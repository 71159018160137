import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import API from "../api/api";
import {AUTH, ROUTE_CONSTANT, USER} from "../constants/app.constants";
import {toastError, toastWarning} from "../context/ToasterContext";
import AppContext from "../context/AppContext";
import mixpanel from 'mixpanel-browser';
import Lottie from "react-lottie";
import BlogEdit from "../assets/lotties/Blog_editing_2.json";

const CreateAccount = () => {
    let navigate = useNavigate();
    const [error, setError] = useState("");
    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [disableCreateAccountButton, setDisableCreateAccountButton] = useState(true);
    let {storeTokenData, storeUserData, storeAccountData, triggerConfetti} = useContext(AppContext);
    const [creatingArticle, setCreatingArticle] = useState(false);
    
    const [errorMessage, setErrorMessage] = useState('');
    
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: BlogEdit,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    
    useEffect(() => {
        if (
            name &&
            lastName &&
            email &&
            password &&
            confirmPassword &&
            acceptedTerms &&
            !errorMessage
        ) {
            setDisableCreateAccountButton(false);
        } else {
            setDisableCreateAccountButton(true);
        }
    }, [name, lastName, email, password, confirmPassword, acceptedTerms, errorMessage]);
    
    const validatePassword = (password) => {
        const minLength = 8;
        const hasUppercase = /[A-Z]/.test(password);
        const hasLowercase = /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
        
        if (password.length < minLength) {
            return `Password must be at least ${minLength} characters long.`;
        }
        if (!hasUppercase) {
            return 'Password must contain at least one uppercase letter.';
        }
        if (!hasLowercase) {
            return 'Password must contain at least one lowercase letter.';
        }
        if (!hasNumber) {
            return 'Password must contain at least one number.';
        }
        if (!hasSpecialChar) {
            return 'Password must contain at least one special character.';
        }
        
        return '';
    };
    
    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email) ? '' : 'Invalid email address.';
    };
    
    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        const validationError = validatePassword(newPassword);
        setErrorMessage(validationError);
    };
    
    const handleConfirmPasswordChange = (e) => {
        const newConfirmPassword = e.target.value;
        setConfirmPassword(newConfirmPassword);
        if (newConfirmPassword !== password) {
            setErrorMessage('Passwords do not match.');
        } else {
            setErrorMessage('');
        }
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setCreatingArticle(true);
        const user = {
            name: name,
            last_name: lastName,
            email: email,
            password: password,
            confirm_password: confirmPassword,
            is_policy_agreed: acceptedTerms
        };
        setDisableCreateAccountButton(true);
        API.post(`signup`, user)
            .then(res => {
                if (!!res.data.data) {
                    const {data} = res.data;
                    const {token, user, account} = data;
                    const authToken = res.data.data.token;
                    storeTokenData(token);
                    storeUserData(user);
                    storeAccountData(account.uuid);
                    localStorage.setItem(AUTH, authToken);
                    localStorage.setItem(USER, JSON.stringify(res.data.data.user));
                    navigate(ROUTE_CONSTANT.ONBOARDING_ACCOUNT);
                    setCreatingArticle(false);
                    mixpanel.track('Create Account', {
                        'email': email,
                    });
                    triggerConfetti();
                } else {
                    toastWarning(res.data.message);
                    setCreatingArticle(false);
                }
            })
            .catch(error => {
                console.log('error', error.response.data.message);
                setDisableCreateAccountButton(false);
                toastError(error.response.data.message);
                setCreatingArticle(false);
            });
    };
    
    return (
        <section className="h-full gradient-form bg-gradient-to-r from-white to-[#FEEFE2] md:h-screen">
            <div className="py-12 px-6 h-full">
                <div className="flex justify-center items-center flex-wrap h-full g-6 text-gray-800">
                    <div className="w-5/12 rounded-lg">
                        <div className="block rounded-lg">
                            <div className="g-01">
                                <div className="px-4 md:px-0">
                                    <div className="md:p-12 md:mx-6">
                                        <div
                                            className="mx-auto justify-center place-content-center content-center place-items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                 strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                      d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z"/>
                                            </svg>
                                            <h4 className="text-xl font-semibold mt-3 mb-6 pb-1">Welcome to <span
                                                className="text-orange-500">Blogd</span></h4>
                                            <h4 className="font-bold tracking-wide mb-6 pb-1 uppercase text-orange-500">
                                                <strong>Sign Up</strong></h4>
                                        </div>
                                        
                                        {error &&
                                            <div
                                                className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                                                role="alert">
                                                {error}
                                            </div>}
                                        
                                        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
                                            <div>
                                                <div className="mb-2 block">
                                                    <label htmlFor="name"
                                                           className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                        Your First Name
                                                    </label>
                                                </div>
                                                <input type="text" id="name"
                                                       className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                       placeholder="Enter your first name"
                                                       onChange={(e) => setName(e.target.value)}
                                                       required={true}/>
                                            </div>
                                            <div>
                                                <div className="mb-2 block">
                                                    <label htmlFor="last-name"
                                                           className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                        Your Last Name
                                                    </label>
                                                </div>
                                                <input type="text" id="last-name"
                                                       className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                       placeholder="Enter your last name"
                                                       onChange={(e) => setLastName(e.target.value)}
                                                       required={true}/>
                                            </div>
                                            <div>
                                                <div className="mb-2 block">
                                                    <label htmlFor="email"
                                                           className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                        Your Email
                                                    </label>
                                                </div>
                                                <input type="email" id="email1"
                                                       className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                       placeholder="name@gmail.com"
                                                       onChange={(e) => {
                                                           setEmail(e.target.value);
                                                           const emailError = validateEmail(e.target.value);
                                                           setErrorMessage(emailError);
                                                       }}
                                                       required={true}/>
                                            </div>
                                            <div>
                                                <div className="mb-2 block">
                                                    <label htmlFor="password1"
                                                           className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                        Your Password
                                                    </label>
                                                </div>
                                                <input type="password" id="password1"
                                                       className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                       placeholder="********"
                                                       onChange={handlePasswordChange}
                                                       required={true}/>
                                            </div>
                                            <div>
                                                <div className="mb-2 block">
                                                    <label htmlFor="password2"
                                                           className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                        Confirm Password
                                                    </label>
                                                </div>
                                                <input type="password" id="password2"
                                                       className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                       placeholder="********"
                                                       onChange={handleConfirmPasswordChange}
                                                       required={true}/>
                                            </div>
                                            {errorMessage && <p className="text-red-500 text-sm mb-2">
                                                {errorMessage}
                                            </p>}
                                            <div className="flex items-center gap-2">
                                                <div className="flex items-center mb-4">
                                                    <input id="acceptedTerms" type="checkbox"
                                                           className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                           onChange={(e) => setAcceptedTerms(e.target.checked)}/>
                                                    <label htmlFor="acceptedTerms"
                                                           className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                                        I accept the Terms and Conditions
                                                    </label>
                                                </div>
                                            </div>
                                            <button type="submit"
                                                    disabled={disableCreateAccountButton}
                                                    className={`text-white focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2
                                                        ${disableCreateAccountButton ? 'bg-gray-300 cursor-not-allowed' : 'bg-primary cursor-pointer'}`}>
                                                {!creatingArticle ? "Create Account" : "Creating..."}
                                            </button>
                                            
                                            <p className="text-bold text-center text-sm mt-2 underline cursor-pointer">
                                                <Link to={ROUTE_CONSTANT.LOGIN}>
                                                    Already have an account? Sign in here
                                                </Link>
                                            </p>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="w-6/12 px-16 py-8 rounded-lg">
                        <Lottie
                            options={defaultOptions}
                            height={260}
                            width={260}
                        />
                        
                        <div className="flex mb-8 mt-12">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                 className="h-5 w-5 mt-1 text-orange-500 mr-2">
                                <path fillRule="evenodd"
                                      d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                                      clipRule="evenodd"/>
                            </svg>
                            <div className="">
                                <h3 className="text-lg font-bold mb-2 text-gray-700">Connect Your Shopify Account</h3>
                                <p className="text-gray-500">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </p>
                            </div>
                        </div>
                        
                        <div className="flex mb-8">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                 className="h-5 w-5 mt-1 text-orange-500 mr-2">
                                <path fillRule="evenodd"
                                      d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                                      clipRule="evenodd"/>
                            </svg>
                            <div className="">
                                <h3 className="text-lg font-bold mb-2 text-gray-700">Create or Migrate Blogs</h3>
                                <p className="text-gray-500">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate
                                </p>
                            </div>
                        </div>
                        
                        <div className="flex mb-8">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                 className="h-5 w-5 mt-1 text-orange-500 mr-2">
                                <path fillRule="evenodd"
                                      d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                                      clipRule="evenodd"/>
                            </svg>
                            <div className="">
                                <h3 className="text-lg font-bold mb-2 text-gray-700">Generate Article Using <u
                                    className="text-orange-500">Blogd AI</u></h3>
                                <p className="text-gray-500">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </p>
                            </div>
                        </div>
                        
                        <div className="flex mb-8">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                 className="h-5 w-5 mt-1 text-orange-500 mr-2">
                                <path fillRule="evenodd"
                                      d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                                      clipRule="evenodd"/>
                            </svg>
                            <div className="">
                                <h3 className="text-lg font-bold mb-2 text-gray-700">Add Personalized Widgets</h3>
                                <p className="text-gray-500">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default CreateAccount
