import React, {useContext, useEffect, useState} from "react";
import {
    DISPLAY_POSITION,
    DISPLAY_SIZE,
    POPUP_TRIGGER,
    POPUP_WIDGET,
    STORE_UUID
} from "../../../constants/app.constants";
import TwitterIcon from "../../../assets/img/twitter.png";
import FacebookIcon from "../../../assets/img/facebook.png";
import InstagramIcon from "../../../assets/img/instagram.png";
import PinterestIcon from "../../../assets/img/pinterest.png";
import LinkIcon from "../../../assets/img/link.png";
import AppModalShort from "../AppModalShort";
import API from "../../../api/api";
import {toastError, toastSuccess} from "../../../context/ToasterContext";
import {useLocation} from "react-router";
import AppContext from "../../../context/AppContext";

const NewsletterWidgetWidgetForm = ({closeWidget, widgetData, getSocialMediaWidgets}) => {
    
    const [headerText, setHeaderText] = useState('');
    const [buttonText, setButtonText] = useState('');
    const [popUpTrigger, setPopUpTrigger] = useState('');
    const [thankYouMessage, setThankYouMessage] = useState('');
    const [activateLoading, setActivateLoading] = useState(false);
    const [deactivateLoading, setDeactivateLoading] = useState(false);
    
    const location = useLocation();
    const {blog_id, blog_uuid} = location.state;
    let {initialDataFetch, token, user, account, store} = useContext(AppContext);
    
    
    useEffect(() => {
        if (widgetData) {
            setHeaderText(widgetData.newsletter_popup_header_text || '');
            setButtonText(widgetData.newsletter_popup_button_text || '');
            setPopUpTrigger(widgetData.newsletter_popup_trigger || '');
            setThankYouMessage(widgetData.thank_you_message || '');
        }
    }, [widgetData]);
    
    const handleHeaderTextChange = (event) => {
        setHeaderText(event.target.value);
    };
    
    const handleButtonTextChange = (event) => {
        setButtonText(event.target.value);
    };
    
    const handlePopUpTriggerChange = (event) => {
        setPopUpTrigger(event.target.value);
    };
    
    const handleThankYouMessageChange = (event) => {
        setThankYouMessage(event.target.value);
    };
    
    const handleNewsletterWidgetCreation = (payload, status) => {
        if (status === 'active') setActivateLoading(true)
        if (status === 'deactive') setDeactivateLoading(true)
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        const store_uuid = localStorage.getItem(STORE_UUID);
        API.post(`social_widgets/add-newsletter-widget/${account}/${store_uuid}/${blog_uuid}`, payload, headerParams)
            .then(res => {
                if (status === 'active') setActivateLoading(false)
                if (status === 'deactive') setDeactivateLoading(false)
                toastSuccess("Widget Saved Successfully!");
                getSocialMediaWidgets();
            })
            .catch(error => {
                if (status === 'active') setActivateLoading(false)
                if (status === 'deactive') setDeactivateLoading(false)
                toastError(error.message);
                console.log('Error: ', error.message);
            })
    }
    
    return (
        <>
            <div className="bg-gray-100 border border-gray-200 rounded-lg p-4 mt-6">
                <div className="grid grid-cols-1 gap-4 my-2">
                    <div className="relative">
                        <label htmlFor="header-text"
                               className="block mb-3 text-sm font-semibold text-gray-700 dark:text-white ml-2">
                            Popup Header Text
                        </label>
                        <input
                            id="header-text"
                            className="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Welcome to our Newsletter!"
                            onChange={handleHeaderTextChange}
                            value={headerText}
                        />
                    </div>
                    
                    <div className="relative">
                        <label htmlFor="button-text"
                               className="block mb-3 text-sm font-semibold text-gray-700 dark:text-white mt-3 ml-2">
                            Popup Button Text
                        </label>
                        <input
                            id="button-text"
                            className="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Subscribe"
                            onChange={handleButtonTextChange}
                            value={buttonText}
                        />
                    </div>
                    
                    <div className="relative">
                        <label htmlFor="popup-trigger"
                               className="block mb-2 text-sm font-semibold text-gray-700 dark:text-white mt-4 ml-2">
                            Popup Trigger
                        </label>
                        <div className="flex mt-5">
                            {POPUP_TRIGGER.map((trigger, index) => (
                                <div key={index} className="flex items-center ml-2 mr-2">
                                    <input
                                        id={`trigger-${index}`}
                                        type="radio"
                                        value={trigger}
                                        name="popup-trigger"
                                        className="w-4 h-4 text-blue-600 bg-white border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        onChange={handlePopUpTriggerChange}
                                        checked={popUpTrigger === trigger}
                                    />
                                    <label htmlFor={`trigger-${index}`}
                                           className="ml-2 text-sm text-gray-900 dark:text-gray-500 capitalize">
                                        {trigger}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                    
                    <div className="relative">
                        <label htmlFor="thank-you-message"
                               className="block mb-3 text-sm font-semibold text-gray-700 dark:text-white mt-4 ml-2">
                            Thank You Message
                        </label>
                        <input
                            id="thank-you-message"
                            className="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Thank you for subscribing!"
                            onChange={handleThankYouMessageChange}
                            value={thankYouMessage}
                        />
                    </div>
                </div>
            </div>
            
            <div className="flex mt-4">
                <div className="ml-auto">
                    <button
                        onClick={() => {
                            const payload = {
                                status: true,
                                has_data: true,
                                header_text: headerText,
                                thank_you_text: thankYouMessage,
                                button_text: buttonText,
                                trigger: popUpTrigger,
                            }
                            handleNewsletterWidgetCreation(payload, 'active');
                        }}
                        className={`bg-primary text-white text-sm font-semibold px-4 py-2 mb-2 rounded-lg ml-2`}>
                        {activateLoading ? 'Activating...' : 'Activate Widget'}
                    </button>
                    
                    <button
                        onClick={() => {
                            const payload = {
                                status: false,
                                has_data: false,
                                header_text: "",
                                thank_you_text: "",
                                button_text: "",
                                trigger: "",
                            }
                            setButtonText('');
                            setHeaderText('');
                            setThankYouMessage('');
                            setPopUpTrigger('');
                            handleNewsletterWidgetCreation(payload, 'deactive');
                        }}
                        className={`bg-gray-700 text-white text-sm font-semibold px-4 py-2 mb-2 rounded-lg ml-2`}>
                        {deactivateLoading ? 'Deactivating...' : 'Deactivate Widget'}
                    </button>
                </div>
            </div>
        </>
    )
}

export default NewsletterWidgetWidgetForm;
