import React, {useContext, useEffect, useState} from 'react'
import Editor from "./Editor/Editor.jsx";
import classNames from 'classnames';
import {useNavigate, useParams} from "react-router";
import {AUTH, ROUTE_CONSTANT, STORE_UUID, USER} from "../constants/app.constants";
import AppContext from "../context/AppContext";
import {Link, useLocation} from "react-router-dom";
import API from "../api/api";
import {toastError, toastSuccess, toastWarning} from "../context/ToasterContext";
import {cssContent} from "../constants/style.constants";
import DeployForm from "../components/shared/DeployForm";
import AppModalShort from "../components/shared/AppModalShort";
import AppModalMedium from "../components/shared/AppModalMedium";
import AppModalLong from "../components/shared/AppModalLong";
import ChooseTemplate from "./ChooseTemplate";
import SEOReportView from "../components/layouts/SEOReportView";
import mixpanel from "mixpanel-browser";


function EditorPage() {
    
    const navigate = useNavigate();
    let {initialDataFetch, token, user, account, store, contentSummary} = useContext(AppContext);
    const [isEditorMode, setIsEditorMode] = useState(true);
    const [text, setText] = useState('');
    const [html, setHtml] = useState("");
    const [articleId, setArticleId] = useState("");
    const [article, setArticle] = useState({});
    const [articleHtml, setArticleHtml] = useState("");
    const [articleJson, setArticleJson] = useState(null);
    const [blogId, setBlogId] = useState("");
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState("");
    const location = useLocation();
    const {article_id, blog_id, blog_uuid, blog_title, blog_handle, article_uuid} = location.state;
    const url = location.pathname;
    const {2: blog_name, 4: article_name} = url.split('/');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
    const [isEditArticleDetailModalOpen, setIsEditArticleDetailModalOpen] = useState(false);
    const [articleTitle, setArticleTitle] = useState("");
    const [articleSlug, setArticleSlug] = useState("");
    const [articleAuthor, setArticleAuthor] = useState("");
    const [articleCategory, setArticleCategory] = useState("");
    const [editArticleSaving, setEditArticleSaving] = useState(false);
    const [showCopied, setShowCopied] = useState(false);
    const [currentBlog, setCurrentBlog] = useState({});
    const [isPublishSelected, setIsPublishSelected] = useState(false);
    const [showSEOReport, setShowSEOReport] = useState(false);
    const [showSEOReportView, setShowSEOReportView] = useState(false);
    const [articleContent, setArticleContent] = useState("");
    
    const [showAddCategoryField, setShowAddCategoryField] = useState(false);
    const [showAddAuthorField, setShowAddAuthorField] = useState(false);
    const [addNewCategory, setAddNewCategory] = useState('');
    const [addNewAuthor, setAddNewAuthor] = useState('');
    const [authors, setAuthors] = useState([]);
    const [categories, setCategories] = useState([]);
    const [slugValid, setSlugValid] = useState(false);
    const slugRegex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;
    
    
    const handleSlugChange = (e) => {
        const value = e.target.value;
        setArticleSlug(value);
        setSlugValid(slugRegex.test(value));
    };
    
    useEffect(() => {
        setSlugValid(slugRegex.test(articleSlug));
    }, [articleSlug]);
    
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    
    const handleClosePublishModal = () => {
        setIsPublishModalOpen(false);
    };
    
    const handleOpenEditArticleDetailModal = () => {
        setIsEditArticleDetailModalOpen(true);
    };
    
    const handleCloseEditArticleDetailModal = () => {
        setIsEditArticleDetailModalOpen(false);
    };
    
    const handleEditArticleDetail = () => {
        if (slugValid && articleTitle) {
            setEditArticleSaving(true);
            const payload = {
                title: articleTitle,
                handle: articleSlug,
                author: articleAuthor,
                categories: articleCategory
            };
            console.log("payload: ", payload);
            let headerParams = {
                headers: {
                    "Authorization": token
                }
            };
            API.post(`articles/edit-article-title-and-handle/${account}/${store}/${blog_uuid}/${article_uuid}`, payload, headerParams)
                .then(res => {
                    setEditArticleSaving(false);
                    setIsEditArticleDetailModalOpen(false);
                    toastSuccess("Saved your changes");
                    handleFetchArticle();
                })
                .catch(error => {
                    setLoading(false);
                    toastError(error.message);
                })
        }
    }
    
    useEffect(() => {
        if (initialDataFetch) {
            if (!!token && !!user) {
                handleFetchArticle();
                handleGetAuthors();
                handleGetCategories();
                setCurrentBlog({
                    name: blog_name,
                    uuid: blog_uuid,
                    title: blog_title,
                    handle: blog_handle,
                    id: blog_id,
                    article_uuid,
                    article_id
                })
            } else {
                navigate(ROUTE_CONSTANT.LOGIN);
            }
        }
    }, [location, token, user]);
    
    
    const handleFetchArticle = () => {
        setLoading(true);
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        const store_uuid = localStorage.getItem(STORE_UUID);
        API.get(`articles/get-article/${account}/${store}/${blog_uuid}/${article_uuid}`, headerParams)
            .then(res => {
                if (!!res.data.data) {
                    const {data} = res.data;
                    setArticle(data);
                    setArticleTitle(data.title);
                    setArticleSlug(data.handle);
                    setArticleAuthor(data.author);
                    setArticleCategory(data.categories);
                    setHtml(res.data.data.article_html);
                    setLoading(false);
                } else {
                    setArticle({});
                    setLoading(false);
                }
            })
            .catch(error => {
                setLoading(false);
                setError(error.message);
            })
    }
    
    const handleSaveAsDraft = () => {
        setSaving(true);
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        let bodyParams = {
            article_html: articleContent,
            is_published: false,
            article_id: article.article_id,
            content_summary: contentSummary
        };
        console.log('articleContent from Save as Draft -------- ', articleContent);
        API.post(`articles/save-article/${account}/${store}/${blog_uuid}`, bodyParams, headerParams)
            .then(res => {
                setSaving(false);
                toastSuccess("Saved your changes");
            })
            .catch(error => {
                setLoading(false);
                setSaving(error.message);
                toastError(error.message);
            })
    }
    
    
    const publishBlog = () => {
        setIsPublishSelected(true);
        setShowSEOReportView(true);
        
        mixpanel.track('Publish Clicked', {
            "article_id": article.article_id,
        });
        
        const payload = {
            blogd_account_uuid: "",
            blogd_store_uuid: "",
            blogd_user_id: "",
        };
    }
    
    const handleCopyToClipboard = async (textToCopy) => {
        try {
            await navigator.clipboard.writeText(textToCopy);
            setShowCopied(true);
            setTimeout(() => {
                setShowCopied(false);
            }, 2000);
        } catch (error) {
            console.error('Failed to copy text:', error);
        }
    };
    
    const toggleMode = () => {
        setIsEditorMode(!isEditorMode);
    };
    
    const previewClasses = classNames({
        'hidden': isEditorMode,
        'block': !isEditorMode,
    });
    
    const editorClasses = classNames({
        'hidden': !isEditorMode,
        'block': isEditorMode,
    });
    
    let onEditorChangeValue = (htmlValue) => {
        console.log("htmlValue --------------------------- ", htmlValue);
        setArticleHtml(htmlValue);
    }
    
    const publishSelectedStatus = (status) => {
        setIsPublishSelected(status);
    }
    
    const showSEO = (value) => {
        setShowSEOReportView(value);
    }
    
    const showSEOReportStatus = (status) => {
        setShowSEOReportView(status);
        setIsPublishSelected(status);
    }
    
    const handleGetAuthors = () => {
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        API.get(`account/get-blog-authors/${account}`, headerParams)
            .then(res => {
                const {data} = res.data;
                setAuthors(data);
            })
            .catch(error => {
                console.log('Error: ', error.message);
            });
    };
    
    const handleGetCategories = () => {
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        API.get(`account/get-blog-categories/${account}`, headerParams)
            .then(res => {
                const {data} = res.data;
                setCategories(data);
            })
            .catch(error => {
                console.log('Error: ', error.message);
            });
    };
    
    const handleAddNewCategory = () => {
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        const params = {
            "blog_categories": addNewCategory,
        };
        API.post(`account/add-blog-categories/${account}`, params, headerParams)
            .then(res => {
                handleGetCategories();
                setAddNewCategory('');
                setShowAddCategoryField(false);
            })
            .catch(error => {
                console.log('Error: ', error.message);
                toastError(error.message);
            });
    };
    
    const handleAddNewAuthor = () => {
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        const params = {
            "blog_authors": addNewAuthor,
        };
        API.post(`account/add-blog-authors/${account}`, params, headerParams)
            .then(res => {
                handleGetAuthors();
                setAddNewAuthor('');
                setShowAddAuthorField(false);
            })
            .catch(error => {
                console.log('Error: ', error.message);
                toastError(error.message);
            });
    };
    
    
    return (
        <div>
            
            {/*<TopNav/>*/}
            
            {!isPublishSelected && <>
                {!loading && error.length === 0 &&
                    <>
                        <div className="mx-auto mt-4">
                            <div className="flex mx-4 mb-2">
                                <div className="text-left -pt-12">
                                    <div className={"mb-2"}>
                                        <div className="flex">
                                            <div className="mr-5 mt-2 cursor-pointer"
                                                 onClick={() => {
                                                     navigate(`/blogs/${blog_name}/articles`, {
                                                         state: {
                                                             blog_id,
                                                             blog_uuid,
                                                             blog_title,
                                                             blog_handle
                                                         }
                                                     })
                                                 }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                     strokeWidth="2" stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                          d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"/>
                                                </svg>
                                            </div>
                                            
                                            <div>
                                                <div className="flex">
                                                    <div>
                                                        <div className="text-sm font-bold text-black">{article.title}</div>
                                                    </div>
                                                    <div className="ml-2 cursor-pointer"
                                                         onClick={handleOpenEditArticleDetailModal}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                             viewBox="0 0 24 24"
                                                             strokeWidth="1.5" stroke="currentColor"
                                                             className="w-5 h-5">
                                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                                
                                                <div className="flex mb-2">
                                                    <div className="flex mt-2 mr-5">
                                                        <div className="text-xs font-bold text_light_brown">Author: &nbsp;</div>
                                                        <div className="relative">
                                                            <p className="text-xs font-semibold cursor-pointer text-blue-500 hover:underline uppercase">{article.author}</p>
                                                        </div>
                                                        <div className={"cursor-pointer"} onClick={() => {
                                                            handleOpenEditArticleDetailModal();
                                                        }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                 viewBox="0 0 24 24"
                                                                 strokeWidth="1.5" stroke="currentColor"
                                                                 className="w-4 h-4 ml-2">
                                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"/>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                    <div className="flex mt-2 mr-5">
                                                        <div className="text-xs font-bold text_light_brown">Category: &nbsp;</div>
                                                        <div className="relative">
                                                            <p className="text-xs font-semibold cursor-pointer text-blue-500 hover:underline uppercase">
                                                                {!!article.categories ? article.categories : "-"}
                                                            </p>
                                                        </div>
                                                        <div className={"cursor-pointer"} onClick={() => {
                                                            handleOpenEditArticleDetailModal();
                                                        }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                 viewBox="0 0 24 24"
                                                                 strokeWidth="1.5" stroke="currentColor"
                                                                 className="w-4 h-4 ml-2">
                                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"/>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                    <div className="flex mt-2">
                                                        <div className="text-xs font-bold text_light_brown">Handle: &nbsp;</div>
                                                        <div className="relative"
                                                             onClick={() => {
                                                                 handleCopyToClipboard(`https://www.yanabhojan.com/some-category/${article.handle}`);
                                                             }}>
                                                            <p className="text-xs font-semibold cursor-pointer text-blue-500 hover:underline">{article.handle}</p>
                                                        </div>
                                                        <div className={"cursor-pointer"} onClick={() => {
                                                            handleOpenEditArticleDetailModal();
                                                        }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                 viewBox="0 0 24 24"
                                                                 strokeWidth="1.5" stroke="currentColor"
                                                                 className="w-4 h-4 ml-2">
                                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"/>
                                                            </svg>
                                                        </div>
                                                        {showCopied &&
                                                            <p className="text-xs font-semibold cursor-pointer text-blue-500 ml-3">
                                                                Copied!
                                                            </p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    
                                    </div>
                                
                                </div>
                                
                                <div className="text-center flex-1">
                                </div>
                                
                                <div className="text-right">
                                    
                                    <div className="flex mt-2 mb-2">
                                        <button
                                            className={`text-center bg-secondary text-black
                                    focus:ring-4 focus:ring-blue-300 font-bold rounded-lg text-sm px-5 py-2
                                    mr-2 focus:outline-none`}
                                            onClick={handleSaveAsDraft}>
                                            {saving ? "Saving..." : "Save as Draft"}
                                        </button>
                                        
                                        <button
                                            className={`text-white text-center bg-primary
                                    focus:ring-4 focus:ring-blue-300 font-bold rounded-lg text-sm px-5 py-2
                                    mr-2 focus:outline-none`}
                                            onClick={publishBlog}>
                                            Publish
                                        </button>
                                        
                                        <button
                                            className={`ml-2 text-center underline font-bold ${isEditorMode ? "text-primary" : "text-secondary"}
                                    font-bold rounded-lg text-xs`}
                                            onClick={toggleMode}>
                                            {isEditorMode ? 'Preview Mode' : 'Editor Mode'}
                                        </button>
                                    </div>
                                
                                </div>
                            </div>
                            
                            <div className={editorClasses + " -mt-3"}>
                                <Editor
                                    article={article}
                                    setArticle={setArticle}
                                    articleContent={html}
                                    setArticleContent={setArticleContent}
                                    placeholder={'Write something...'}
                                />
                            </div>
                            
                            {!isEditorMode &&
                                <div>
                                    <div
                                        className="max-w-5xl mb-6 flex mx-auto border rounded-2xl bg-white shadow border-gray-50 px-6 pb-6 mt-4">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: `
                                    <style>
                                    ${cssContent}
                                    </style>
                                    <div class="outer-body mx-6">
                                        <div class="text-3xl font-bold mt-6 leading-normal">
                                          ${article.title}
                                        </div>
                                        <div class="mt-2 mx-2">
                                          ${articleContent}
                                        </div>
                                    </div>
                                    `
                                            }}
                                        />
                                    </div>
                                </div>}
                        
                        </div>
                    </>}
            </>}
            
            {loading &&
                <div className="flex justify-center items-center mt-44">
                    <div className="text-center">
                        <h1 className="text-2xl font-bold">Loading...</h1>
                        <p className="text-gray-500">Please wait</p>
                    </div>
                </div>}
            
            {!loading && error.length > 0 &&
                <div className="flex justify-center items-center mt-44">
                    <div className="text-center">
                        <h1 className="text-2xl font-bold">Oops...</h1>
                        <p className="text-gray-500">{error}</p>
                    </div>
                </div>}
            
            {isModalOpen && (
                <AppModalShort title={""}>
                    <div className="">
                        <div className="flex justify-between py-3 px-1 text-sm text-bold">
                            <div className="text-left">
                                <h2 className="text-xl font-bold">Summarize</h2>
                            </div>
                            
                            <div onClick={handleCloseModal}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 cursor-pointer">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                                </svg>
                            </div>
                        </div>
                        
                        <div className="flex justify-between py-3 px-1 text-sm text-bold">
                            <div className="text-left my-auto">
                                <p className="font-semibold">
                                    Summary
                                </p>
                            </div>
                            
                            <div
                                className="bg-orange-200 text-gray-800 py-2 px-4 rounded-lg">
                                Tone of the blog: <strong>Friendly 🤝</strong>
                            </div>
                        </div>
                        
                        <div className="py-3 px-1">
                            <p className={"text-sm text-gray-700"}>
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                unknown printer took a galley of type and scrambled it to make a type specimen book. It
                                has survived not only five centuries, but also the leap into electronic typesetting,
                                remaining essentially unchanged. It was popularised in the 1960s with the release of
                                Letraset sheets containing Lorem Ipsum passages, and more recently with desktop
                                publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum
                                has been the industry's standard dummy text ever since the 1500s, when an unknown
                                printer took a galley of type and scrambled it to make a type specimen book. It has
                                survived not only five centuries, but also the leap into electronic typesetting,
                                remaining essentially unchanged. It was popularised in the 1960s with the release of
                                Letraset sheets containing Lorem Ipsum passages, and more recently with desktop
                                publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                            </p>
                            
                            <div className={"mt-8 text-sm"}>
                                <p className="font-bold mb-1">Related Products</p>
                                <p>We fetched some of the relatable products for your blog. You can add manually so to
                                    increase the sales.</p>
                            </div>
                            
                            <div className="flex overflow-x-auto mt-4 text-sm">
                                <div className="flex space-x-4">
                                    <div className="bg-gray-100 p-2 rounded-lg">
                                        <div className="w-32 h-32 bg-gray-200 rounded-lg"></div>
                                        <p className="font-bold mb-1 mt-2 pl-1">Product Name</p>
                                        <p className={"text-xs pl-1"}>Collection One</p>
                                    </div>
                                    <div className="bg-gray-100 p-2 rounded-lg">
                                        <div className="w-32 h-32 bg-gray-200 rounded-lg"></div>
                                        <p className="font-bold mb-1 mt-2 pl-1">Product Name</p>
                                        <p className={"text-xs pl-1"}>Collection One</p>
                                    </div>
                                    <div className="bg-gray-100 p-2 rounded-lg">
                                        <div className="w-32 h-32 bg-gray-200 rounded-lg"></div>
                                        <p className="font-bold mb-1 mt-2 pl-1">Product Name</p>
                                        <p className={"text-xs pl-1"}>Collection One</p>
                                    </div>
                                    <div className="bg-gray-100 p-2 rounded-lg">
                                        <div className="w-32 h-32 bg-gray-200 rounded-lg"></div>
                                        <p className="font-bold mb-1 mt-2 pl-1">Product Name</p>
                                        <p className={"text-xs pl-1"}>Collection One</p>
                                    </div>
                                    <div className="bg-gray-100 p-2 rounded-lg">
                                        <div className="w-32 h-32 bg-gray-200 rounded-lg"></div>
                                        <p className="font-bold mb-1 mt-2 pl-1">Product Name</p>
                                        <p className={"text-xs pl-1"}>Collection One</p>
                                    </div>
                                    <div className="bg-gray-100 p-2 rounded-lg">
                                        <div className="w-32 h-32 bg-gray-200 rounded-lg"></div>
                                        <p className="font-bold mb-1 mt-2 pl-1">Product Name</p>
                                        <p className={"text-xs pl-1"}>Collection One</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </AppModalShort>
            )}
            
            {isEditArticleDetailModalOpen && (
                <AppModalMedium title={""}>
                    <div className="">
                        <div className="flex justify-between py-3 px-1 text-sm text-bold">
                            <div className="text-left">
                                <h2 className="text-xl font-bold">Edit Article Details</h2>
                            </div>
                            
                            <div onClick={handleCloseEditArticleDetailModal}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 cursor-pointer">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                                </svg>
                            </div>
                        </div>
                        
                        <div className="py-3 px-1">
                            
                            <form className="flex flex-col gap-4">
                                <div>
                                    <div className="mb-2 block">
                                        <label
                                            htmlFor="title"
                                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                            Title
                                        </label>
                                    </div>
                                    <input
                                        type="text"
                                        id="title"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Enter Title"
                                        value={articleTitle}
                                        onChange={(e) => setArticleTitle(e.target.value)}
                                        required={true}
                                    />
                                </div>
                                
                                <div>
                                    <div className="mb-2 block">
                                        <label
                                            htmlFor="slug"
                                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                            Slug
                                        </label>
                                    </div>
                                    <input
                                        type="text"
                                        id="slug"
                                        className={`bg-gray-50 border ${slugValid ? 'border-gray-300' : 'border-red-500'} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                        placeholder="Enter Slug"
                                        value={articleSlug}
                                        onChange={handleSlugChange}
                                        required={true}
                                    />
                                    {!slugValid && articleSlug && (
                                        <p className="text-red-500 text-xs mt-1">
                                            Slug must be lowercase, hyphenated, and contain no special characters or spaces.
                                        </p>
                                    )}
                                </div>
                                
                                <div className="flex flex-col md:flex-row md:space-x-4">
                                    <div className="w-full">
                                        <div className="flex">
                                            <label
                                                htmlFor="author"
                                                className="text-xs uppercase font-bold text-gray-600 dark:text-white"
                                            >
                                                Select Author
                                            </label>
                                            <div
                                                className="font-medium flex cursor-pointer text-blue-500 text-[14px] ml-auto"
                                                onClick={() => setShowAddAuthorField(true)}
                                            >
                                                <span>Add Author</span>
                                            </div>
                                        </div>
                                        <select
                                            id="author"
                                            required={true}
                                            onChange={(e) => setArticleAuthor(e.target.value)}
                                            value={articleAuthor}
                                            className="bg-gray-50 mt-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        >
                                            <option value="">Select Author</option>
                                            {authors.map((author, index) => (
                                                <option key={index} value={author}>
                                                    {author}
                                                </option>
                                            ))}
                                        </select>
                                        {showAddAuthorField && (
                                            <div className="mt-4">
                                                <input
                                                    type="text"
                                                    value={addNewAuthor}
                                                    onChange={(e) => setAddNewAuthor(e.target.value)}
                                                    placeholder="New Author"
                                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                                />
                                                <button
                                                    type="button"
                                                    onClick={handleAddNewAuthor}
                                                    className="text-white mt-4 hover:bg-red-500 bg-primary focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 mr-2 mb-2"
                                                >
                                                    Add Author
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                    
                                    <div className="w-full">
                                        <div className="mb-2 flex">
                                            <label
                                                htmlFor="category"
                                                className="block text-sm font-medium text-gray-900 dark:text-white"
                                            >
                                                Category
                                            </label>
                                            <div
                                                className="font-medium flex cursor-pointer text-blue-500 text-[14px] ml-auto"
                                                onClick={() => setShowAddCategoryField(true)}
                                            >
                                                <span>Add Category</span>
                                            </div>
                                        </div>
                                        <select
                                            id="category"
                                            required={true}
                                            onChange={(e) => setArticleCategory(e.target.value)}
                                            value={articleCategory}
                                            className="bg-gray-50 mt-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        >
                                            <option value="">Select Category</option>
                                            {categories.map((category, index) => (
                                                <option key={index} value={category}>
                                                    {category}
                                                </option>
                                            ))}
                                        </select>
                                        {showAddCategoryField && (
                                            <div className="mt-4">
                                                <input
                                                    type="text"
                                                    value={addNewCategory}
                                                    onChange={(e) => setAddNewCategory(e.target.value)}
                                                    placeholder="New Category"
                                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                                />
                                                <button
                                                    type="button"
                                                    onClick={handleAddNewCategory}
                                                    className="text-white mt-4 hover:bg-red-500 bg-primary focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 mr-2 mb-2"
                                                >
                                                    Add Category
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                
                                <button
                                    type="button"
                                    onClick={handleEditArticleDetail}
                                    className={`text-white mt-6 hover:bg-red-500 bg-primary focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 ${editArticleSaving || !slugValid || !articleTitle ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
                                    disabled={editArticleSaving || !slugValid || !articleTitle}
                                >
                                    {!editArticleSaving ? 'Save' : 'Saving...'}
                                </button>
                            </form>
                        
                        </div>
                    </div>
                </AppModalMedium>
            )}
            
            {isPublishModalOpen && (
                <AppModalLong title={""}>
                    <div className="">
                        <div className="flex justify-between py-3 px-1 text-sm text-bold">
                            <div className="text-left">
                                <h2 className="text-xl font-bold">Publish Blog</h2>
                            </div>
                            
                            <div onClick={() => {
                                handleClosePublishModal();
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 cursor-pointer">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M6 18L18 6M6 6l12 12"/>
                                </svg>
                            </div>
                        </div>
                        
                        <div className="pb-1 px-1">
                            
                            <DeployForm
                                article={article}
                                article_html={html}
                                handleClosePublishModal={() => {
                                    handleClosePublishModal();
                                }}
                                article_id={""}
                            />
                        
                        </div>
                    
                    </div>
                </AppModalLong>
            )}
            
            {/*<div className="h-screen flex justify-center items-center">*/}
            {showSEOReportView &&
                <>
                    {/*<div className="h-screen flex justify-center items-center">*/}
                    {/*    <div className="max-w-6xl mx-auto my-auto">*/}
                    {/*        <ChooseTemplate*/}
                    {/*            blog={currentBlog}*/}
                    {/*            isRouteChange={false}*/}
                    {/*            showSEO={showSEO}*/}
                    {/*            publishSelectedStatus={publishSelectedStatus}/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="h-screen flex justify-center items-center">
                        <SEOReportView showSEOReportStatus={showSEOReportStatus}
                                       isSingleArticlePublish={true}
                                       isMigrate={true}
                                       blog={currentBlog}/>
                    </div>
                </>
            }
            
            {/*<>
                {showSEOReportView &&
                    <div className="h-screen flex justify-center items-center">
                        <SEOReportView showSEOReportStatus={showSEOReportStatus}
                                       isSingleArticlePublish={true}
                                       blog={currentBlog}/>
                    </div>}
            </>*/}
            {/*</div>*/}
        
        </div>
    )
}

export default EditorPage
