import React, {useContext, useEffect, useState} from 'react'
import {useNavigate} from "react-router";
import {useLocation} from "react-router-dom";
import {AUTH, ROUTE_CONSTANT, STORE_UUID} from "../constants/app.constants";
import API from "../api/api";
import AppContext from "../context/AppContext";
import Lottie from "react-lottie";
import VerifyLottie from "../assets/lotties/payment-verify.json";

export default function HomeCheck() {
    
    const navigate = useNavigate();
    const params = useLocation().search;
    let {initialDataFetch, token, user, storeStoreData} = useContext(AppContext);
    
    const code = new URLSearchParams(params).get('code');
    const hmac = new URLSearchParams(params).get('hmac');
    const shop = new URLSearchParams(params).get('shop');
    const host = new URLSearchParams(params).get('host');
    const state = new URLSearchParams(params).get('state');
    const timestamp = new URLSearchParams(params).get('timestamp');
    
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: VerifyLottie,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    
    useEffect(() => {
        if (initialDataFetch) {
            if (code !== null && hmac !== null && shop !== null && host !== null && state !== null && timestamp !== null) {
                authCallbackHandler(code, hmac, shop, host, state, timestamp);
            } else {
                if (!!token && !!user) {
                    navigate(ROUTE_CONSTANT.BLOGS);
                } else {
                    navigate(ROUTE_CONSTANT.LOGIN);
                }
            }
        }
    }, [initialDataFetch]);
    
    const authCallbackHandler = (code, hmac, shop, host, state, timestamp) => {
        const params = {
            code: code,
            hmac: hmac,
            host: host,
            shop: shop,
            state: state,
            timestamp: timestamp,
        }
        let headerParams = {
            headers: {
                "Authorization": localStorage.getItem(AUTH)
            }
        };
        API.post(`shopify/setup-complete`, {params: params}, headerParams)
            .then(async res => {
                localStorage.setItem(STORE_UUID, res.data.data.store_uuid);
                storeStoreData(res.data.data.store_uuid);
                navigate(ROUTE_CONSTANT.BLOGS);
            })
            .catch(error => {
                navigate(ROUTE_CONSTANT.BLOGS);
                console.log("Error: ", error.message);
            })
    }
    
    return (
        <div>
            <Lottie
                options={defaultOptions}
                height={200}
                width={200}
            />
        </div>
    )
}
