import React, {useContext, useEffect, useState} from "react";
import moment from "moment";

const PublishArticleDescForm = ({nextStep, prevStep, step2Payload}) => {
    const [selectedAuthorDate, setSelectedAuthorDate] = useState('show-author-date');
    const [selectedAuthorDatePosition, setSelectedAuthorDatePosition] = useState('top');
    
    const handleAuthorDateRadioChange = (event) => {
        setSelectedAuthorDate(event.target.id);
    };
    
    const handleAuthorDatePositionRadioChange = (event) => {
        setSelectedAuthorDatePosition(event.target.id);
    };
    
    
    useEffect(() => {
    }, []);
    
    return (
        <div className="">
            
            <div>
                <div className="w-full bg-white">
                    
                    <div className="grid grid-cols-2 gap-3 mb-3">
                        
                        <div>
                            <div className={"bg-white rounded-lg p-4"}>
                                <div className="text-sm font-semibold text-orange-600 mb-4 px-1">Show Author & Date
                                </div>
                                <div className="flex">
                                    <div
                                        className="flex items-center w-full ps-4 border border-gray-200 rounded-lg px-4">
                                        <input
                                            id="show-author-date"
                                            type="radio"
                                            value=""
                                            name="show-author-date"
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                                            checked={selectedAuthorDate === 'show-author-date'}
                                            onChange={handleAuthorDateRadioChange}
                                        />
                                        <label htmlFor="show-author-date"
                                               className="w-full py-4 ms-2 text-sm font-medium text-gray-900 px-2">
                                            Show Author & Date
                                        </label>
                                    </div>
                                    <div
                                        className="flex items-center w-full ps-4 border border-gray-200 rounded-lg px-4 ml-3">
                                        <input
                                            id="dont-show-author-date"
                                            type="radio"
                                            value=""
                                            name="dont-show-author-date"
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                                            checked={selectedAuthorDate === 'dont-show-author-date'}
                                            onChange={handleAuthorDateRadioChange}
                                        />
                                        <label htmlFor="dont-show-author-date"
                                               className="w-full py-4 ms-2 text-sm font-medium text-gray-900 px-2">
                                            Don't Author & Date
                                        </label>
                                    </div>
                                </div>
                            </div>
                            
                            {selectedAuthorDate === 'show-author-date' &&
                                <div className={"bg-white rounded-lg p-4"}>
                                    <div className="text-sm font-semibold text-orange-600 mb-4 px-1">Show Author & Date
                                        Position
                                    </div>
                                    <div className="flex">
                                        <div
                                            className="flex items-center w-full ps-4 border border-gray-200 rounded-lg px-4">
                                            <input
                                                id="top"
                                                type="radio"
                                                value=""
                                                name="top"
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                                                checked={selectedAuthorDatePosition === 'top'}
                                                onChange={handleAuthorDatePositionRadioChange}
                                            />
                                            <label htmlFor="top"
                                                   className="w-full py-4 ms-2 text-sm font-medium text-gray-900 px-2">
                                                Top
                                            </label>
                                        </div>
                                        <div
                                            className="flex items-center w-full ps-4 border border-gray-200 rounded-lg px-4 ml-3">
                                            <input
                                                id="bottom"
                                                type="radio"
                                                value=""
                                                name="bottom"
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                                                checked={selectedAuthorDatePosition === 'bottom'}
                                                onChange={handleAuthorDatePositionRadioChange}
                                            />
                                            <label htmlFor="bottom"
                                                   className="w-full py-4 ms-2 text-sm font-medium text-gray-900 px-2">
                                                Bottom
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        
                        <div>
                            <div
                                className="bg-gray-100 rounded-lg font-medium p-6 m-2">
                                {selectedAuthorDate === 'show-author-date' &&
                                    <>
                                        {selectedAuthorDatePosition === 'top' &&
                                            <div className={"flex"}>
                                                <div>
                                                    <div
                                                        className="flex text-sm tracking-wide leading-relaxed text-gray-500">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                             viewBox="0 0 24 24"
                                                             strokeWidth="1.5" stroke="currentColor"
                                                             className="w-4 h-4 mr-2 mt-0.5">
                                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                                  d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                                        </svg>
                                                        <span className="font-medium text-sm">UPDATE</span></div>
                                                    <div><span
                                                        className="text-xs mt-2">{moment(new Date()).format("DD MMM YYYY")}</span>
                                                    </div>
                                                </div>
                                                <div className={"ml-12 mb-10"}>
                                                    <div
                                                        className="flex text-sm tracking-wide leading-relaxed text-gray-500">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                             viewBox="0 0 24 24"
                                                             strokeWidth="1.5" stroke="currentColor"
                                                             className="w-4 h-4 mr-2 mt-0.5">
                                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                                  d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"/>
                                                        </svg>
                                                        <span className="font-medium text-sm">AUTHOR</span></div>
                                                    <div><span className="text-xs mt-2">John Martin Kris</span></div>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                                
                                
                                <div className="flex">
                                    <div>
                                        <div className="text-sm font-bold">Title</div>
                                        <div className="text-gray-500 text-xs">Meta Description</div>
                                    </div>
                                    <div className="ml-auto my-auto text-sm">
                                        <button type="button"
                                                className={"flex border border-gray-300 text-gray-700 ml-3 font-medium rounded-full text-sm px-4 py-2.5"}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                 strokeWidth="1.5" stroke="currentColor"
                                                 className="w-4 h-4 mr-3 mt-0.5">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                      d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z"/>
                                            </svg>
                                            <span className={"text-sm"}>Share</span></button>
                                    </div>
                                </div>
                                
                                <p className={"text-sm mt-6 text-gray-700"}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                                    irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                                    deserunt mollit anim id est laborum.
                                </p>
                                
                                
                                {selectedAuthorDate === 'show-author-date' &&
                                    <>
                                        {selectedAuthorDatePosition === 'bottom' &&
                                            <div className={"flex mt-8"}>
                                                <div>
                                                    <div
                                                        className="flex text-sm tracking-wide leading-relaxed text-gray-500">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                             viewBox="0 0 24 24"
                                                             strokeWidth="1.5" stroke="currentColor"
                                                             className="w-4 h-4 mr-2 mt-0.5">
                                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                                  d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                                        </svg>
                                                        <span className="font-medium text-sm">UPDATE</span></div>
                                                    <div><span
                                                        className="text-xs mt-2">{moment(new Date()).format("DD MMM YYYY")}</span>
                                                    </div>
                                                </div>
                                                <div className={"ml-12"}>
                                                    <div
                                                        className="flex text-sm tracking-wide leading-relaxed text-gray-500">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                             viewBox="0 0 24 24"
                                                             strokeWidth="1.5" stroke="currentColor"
                                                             className="w-4 h-4 mr-2 mt-0.5">
                                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                                  d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"/>
                                                        </svg>
                                                        <span className="font-medium text-sm">AUTHOR</span></div>
                                                    <div><span className="text-xs mt-2">John Martin Kris</span></div>
                                                </div>
                                            </div>}
                                    </>}
                            </div>
                        
                        </div>
                    
                    </div>
                
                </div>
                
                <div className={"absolute bottom-10 left-[50%] -translate-x-1/2 pt-4"}>
                    <button type="button"
                            onClick={prevStep}
                            className="text-gray-800 w-0.5/4 font-bold mt-2 hover:bg-gray-100 bg-white border border-gray-300 rounded-lg text-sm px-6 py-2.5 mr-2">
                        PREVIOUS
                    </button>
                    <button type="button"
                            onClick={nextStep}
                            className="text-white w-0.5/4 font-bold mt-2 hover:bg-gray-800 bg-gray-700 rounded-lg text-sm px-6 py-2.5 mr-2">
                        NEXT
                    </button>
                </div>
            </div>
        
        </div>
    )
}

export default PublishArticleDescForm;
