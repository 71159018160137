import React, {useContext, useEffect, useState} from 'react'
import PageTitle from "../components/typography/PageTitle";
import {useNavigate} from "react-router";
import API from "../api/api";
import AppContext from "../context/AppContext";
import {ROUTE_CONSTANT} from "../constants/app.constants";
import EmptyImage from "../assets/img/empty-image.png";
import OBTopNav from "../components/layouts/OBTopNav";
import AppModalShort from "../components/shared/AppModalShort";
import DeployForm from "../components/shared/DeployForm";
import AppModalLong from "../components/shared/AppModalLong";
import {useLocation} from "react-router-dom";
import _ from "lodash";
import ChooseTemplate from "./ChooseTemplate";

export default function AllBlogs() {
    
    const navigate = useNavigate();
    let {initialDataFetch, token, user, account, store, afterLogout} = useContext(AppContext);
    const [allBlogs, setAllBlogs] = useState([]);
    const [allImportedBlogs, setAllImportedBlogs] = useState([]);
    const [deployedBlogs, setDeployedBlogs] = useState([]);
    const [notDeployedBlogs, setNotDeployedBlogs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    
    const [blogTitle, setBlogTitle] = useState("");
    const [isCreateBlogModalOpen, setIsCreateBlogModalOpen] = useState(false);
    
    const location = useLocation();
    const [article, setArticle] = useState({});
    const [articleHtml, setArticleHtml] = useState("");
    const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
    const [selectedBlog, setSelectedBlog] = useState(null);
    const [isPublishSelected, setIsPublishSelected] = useState(false);
    
    const handleCloseCreateBlogModal = () => {
        setIsCreateBlogModalOpen(false);
    };
    
    const handleClosePublishModal = () => {
        setIsPublishModalOpen(false);
    };
    
    const handleCreateBlog = () => {
        createBlog();
    }
    
    const handleBlogSelect = (id) => {
        setAllBlogs((prevItems) => {
            const isAnyBlogSelected = prevItems.some((item) => item.isChecked);
            return prevItems.map((item) => {
                if (isAnyBlogSelected && item.id !== id) {
                    return {...item, isChecked: false};
                }
                if (item.id === id) {
                    const isChecked = !item.isChecked;
                    if (isChecked) {
                        setSelectedBlog(item);
                    } else {
                        setSelectedBlog(null);
                    }
                    return {...item, isChecked};
                }
                return item;
            });
        });
    };
    
    const publishSelectedStatus = (status) => {
        setIsPublishSelected(status)
    }
    
    const showSEO = (value) => {
    }
    
    useEffect(() => {
        if (initialDataFetch) {
            if (!!token && !!user) {
                if (store === undefined || store === null || store === "") {
                    setAllBlogs([]);
                } else {
                    handleFetchBlog(account);
                }
                if (location.search) {
                    createTempStore(user.id);
                }
            } else {
                navigate(ROUTE_CONSTANT.LOGIN);
            }
        }
    }, [token, user, store]);
    
    
    const createTempStore = (userId) => {
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        let bodyParams = {
            userId: userId
        }
        API.post(`shopify/setup-temp-store`, bodyParams, headerParams)
            .then(res => {
            })
            .catch(error => {
                console.log('Error: ', error.message);
            })
    }
    
    const createBlog = () => {
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        let bodyParams = {
            blog_name: blogTitle
        }
        API.post(`shopify/blog/create/${account}/${store}`, bodyParams, headerParams)
            .then(res => {
            })
            .catch(error => {
                console.log('Error: ', error.message);
            })
    }
    
    
    const handleFetchBlog = (account) => {
        setLoading(true);
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        API.get(`shopify/blogs/${account}/${store}`, headerParams)
            .then(res => {
                /*if (res.data.data.blogs.length > 0) {
                    const blogsWithDomain = [];
                    const blogsWithoutDomain = [];
                    res.data.data.blogs.forEach((blog) => {
                        const blogWithChecked = {
                            ...blog,
                            isChecked: typeof blog.blogd_domain === 'string',
                        };
                        if (typeof blog.blogd_domain === 'string') {
                            blogsWithDomain.push(blogWithChecked);
                        } else {
                            blogsWithoutDomain.push(blogWithChecked);
                        }
                    });
                    setAllImportedBlogs(blogsWithDomain);
                    setAllBlogs(blogsWithoutDomain);
                    console.log("blogsWithDomain", blogsWithDomain);
                    console.log("blogsWithoutDomain", blogsWithoutDomain);
                } else {
                    setAllBlogs([]);
                }*/
                const {deployedBlogs, yetToBeDeployedBlogs, blogs} = res.data.data;
                setAllBlogs(blogs);
                setDeployedBlogs(deployedBlogs);
                setNotDeployedBlogs(yetToBeDeployedBlogs);
                setLoading(false);
            })
            .catch(error => {
                setAllBlogs([]);
                setLoading(false);
                setError(error.message);
            })
    }
    
    
    return (<div>
        
        <OBTopNav/>
        
        <div className="mt-6 tracking-wide mb-36">
            
            {!isPublishSelected && <div className="max-w-6xl mx-auto my-auto px-6 mb-8">
                
                {loading && <div className="flex justify-center text-center items-center mt-44">
                    <div className="text-center">
                        <div role="status" className={"text-center ml-8 mb-3"}>
                            <svg aria-hidden="true"
                                 className="w-12 h-12 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                 viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"/>
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"/>
                            </svg>
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>}
                
                {allBlogs.length >= 1 && <>
                    <div className="flex justify-between items-center">
                        {!loading && (<div className="">
                            <PageTitle>
                                We've uncovered <span
                                className="text-orange-600">{allBlogs.length} linked blogs</span> for your
                                store, <br/>enhance their efficiency with our provided features.
                            </PageTitle>
                        </div>)}
                        
                        <div className="text-center mt-2">
                            <div
                                className="bg-primary text-sm hover:bg-orange-600 cursor-pointer text-white inline-block font-bold py-2 px-8 rounded-lg"
                                onClick={() => {
                                    navigate(ROUTE_CONSTANT.CREATE_BLOG);
                                }}>
                                Create New Blog
                            </div>
                        </div>
                    </div>
                    
                    
                    {deployedBlogs.length > 0 && <div
                        className="text-[20px] font-bold text-gray-600 capitalize mt-8 ml-2">
                        Deployed Blogs
                    </div>}
                    
                    <div className="grid grid-cols-2 gap-3 mt-4">
                        {deployedBlogs && deployedBlogs.map((item) => (<div
                            key={item.id}
                            className="relative w-full p-6 flex bg-white border border-gray-200 shadow-lg rounded-lg hover:border-gray-300"
                        >
                            <div className="">
                                <div
                                    className="p-1 text-[20px] font-bold text-gray-600 capitalize">
                                    {item.title} Blog
                                </div>
                                <div
                                    className="pt-1 pl-1 text-[14px]">
                                    <a href={`https://${item.blogd_domain}/${item.handle}/index.html`}
                                       target="_blank">https://{item.blogd_domain}/{item.handle}</a>
                                </div>
                                <div className="p-1 text-gray-600 mt-1">
                                    <button
                                        onClick={() => {
                                            localStorage.setItem("blog_uuid", item.uuid);
                                            localStorage.setItem("blog_handle", item.handle);
                                            navigate(`/blogs/${item.handle}/articles`, {
                                                state: {
                                                    blog_id: item.id,
                                                    blog_uuid: item.uuid,
                                                    blog_title: item.title,
                                                    blog_handle: item.handle
                                                }
                                            })
                                        }}
                                        className="text-primary mt-4 text-xs text-white font-bold underline uppercase rounded-lg">
                                        View Articles
                                    </button>
                                </div>
                                <div className="absolute right-3 text-[140px] font-bold text-gray-200 capitalize"
                                     style={{bottom: "-53px"}}>
                                    {_.head(item.title)}
                                </div>
                            </div>
                        </div>))}
                    </div>
                    
                    <hr/>
                    
                    {notDeployedBlogs.length > 0 && <div
                        className="text-[20px] font-bold text-gray-600 capitalize mt-12 ml-2">
                        Blogs to be deployed
                    </div>}
                    
                    <div className="grid grid-cols-2 gap-3 mt-4">
                        {notDeployedBlogs && notDeployedBlogs.map((item) => (<div
                            key={item.id}
                            className="relative w-full p-6 flex bg-white border border-gray-200 shadow-lg rounded-lg hover:border-gray-300">
                            <div className="">
                                <div className={`flex mt-1 cursor-pointer`}>
                                    <div className="flex items-center p-1 mb-2">
                                        <input
                                            id={`default-checkbox-${item.id}`}
                                            type="checkbox"
                                            value=""
                                            checked={item.isChecked}
                                            className={`w-6 h-6 text-orange-600 bg-gray-100 border-gray-300 rounded`}
                                            onClick={() => {
                                                if (!item.blogd_domain) {
                                                    handleBlogSelect(item.id)
                                                }
                                            }}
                                        />
                                    </div>
                                    <div
                                        className="pl-2 text-[20px] font-bold text-gray-600 capitalize">
                                        {item.title} Blog
                                    </div>
                                </div>
                                <div
                                    className="pt-1 pl-1 text-[14px] underline text-blue-600">
                                    {item.blogd_domain ? item.blogd_domain : ''}
                                </div>
                                <div className="p-1 text-gray-600">
                                    <button
                                        onClick={() => {
                                            localStorage.setItem("blog_uuid", item.uuid);
                                            localStorage.setItem("blog_handle", item.handle);
                                            navigate(`/blogs/${item.handle}/articles`, {
                                                state: {
                                                    blog_id: item.id,
                                                    blog_uuid: item.uuid,
                                                    blog_title: item.title,
                                                    blog_handle: item.handle
                                                }
                                            })
                                        }}
                                        className="text-primary text-xs text-white font-bold underline uppercase rounded-lg">
                                        View Articles
                                    </button>
                                </div>
                                <div className="absolute right-8 text-[100px] font-bold text-gray-200 uppercase"
                                     style={{bottom: "-37px"}}>
                                    {_.head(item.title)}
                                </div>
                            </div>
                        </div>))}
                    </div>
                </>}
                
                {!loading && <>
                    {selectedBlog && <div className="fixed bottom-8 inset-x-0 text-center">
                        <div className="mt-12 flex mx-auto text-center justify-center">
                            <button
                                onClick={() => {
                                    setIsPublishSelected(true);
                                }}
                                className="bg-primary hover:bg-orange-600 text-white uppercase  font-bold py-4 px-16 rounded-lg shadow">
                                Migrate
                            </button>
                        </div>
                    </div>}
                </>}
                
                
                {allBlogs.length < 1 && <>
                    {!loading && <div className="flex text-center items-center justify-center -mt-16 min-h-screen">
                        <div className="bg-white rounded-xl p-12 shadow">
                            <img src={EmptyImage} alt="Your Image" className="w-[30%] h-auto"/>
                            <p className={"font-bold text-gray-600 mt-8"}>No Blogs Found</p>
                            <p className={"text-gray-600 mt-3"}>Unleash your thoughts, transform your ideas
                                into <br/>
                                a masterpiece — start your blog today!</p>
                            <button
                                onClick={() => {
                                    navigate(ROUTE_CONSTANT.CREATE_BLOG);
                                }}
                                className="bg-primary hover:bg-orange-600 text-white font-bold py-2 px-8 rounded-lg mt-6">
                                Create Blog
                            </button>
                        </div>
                    </div>}
                </>}
                
                {isCreateBlogModalOpen && (<AppModalShort title={""}>
                    <div className="">
                        <div className="flex justify-between py-3 px-1 text-sm text-bold">
                            <div className="text-left">
                                <h2 className="text-xl font-bold">Create New Blog</h2>
                            </div>
                            
                            <div onClick={handleCloseCreateBlogModal}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 cursor-pointer">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M6 18L18 6M6 6l12 12"/>
                                </svg>
                            </div>
                        </div>
                        
                        <div className="py-3 px-1">
                            
                            <form className="flex flex-col gap-4">
                                <div>
                                    <div className="mb-2 block">
                                        <label htmlFor="title"
                                               className="block mb-2 ml-1 text-sm font-medium text-gray-900 dark:text-white">
                                            Blog Title
                                        </label>
                                    </div>
                                    <input type="name" id="title"
                                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                           placeholder="Health Nutrition"
                                           onChange={(e) => setBlogTitle(e.target.value)}
                                           required={true}/>
                                </div>
                                
                                <button type="button"
                                        onClick={handleCreateBlog}
                                        className="text-white mt-6 hover:bg-red-500 bg-primary focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                                    {!loading ? "Proceed" : "Creating blog..."}
                                </button>
                            
                            </form>
                        
                        </div>
                    </div>
                </AppModalShort>)}
                
                
                {isPublishModalOpen && (<AppModalLong title={""}>
                    <div className="">
                        <div className="flex justify-between py-3 px-1 text-sm text-bold">
                            <div className="text-left">
                                <h2 className="text-xl font-bold">Publish Blog</h2>
                            </div>
                            
                            <div onClick={() => {
                                handleClosePublishModal();
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 cursor-pointer">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M6 18L18 6M6 6l12 12"/>
                                </svg>
                            </div>
                        </div>
                        
                        <div className="pb-1 px-1">
                            
                            <DeployForm
                                article={article}
                                article_html={articleHtml}
                                handleClosePublishModal={() => {
                                    handleClosePublishModal();
                                }}
                                article_id={""}
                            />
                        
                        </div>
                    
                    </div>
                </AppModalLong>)}
            
            
            </div>}
            
            {isPublishSelected && <div className="max-w-6xl mx-auto my-auto px-6 mb-8">
                <ChooseTemplate
                    isMigrate={true}
                    blog={selectedBlog}
                    isRouteChange={true}
                    showSEO={showSEO}
                    publishSelectedStatus={publishSelectedStatus}/>
            </div>}
        
        </div>
    </div>)
}
