import React, {useContext, useEffect, useRef, useState} from "react";
import Quill from "quill";
import ReactQuill from "react-quill";
import SectionInputHeader from "../shared/SectionInputHeader";
import SectionHeader from "../shared/SectionHeader";
import DeleteEditorSection from "../shared/DeleteEditorSection";
import {redoChange, undoChange} from "../../../modules/Editor/EditorToolbar";
import CustomToolbar from "../CustomToolbar";
import GenerateSubTitleSection from "../shared/GenerateSubTitleSection";
import _ from "lodash";
import QuillEditorContext from "../../quill/context/QuillEditorContext";


const formatsList = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "align",
    "strike",
    "script",
    "blockquote",
    "background",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "code-block", "formula", "youtubeEmbedBlot", "my-blot", "social-media-blot"
];

const EditorComponent = (
    {
        editors,
        editor,
        onEditorChange,
        handleSelectionChange,
        quillRefs,
        editSubTitleId,
        deleteEditor,
        titleValue,
        handleSubTitleBlur,
        handleSubTitleClick,
        handleRegenerateSubTitle,
        handleSaveSubtitle,
        index,
        selectedText,
        setSelectedText,
        setEditorIndex,
        setBubbleMenuClicked,
        sectionRefs,
        generatingTitle,
    }) => {
    
    const [showUI, setShowUI] = useState(false);
    const [uiPosition, setUiPosition] = useState({top: 0, left: 0});
    const {updateEditorIndex, updateSelectedText} = useContext(QuillEditorContext);
    
    const modulesList = {
        toolbar: {
            container: `#toolbar-${editor.id}`,
            handlers: {
                undo: undoChange,
                redo: redoChange,
            }
        },
        history: {
            delay: 500,
            maxStack: 100,
            userOnly: true
        },
        imageResize: {
            parchment: Quill.import('parchment'),
            modules: ['Resize', 'DisplaySize']
        }
    };
    
    useEffect(() => {
        const quill = quillRefs.current[index].getEditor();
        quill.setSelection(0, 0);
    }, [editor.id]);
    
    useEffect(() => {
        const quill = quillRefs.current[index].getEditor();
        const handleTextSelect = () => {
            const range = quill.getSelection();
            if (range && range.length > 0) {
                const bounds = quill.getBounds(range);
                const selectedText = quill.getText(range.index, range.length);
                setUiPosition({top: bounds.top, left: bounds.left, width: bounds.width});
                setSelectedText(selectedText);
                updateSelectedText(selectedText);
                updateEditorIndex(index);
                setShowUI(true);
            } else {
                setShowUI(false);
            }
        };
        quill.on('selection-change', handleTextSelect);
        return () => {
            quill.off('selection-change', handleTextSelect);
        };
    }, []);
    
    const handleWidgetClick = (e) => {
        e.stopPropagation();
        // setCollapsed(collapsed);
        setBubbleMenuClicked(3);
        setEditorIndex(index);
        setShowUI(false);
    };
    
    
    return (
        <div>
            <div
                ref={sectionRefs[_.kebabCase(editor.title)]}
                className="w-full text-left flex items-center h-14 px-5 bg-gray-100">
                {editSubTitleId === editor.id ? (
                    <SectionInputHeader
                        editorId={editor.id}
                        titleValue={titleValue}
                        handleSubTitleBlur={handleSaveSubtitle}
                        handleSaveSubtitle={handleSaveSubtitle}/>
                ) : (
                    <SectionHeader editor={editor} handleSubTitleClick={handleSubTitleClick}/>
                )}
                
                <div className="ml-auto flex">
                    <GenerateSubTitleSection
                        editorId={editor.id}
                        editors={editors}
                        editor={editor}
                        titleValue={titleValue}
                        generatingTitle={generatingTitle}
                        handleRegenerateSubTitle={handleRegenerateSubTitle}
                        setSelectedEditor={deleteEditor}/>
                    
                    <DeleteEditorSection
                        editors={editors}
                        editor={editor}
                        setSelectedEditor={deleteEditor}/>
                </div>
            </div>
            
            <div className="relative pb-1">
                <CustomToolbar editor={editor} selectedText={selectedText} quillRefs={quillRefs}/>
                
                <ReactQuill
                    theme="snow"
                    value={editor.content}
                    modules={modulesList}
                    formats={formatsList}
                    ref={(el) => (quillRefs.current[index] = el)}
                    onChange={(content) => onEditorChange(editor.id, content)}
                    onChangeSelection={handleSelectionChange}
                />
                
                {showUI && (
                    <button className={"rounded-lg cursor-pointer text-center"}
                         style={{
                             position: 'absolute',
                             top: uiPosition.top + 3,
                             left: uiPosition.left,
                         }}
                         onClick={handleWidgetClick}>
                        <div
                            className={"bg-white shadow-xl text-orange-500 rounded-lg border border-gray-300 py-[6px] px-[8px] font-bold"}>
                            <div className="px-3 py-1 text-[14px] rounded-lg hover:bg-gray-100">
                                Add Product Widget
                            </div>
                        </div>
                    </button>
                )}
            </div>
        </div>
    );
};

export default EditorComponent;
