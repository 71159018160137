import BlogdLogoDark from "../../assets/img/blogd_dark_logo.png";
import React, {useContext, useEffect, useState} from "react";
import {MODE_STRING, ROUTE_CONSTANT} from "../../constants/app.constants";
import AppContext from "../../context/AppContext";
import {useNavigate} from "react-router";
import {useLocation} from "react-router-dom";

const OBTopNav = () => {
    
    const navigate = useNavigate();
    const {afterLogout, storeModeData, initialDataFetch, token, user, mode} = useContext(AppContext);
    const [isOpenStoreDropDown, setOpenStoreDropDown] = useState(false);
    const [isOpenAccountDropDown, setOpenAccountDropDown] = useState(false);
    
    const location = useLocation();
    const pathSegments = location.pathname.split('/');
    const lastPathname = pathSegments[1]; // blogs
    
    useEffect(() => {
        if (initialDataFetch) {
            if (!!token && !!user) {
            } else {
                navigate(ROUTE_CONSTANT.LOGIN);
            }
        }
    }, [token, user]);
    
    const handleAccountDropDown = () => {
        setOpenAccountDropDown(!isOpenAccountDropDown);
        setOpenStoreDropDown(false);
    };
    
    const handleLogout = async () => {
        try {
            afterLogout();
            navigate(ROUTE_CONSTANT.LOGIN);
        } catch (error) {
            console.log(error.message);
        }
    };
    
    return (
        <div className="min-h-full sticky top-0 z-40">
            <nav className="bg-white border-b">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="h-16 items-center flex">
                        <div className="flex items-center text-left">
                            <div className="flex-shrink-0 ml-16 mt-1">
                                {/*<h4 className="text-gray-900 text-lg font-bold">Blogd</h4>*/}
                                <img src={BlogdLogoDark} className="mx-auto" width="60" alt="Empty"/>
                            </div>
                        </div>
                        
                        
                        <div className="hidden md:block text-right float-right ml-auto">
                            
                            <>
                                <div className="flex md:ml-6 mr-12">
                                    
                                    <button type="button"
                                            className="rounded-full ml-2 p-2 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                                            id="account-menu-button" aria-expanded="true" aria-haspopup="true"
                                            onClick={handleAccountDropDown}>
                                        <div className="flex">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                 strokeWidth="1.5"
                                                 stroke="currentColor" className="w-5 h-5 ml-2">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                      d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"/>
                                            </svg>
                                            
                                            {/*<div className="font-semibold text-sm ml-3">{user.name}</div>*/}
                                            
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                 strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 ml-3 mr-2">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
                                            </svg>
                                        </div>
                                    </button>
                                </div>
                                
                                {isOpenAccountDropDown && <div
                                    className="absolute right-22 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                    role="menu" aria-orientation="vertical" aria-labelledby="account-menu-button"
                                    tabIndex="-1">
                                    <div className="py-1" role="none">
                                        <button type="submit"
                                                className="text-gray-700 block w-full px-4 py-2 text-left text-sm
                                                hover:bg-red-200 hover:text-red-700"
                                                role="menuitem" tabIndex="-1" id="menu-item-3"
                                                onClick={handleLogout}>
                                            Log Out
                                        </button>
                                    </div>
                                </div>}
                            </>
                        </div>
                    </div>
                </div>
            </nav>
        
        </div>
    )
}

export default OBTopNav;
