import React, {useEffect, useState} from 'react'
import {useNavigate} from "react-router";
import Sidebar from "../components/layouts/Sidebar";
import TopNav from "../components/layouts/TopNav";
import PageTitle from "../components/typography/PageTitle";


export default function Notepad() {
    
    const navigate = useNavigate();
    
    useEffect(() => {
    }, [])
    
    return (
        <div>
    
            <Sidebar/>
    
            <TopNav/>
    
    
            <div className="ml-52 px-6 mx-auto mb-8">
        
                <div className="ml-3">
                    <PageTitle>Notepad</PageTitle>
                </div>
    
            </div>
            
        </div>
    )
}
