import React, {useRef, useState} from "react";
import "../scss/custom-editor.scss";

const SectionInputHeader = ({editorId, titleValue, handleSubtitleBlur, handleSaveSubtitle}) => {
    
    const inputRef = useRef(null);
    const [title, setTitle] = useState(titleValue);
    
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            inputRef.current.blur();
        }
    };
    
    return (
        <>
            <input
                ref={inputRef}
                className="w-3/4 bg-transparent font-semibold rounded-lg py-1 px-2 outline-none section-input-header"
                type="text"
                placeholder={"Enter Section Title"}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                // onChange={(content) => onTitleChange(editorId, content.target.value)}
                onBlur={() => handleSaveSubtitle(editorId, title)}
                onKeyDown={handleKeyDown}
                autoFocus
            />
            {/*<div className="pl-4 cursor-pointer"
                 onClick={() => handleSaveSubtitle(editorId, title)}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                     strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round"
                          d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                </svg>
            </div>
            <div className="pl-2 cursor-pointer"
                 onClick={() => handleSubtitleBlur()}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                     strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round"
                          d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                </svg>
            </div>*/}
        </>
    )
};


export default SectionInputHeader;
