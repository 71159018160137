import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {ROUTE_CONSTANT, MODE_STRING} from "../../constants/app.constants";
import AppContext from "../../context/AppContext";
import BlogdLogoDark from "../../assets/img/blogd_dark_logo.png";
import {useLocation} from "react-router-dom";


const menus = [
    {id: 0, name: 'Dashboard', subString: 'dashboard', route: 'dashboard'},
    {id: 1, name: 'Articles', subString: 'articles', route: 'articles'},
    {id: 2, name: 'Widgets', subString: 'widgets', route: 'widgets'},
    {id: 3, name: 'Analytics', subString: 'analytics', route: 'analytics'},
    {id: 4, name: 'Settings', subString: 'settings', route: 'settings'},
]


const SecondaryTopNav = () => {
    
    const navigate = useNavigate();
    const {pathname} = window.location;
    const location = useLocation();
    
    const {blog_id, blog_uuid, blog_title, blog_handle} = location.state;
    const [selectedMenu, setSelectedMenu] = useState("");
    
    useEffect(() => {
        const parts = pathname.split('/');
        const lastPart = parts[parts.length - 1];
        setSelectedMenu(lastPart);
    }, [pathname]);
    
    return (
        <div className="min-h-full sticky top-0 z-30">
            <nav className="bg-white border-b">
                <div className="mx-auto w-full px-4 sm:px-6 lg:px-8">
                    <div className="h-14 items-center flex justify-around">
                        <div className="flex items-center text-left">
                            <div className="flex-shrink-0">
                                
                                <div className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
                                     id="navbar-sticky">
                                    <div
                                        className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                                        {menus.map(menu => (
                                            <>
                                                {selectedMenu === menu.subString && <div
                                                    key={menu.subString}
                                                    className="block cursor-pointer py-2 px-3 text-orange-600 font-bold rounded-lg text-sm"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        navigate(`/blogs/${blog_handle}/${menu.route}`, {
                                                            state: {blog_id, blog_uuid, blog_title, blog_handle}
                                                        });
                                                    }}>
                                                    {menu.name}
                                                </div>}
                                                
                                                {selectedMenu !== menu.subString && <div
                                                    key={menu.subString}
                                                    className="block cursor-pointer py-2 px-3 text-gray-700 rounded-lg text-sm"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        navigate(`/blogs/${blog_handle}/${menu.route}`, {
                                                            state: {blog_id, blog_uuid, blog_title, blog_handle}
                                                        });
                                                    }}>
                                                    {menu.name}
                                                </div>}
                                            </>
                                        ))}
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        
        </div>
    )
}

export default SecondaryTopNav
