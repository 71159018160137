import React, {useState, useEffect, useMemo, useRef, useContext} from 'react';
import 'react-quill/dist/quill.snow.css';
// import "./scss/custom-editor.scss";
import EmbedProductCard from "./EmbedProductCard";
import EditorComponent from "./editor/EditorComponent";
import _ from "lodash";
import API from "../../api/api";
import {toastError, toastSuccess} from "../../context/ToasterContext";
import AppContext from "../../context/AppContext";
import {useLocation} from "react-router-dom";

const DynamicEditorSection = (
    {
        quillRefs,
        selectedText,
        setSelectedText,
        setEditorIndex,
        setBubbleMenuClicked,
        article,
        setArticle,
        articleContent,
        setArticleContent,
        sectionRefs,
        setChangeContent,
        setSectionsRef
    }) => {
    
    const [editors, setEditors] = useState([{id: 1, title: '', sub_title: '', content: '', type: '', show: true}]);
    const [activeEditor, setActiveEditor] = useState(null);
    const [editSubTitleId, setEditSubTitleId] = useState(null);
    const [titleValue, setTitleValue] = useState('');
    const [showProductCardWidget, setShowProductCardWidget] = useState(false);
    
    const inputRef = useRef(null);
    const [popupPosition, setPopupPosition] = useState({left: 0, top: 0});
    const [showPopup, setShowPopup] = useState(false);
    
    let {initialDataFetch, token, user, account, store} = useContext(AppContext);
    const location = useLocation();
    const {article_id, blog_id, blog_uuid, blog_title, blog_handle, article_uuid} = location.state;
    const [generatingTitle, setGeneratingTitle] = useState(false);
    
    
    useEffect(() => {
        const savedArticle = localStorage.getItem('article');
        if (savedArticle) {
            setEditors(JSON.parse(savedArticle));
            setArticleContent(editorsToHtml(JSON.parse(savedArticle)));
        } else {
            const editorObjects = htmlToEditors(articleContent);
            setEditors(editorObjects);
            setArticleContent(editorsToHtml(editorObjects));
        }
    }, [localStorage.getItem('article')]);
    
    
    const handleSelectionChange = (range, oldRange, source) => {
        if (range && range.length > 0 && range.start !== range.end) {
            const quill = inputRef.current.getEditor();
            const selectedText = quill.getText(range.index, range.length);
            setSelectedText(selectedText);
            const boundingRect = quill.getBounds(range.index, range.length);
            setPopupPosition({left: boundingRect.left + boundingRect.width / 2, top: boundingRect.top});
            setShowPopup(true);
            setSelectedText(selectedText);
        } else {
            setShowPopup(false);
        }
    }
    
    const onEditorChange = (id, content) => {
        // console.log("onEditorChange", id, content);
        setEditors((prevEditors) => {
            const newEditors = prevEditors.map((editor) =>
                editor.id === id ? {...editor, content: content} : editor
            );
            setArticleContent(editorsToHtml(newEditors));
            setChangeContent(editorsToHtml(newEditors));
            return newEditors;
        });
    };
    
    const applyProductWidget = (id, content) => {
        const widget = `<div data-id="product_card" data-metadata="product_card" class="mx-auto p-4 flex flex-col items-center mt-6">
                                  <img src="https://plus.unsplash.com/premium_photo-1670426502067-99395cc491c2" alt="Product Image"
                                  class="w-36 max-w-full rounded-lg shadow-md"/>
                                  <div class="text-center mt-4">
                                    <div class="font-bold mb-3" data-product-id='${JSON.stringify(content.product_data)}'>
                                    ${content.product_data.title}</div>
                                    <button class="text-white font-semibold py-1 px-4 rounded-lg ml-1"
                                    style="background-color: ${content.button_color};"
                                    onclick="window.open('https://www.google.com');">Buy Now</button>
                                  </div>
                                </div>`
        setEditors((prevEditors) => {
            const newEditors = prevEditors.map((editor) =>
                editor.id === id ? {
                    ...editor, content: widget, type: 'product_card', title: 'Product Card Widget',
                } : editor
            );
            setArticleContent(editorsToHtml(newEditors));
            setChangeContent(editorsToHtml(newEditors));
            return newEditors;
        });
    }
    
    const handleSaveSubtitle = (editorId, title) => {
        setEditors((prevEditors) => {
            const newEditors = prevEditors.map((editor) =>
                editor.id === editorId ? {...editor, title: title, type: 'editor'} : editor
            );
            setArticleContent(editorsToHtml(newEditors));
            setChangeContent(editorsToHtml(newEditors));
            return newEditors;
        });
        handleSubTitleBlur();
    }
    
    const handleRegenerateSubTitle = (editor, titleValue) => {
        setGeneratingTitle(true);
        const currEditor = editor;
        const payload = {
            "userApiKey": "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",
            "data": {
                "id": currEditor.dataId,
                "title": currEditor.title,
                "level": 2
            }
        };
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        API.post(`article-generator/regenerate-title-in-editor/${account}/${store}/${blog_uuid}/${article_uuid}`, payload, headerParams)
            .then(res => {
                toastSuccess("Saved your changes");
                const {title} = res.data.data;
                setGeneratingTitle(false);
                setEditors((prevEditors) => {
                    const newEditors = prevEditors.map((editor) =>
                        editor.id === currEditor.id ? {...editor, title: title, type: 'editor'} : editor
                    );
                    setArticleContent(editorsToHtml(newEditors));
                    setChangeContent(editorsToHtml(newEditors));
                    return newEditors;
                });
            })
            .catch(error => {
                setGeneratingTitle(false);
                toastError(error.message);
            })
    }
    
    
    const handleSubTitleBlur = () => {
        setEditSubTitleId(null);
    };
    
    const handleSubTitleClick = (editorId, title) => {
        setEditSubTitleId(editorId);
        setTitleValue(title);
    };
    
    const addEditor = () => {
        const newEditorId = editors?.length + 1;
        setEditors([...editors, {id: newEditorId, content: '', type: 'editor', show: true}]);
    };
    
    const deleteEditor = editors => {
        setEditors(editors);
        setArticleContent(editorsToHtml(editors));
        setChangeContent(editorsToHtml(editors));
    };
    
    const addNewProductCardWidget = () => {
        const newEditorId = editors?.length + 1;
        setEditors([...editors, {id: newEditorId, content: '', type: 'product_card', show: true}]);
    };
    
    const deleteWidget = (editors) => {
        setEditors(editors);
        setArticleContent(editorsToHtml(editors));
        setChangeContent(editorsToHtml(editors));
    };
    
    const htmlToEditors = (html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const editorObjects = [];
        let currentEditor = null;
        
        Array.from(doc.body.children).forEach(element => {
            const metadataType = element.getAttribute('data-metadata');
            if (metadataType === 'editor') {
                currentEditor = {
                    id: editorObjects.length + 1,
                    dataId: element.getAttribute('data-id'),
                    title: element.tagName === 'H2' ? element.textContent : '',
                    sub_title: element.tagName === 'H3' ? element.textContent : '',
                    content: "",
                    type: metadataType,
                    show: false,
                };
                editorObjects.push(currentEditor);
            } else if (metadataType === 'product_card') {
                const productData = element.querySelector('[data-product-id]')?.getAttribute('data-product-id');
                if (productData) {
                    currentEditor = {
                        id: editorObjects.length + 1,
                        dataId: element.getAttribute('data-id'),
                        title: 'Product Card Widget',
                        content: element.outerHTML,
                        type: metadataType,
                        show: false,
                        product_data: productData
                    };
                    editorObjects.push(currentEditor);
                }
            } else if (element.tagName === "H3") {
                const elementContent = element.textContent;
                const kebabCaseRef = _.kebabCase(elementContent);
                const h3WithRef = `<h3 ref={sectionRefs[${kebabCaseRef}]}>${element.innerHTML}</h3>`;
                if (currentEditor) {
                    currentEditor.content += h3WithRef;
                }
            } else {
                if (currentEditor) {
                    currentEditor.content += element.outerHTML;
                }
            }
        });
        return editorObjects;
    };
    
    
    const editorsToHtml = (editors) => {
        let html = '';
        editors.forEach(editor => {
            if (editor.type === 'editor') {
                html += `<h2 data-id={editor.dataId} data-metadata="editor">${editor.title}</h2>`;
                html += editor.content;
            } else {
                html += `<h4 data-id="product_card" data-metadata="product_card"></h4>`;
                html += editor.content;
            }
        });
        return html;
    };
    
    useEffect(() => {
        editors.forEach((editor, index) => {
            const quill = quillRefs.current[index]?.getEditor();
            if (quill) {
                const handleTextSelect = () => {
                    const range = quill.getSelection();
                    if (range && range.length > 0) {
                        const bounds = quill.getBounds(range);
                        updateEditorState(editor.id, {
                            showUI: true,
                            uiPosition: {top: bounds.top, left: bounds.left},
                        });
                    } else {
                        updateEditorState(editor.id, {showUI: false});
                    }
                };
                
                quill.on('selection-change', handleTextSelect);
                
                return () => {
                    quill.off('selection-change', handleTextSelect);
                };
            }
        });
    }, [editors]);
    
    const updateEditorState = (id, changes) => {
        setEditors((prevEditors) =>
            prevEditors.map((editor) =>
                editor.id === id ? {...editor, ...changes, type: 'editor'} : editor
            )
        );
    };
    
    
    return (
        <div className="">
            
            <div className="flex">
                <div
                    className="font-bold text-[28px] cursor-pointer ml-3 mb-3">
                    {article.title}
                </div>
            </div>
            
            {editors?.map((editor, index) => (
                <>
                    {editor.type === 'product_card' ? (
                        <div className="">
                            <div key={editor.id}
                                 className="">
                                <EmbedProductCard
                                    selectedProduct={editor.product_data}
                                    editor={editor}
                                    editors={editors}
                                    setSelectedEditor={deleteWidget}
                                    applyProductWidget={applyProductWidget}/>
                            </div>
                        </div>
                    ) : (
                        <div key={editor.id} className="border rounded-lg my-2 mt-3 bg-white shadow">
                            <div style={{maxHeight: '80vh', overflowY: 'auto'}}>
                                <EditorComponent
                                    key={editor.id}
                                    editor={editor}
                                    editors={editors}
                                    onEditorChange={onEditorChange}
                                    handleSelectionChange={handleSelectionChange}
                                    quillRefs={quillRefs}
                                    editSubTitleId={editSubTitleId}
                                    deleteEditor={deleteEditor}
                                    titleValue={titleValue}
                                    handleSubTitleBlur={handleSubTitleClick}
                                    handleSubTitleClick={handleSubTitleClick}
                                    handleSaveSubtitle={handleSaveSubtitle}
                                    handleRegenerateSubTitle={handleRegenerateSubTitle}
                                    index={index}
                                    selectedText={selectedText}
                                    setSelectedText={setSelectedText}
                                    setEditorIndex={setEditorIndex}
                                    setBubbleMenuClicked={setBubbleMenuClicked}
                                    sectionRefs={sectionRefs}
                                    generatingTitle={generatingTitle}
                                />
                            </div>
                        </div>
                    )}
                </>
            ))}
            
            <div className="text-center mx-auto">
                <button
                    className="mt-2 bg-orange-600 hover:bg-orange-700 text-white font-semibold py-2 px-4 rounded-lg m-1"
                    onClick={addEditor}>
                    Add Section
                </button>
                <button
                    className="mt-2 bg-secondary hover:bg-yellow-400 text-black font-semibold py-2 px-4 rounded-lg m-1"
                    onClick={addNewProductCardWidget}>
                    Add Product Card Widget
                </button>
            </div>
            
            {showProductCardWidget &&
                <div className="border rounded-lg my-2 bg-white">
                
                </div>}
        </div>
    );
}

export default DynamicEditorSection;



