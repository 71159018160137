import React, {useContext, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router";
import AppContext from "../context/AppContext";
import API from "../api/api";
import {Toaster} from "react-hot-toast";
import Lottie from 'react-lottie';
import VerifyLottie from "../assets/lotties/payment-verify.json";
import {toastError, toastSuccess} from "../context/ToasterContext";

const PricingFailurePage = () => {
    
    const navigate = useNavigate();
    let {initialDataFetch, token, user, account, store} = useContext(AppContext);
    
    useEffect(() => {
    }, []);
    
    
    return (
        <div className="h-screen flex justify-center items-center">
            <div
                className="max-w-6xl bg-white rounded-xl border border-gray-300 shadow-lg mx-auto my-auto p-16">
                
                <div className="text-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                         stroke="currentColor" className="w-12 h-12">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"/>
                    </svg>
                </div>
                
                <div className="text-3xl font-bold mt-6">
                    Payment Failed
                </div>
                
                <div className="text-lg text-gray-600 mt-6">
                    Lorem ipsum dolor sit amet,
                    consectetur adipiscing edivt, sed do eiusmod <br/> tempor incididunt ut labore et dolore magna
                    adivqua.
                </div>
                
                <div className="flex">
                    
                    <button type="button"
                            onClick={() => {
                                navigate(-2);
                                // publishBlogWithSEOFix();
                            }}
                            className="text-white w-full font-bold hover:bg-orange-700 bg-orange-600 m-1.5 rounded-lg text-sm px-6 py-2.5 mt-8">
                        Retry Payment
                    </button>
                
                </div>
                
                <Toaster/>
            
            </div>
        </div>
    )
}

export default PricingFailurePage;
