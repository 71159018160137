import React from "react";

const QuillEditorContext = React.createContext({
    editorIndex: undefined,
    updateEditorIndex: undefined,
    popupPosition: undefined,
    updatePopupPosition: undefined,
    selectedEditorText: undefined,
    updateSelectedText: undefined,
    articleHtml: undefined,
    updateArticleHtml: undefined,
});

export default QuillEditorContext;
