import React, {useContext, useEffect, useState} from 'react'
import {useLocation, useNavigate, useParams} from "react-router";
import Sidebar from "../components/layouts/Sidebar";
import TopNav from "../components/layouts/TopNav";
import PageTitle from "../components/typography/PageTitle";
import SecondaryTopNav from "../components/layouts/SecondaryTopNav";
import API from "../api/api";
import AppContext from "../context/AppContext";
import {ROUTE_CONSTANT} from "../constants/app.constants";
import AppChart from "../components/layouts/AppChart";
import {CheckCircleIcon} from "lucide-react";
import axios from "axios";
import _ from "lodash";


const trafficSourceChart = {
    series: [{
        data: [10, 20, 15, 20, 5]
    }],
    options: {
        chart: {
            type: 'bar',
            height: 200,
            fontFamily: 'inherit',
            toolbar: {
                show: false
            }
        },
        plotOptions: {
            bar: {
                borderRadius: 10,
                borderRadiusApplication: 'end',
                horizontal: false,
                columnWidth: '30%',
            }
        },
        colors: ['#3b82f6'],
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: ['Direct', 'Referrals', 'Social', 'Search', 'Others'],
            labels: {
                style: {
                    fontSize: '13px'
                }
            }
        },
        yaxis: {
            labels: {
                style: {
                    fontSize: '13px'
                }
            }
        },
        grid: {
            show: false
        }
    },
};

const conversionMetricsChart = {
    series: [{
        data: [100, 330, 260, 370, 50]
    }],
    options: {
        chart: {
            type: 'bar',
            height: 200,
            fontFamily: 'inherit',
            toolbar: {
                show: false
            }
        },
        plotOptions: {
            bar: {
                borderRadius: 10,
                borderRadiusApplication: 'end',
                horizontal: true,
                barHeight: '45%',
            }
        },
        colors: ['#3b82f6'],
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: ['Email Signups', 'Product Redirects', 'Product Purchase', 'Lead Generations', 'Others'],
            labels: {
                style: {
                    fontSize: '13px'
                }
            }
        },
        yaxis: {
            labels: {
                style: {
                    fontSize: '13px'
                }
            }
        },
        grid: {
            show: false
        }
    },
};

const blogPostChart = {
    series:
        [{
            name: 'Bounce Rate',
            data: [10, 50, 20, 30, 40]
        }, {
            name: 'Exit Rate',
            data: [10, 30, 40, 30, 30]
        }],
    options: {
        chart: {
            height: 350,
            type: 'area',
            fontFamily: 'inherit',
            toolbar: {
                show: false
            },
            offsetX: 20,
            offsetY: 20,
            padding: {
                left: 20,
                right: 20,
                top: 20,
                bottom: 20
            }
        },
        colors: ['#4ade80', '#fbbf24'],
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            width: 0
        },
        xaxis: {
            categories: ["Article 1", "Article 2", "Article 3", "Article 4", "Article 5"],
            labels: {
                style: {
                    fontSize: '13px'
                }
            }
        },
        yaxis: {
            labels: {
                style: {
                    fontSize: '13px'
                }
            }
        },
        grid: {
            show: false
        },
    },
}

const analytics = [
    {
        id: 1,
        name: 'Total Blog Articles',
        stat: '581',
        change: '5.4%',
        color: 'orange',
        icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                   stroke="currentColor" className="w-6 h-6 text-white">
            <path strokeLinecap="round" strokeLinejoin="round"
                  d="M16.5 3.75V16.5L12 14.25 7.5 16.5V3.75m9 0H18A2.25 2.25 0 0 1 20.25 6v12A2.25 2.25 0 0 1 18 20.25H6A2.25 2.25 0 0 1 3.75 18V6A2.25 2.25 0 0 1 6 3.75h1.5m9 0h-9"/>
        </svg>
    },
    {
        id: 2,
        name: 'Published Articles',
        stat: '71,897',
        change: '122',
        color: 'green',
        icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                   stroke="currentColor" className="w-6 h-6 text-white">
            <path strokeLinecap="round" strokeLinejoin="round"
                  d="M16.5 3.75V16.5L12 14.25 7.5 16.5V3.75m9 0H18A2.25 2.25 0 0 1 20.25 6v12A2.25 2.25 0 0 1 18 20.25H6A2.25 2.25 0 0 1 3.75 18V6A2.25 2.25 0 0 1 6 3.75h1.5m9 0h-9"/>
        </svg>
    },
    {
        id: 3,
        name: 'Scheduled Articles',
        stat: '583',
        change: '5.4%',
        color: 'blue',
        icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                   stroke="currentColor" className="w-6 h-6 text-white">
            <path strokeLinecap="round" strokeLinejoin="round"
                  d="M16.5 3.75V16.5L12 14.25 7.5 16.5V3.75m9 0H18A2.25 2.25 0 0 1 20.25 6v12A2.25 2.25 0 0 1 18 20.25H6A2.25 2.25 0 0 1 3.75 18V6A2.25 2.25 0 0 1 6 3.75h1.5m9 0h-9"/>
        </svg>
    },
    {
        id: 4,
        name: 'Draft Articles',
        stat: '581',
        change: '5.4%',
        color: 'gray',
        icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                   stroke="currentColor" className="w-6 h-6 text-white">
            <path strokeLinecap="round" strokeLinejoin="round"
                  d="M16.5 3.75V16.5L12 14.25 7.5 16.5V3.75m9 0H18A2.25 2.25 0 0 1 20.25 6v12A2.25 2.25 0 0 1 18 20.25H6A2.25 2.25 0 0 1 3.75 18V6A2.25 2.25 0 0 1 6 3.75h1.5m9 0h-9"/>
        </svg>
    }
]

const blogPostPerformance = [
    {id: 1, stat: 197, name: "Unique Visitors"},
    {id: 2, stat: 197, name: "Average Time On Page"},
    {id: 3, stat: 197, name: "Bounce Rate"},
    {id: 4, stat: 197, name: "Exit Rate"},
]

const seoMetricStats = [
    {id: 1, stat: 197, name: "Organic Traffic", description: "No of visitors coming from search engines"},
    {id: 2, stat: 197, name: "Backlinks", description: "No of links from other websites pointing to blog"},
]

const quickLinks = [
    {
        id: 1,
        stat: 197,
        name: "Choose Theme",
        description: "Loren ipsum dolor sit amet consectetur elit.",
        label: "free",
        icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                   className="h-5 w-5 mr-3 mt-0.5">
            <path fillRule="evenodd"
                  d="M2.25 4.125c0-1.036.84-1.875 1.875-1.875h5.25c1.036 0 1.875.84 1.875 1.875V17.25a4.5 4.5 0 1 1-9 0V4.125Zm4.5 14.25a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z"
                  clipRule="evenodd"/>
            <path
                d="M10.719 21.75h9.156c1.036 0 1.875-.84 1.875-1.875v-5.25c0-1.036-.84-1.875-1.875-1.875h-.14l-8.742 8.743c-.09.089-.18.175-.274.257ZM12.738 17.625l6.474-6.474a1.875 1.875 0 0 0 0-2.651L15.5 4.787a1.875 1.875 0 0 0-2.651 0l-.1.099V17.25c0 .126-.003.251-.01.375Z"/>
        </svg>
    },
    {
        id: 2,
        stat: 197,
        name: "Setup Widgets",
        description: "Loren ipsum dolor sit amet consectetur elit.",
        label: "free",
        icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                   className="h-5 w-5 mr-3 mt-0.5">
            <path fillRule="evenodd"
                  d="M2.25 6a3 3 0 0 1 3-3h13.5a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V6Zm18 3H3.75v9a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5V9Zm-15-3.75A.75.75 0 0 0 4.5 6v.008c0 .414.336.75.75.75h.008a.75.75 0 0 0 .75-.75V6a.75.75 0 0 0-.75-.75H5.25Zm1.5.75a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H7.5a.75.75 0 0 1-.75-.75V6Zm3-.75A.75.75 0 0 0 9 6v.008c0 .414.336.75.75.75h.008a.75.75 0 0 0 .75-.75V6a.75.75 0 0 0-.75-.75H9.75Z"
                  clipRule="evenodd"/>
        </svg>
    },
    {
        id: 3,
        stat: 197,
        name: "Check SEO Status",
        description: "Loren ipsum dolor sit amet consectetur elit.",
        label: "free",
        icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                   className="h-5 w-5 mr-3 mt-0.5">
            <path
                d="M18.375 2.25c-1.035 0-1.875.84-1.875 1.875v15.75c0 1.035.84 1.875 1.875 1.875h.75c1.035 0 1.875-.84 1.875-1.875V4.125c0-1.036-.84-1.875-1.875-1.875h-.75ZM9.75 8.625c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-.75a1.875 1.875 0 0 1-1.875-1.875V8.625ZM3 13.125c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v6.75c0 1.035-.84 1.875-1.875 1.875h-.75A1.875 1.875 0 0 1 3 19.875v-6.75Z"/>
        </svg>
    },
    {
        id: 4,
        stat: 197,
        name: "Add Domain",
        description: "Loren ipsum dolor sit amet consectetur elit.",
        label: "paid",
        icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                   className="h-5 w-5 mr-3 mt-0.5">
            <path d="M11.625 16.5a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75Z"/>
            <path fillRule="evenodd"
                  d="M5.625 1.5H9a3.75 3.75 0 0 1 3.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 0 1 3.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 0 1-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875Zm6 16.5c.66 0 1.277-.19 1.797-.518l1.048 1.048a.75.75 0 0 0 1.06-1.06l-1.047-1.048A3.375 3.375 0 1 0 11.625 18Z"
                  clipRule="evenodd"/>
            <path
                d="M14.25 5.25a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 16.5 7.5h-1.875a.375.375 0 0 1-.375-.375V5.25Z"/>
        </svg>
        
    },
]

const seoKeywordScore = [
    {id: 1, score: 197, keyword: "pancakes"},
    {id: 2, score: 197, keyword: "flowers"},
    {id: 3, score: 197, keyword: "t-shirts"},
    {id: 4, score: 197, keyword: "sunshine tees"},
    {id: 5, score: 197, keyword: "sticker forms"},
    {id: 6, score: 197, keyword: "cartoons"},
]

const scheduledArticles = [
    {id: 1, articleName: "Get the Perfect Fit with Flattering CutPosted", scheduledTime: "July 14, 2024 • 9:30 AM"},
    {id: 2, articleName: "Colored Mug Craze", scheduledTime: "July 20, 2024 • 12:30 PM"},
    {
        id: 3,
        articleName: "How the 3D Maze Illusion Adds a Pop of Color to Your Day!",
        scheduledTime: "July 20, 2024 • 12:30 PM"
    },
]

export default function Dashboard() {
    
    const navigate = useNavigate();
    const location = useLocation();
    let {initialDataFetch, token, user, account, store} = useContext(AppContext);
    const [nudgeCountData, setNudgeCountData] = useState(null);
    const [latestArticleViewCount, setLatestArticleViewCount] = useState(null);
    const [topArticleViewCount, setTopArticleViewCount] = useState(null);
    const [topRedirectedArticle, setTopRedirectedArticle] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const {blog_id, blog_uuid, blog_title, blog_handle} = location.state;
    const [totalArticleStat, setTotalArticleStat] = useState(null);
    const [draftArticles, setDraftArticles] = useState(null);
    const [postPerformance, setPostPerformance] = useState(null);
    const [trafficSource, setTrafficSource] = useState(null);
    const [conversionMetrics, setConversionMetrics] = useState(null);
    
    
    useEffect(() => {
        if (initialDataFetch) {
            if (!!token && !!user && !!store) {
                fetchTotalArticles();
                fetchBlogPerformance();
                fetchTrafficSource();
                fetchConversionMetrics();
            } else {
                navigate(ROUTE_CONSTANT.LOGIN);
            }
        }
    }, [initialDataFetch, token, user, store]);
    
    
    const fetchTotalArticles = () => {
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        axios.get(`http://localhost:4001/api/v1/dashboard/total-articles/${account}/${store}/${blog_uuid}`, headerParams)
            .then(res => {
                setTotalArticleStat(res.data.data);
                setDraftArticles(res.data.data.draft_articles);
            })
            .catch(error => {
                console.log("error: ", error);
            })
    }
    
    const mergedTotalArticlesData = _.map(analytics, (item) => {
        switch (item.name) {
            case 'Total Blog Articles':
                return {...item, stat: totalArticleStat?.articles?.total_articles.toString()};
            case 'Published Articles':
                return {...item, stat: totalArticleStat?.articles?.published.toString()};
            case 'Draft Articles':
                return {...item, stat: totalArticleStat?.articles?.draft.toString()};
            case 'Scheduled Articles':
                return {...item, stat: totalArticleStat?.articles?.scheduled.toString()};
            default:
                return item;
        }
    });
    
    const fetchBlogPerformance = () => {
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        axios.get(`http://localhost:4001/api/v1/dashboard/post-performance/${account}/${store}/${blog_uuid}`, headerParams)
            .then(res => {
                setPostPerformance(res.data);
                console.log("PostPerformance ----- ", postPerformance);
            })
            .catch(error => {
                console.log("error: ", error);
            })
    }
    
    const fetchTrafficSource = () => {
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        axios.get(`http://localhost:4001/api/v1/dashboard/traffic-source/${account}/${store}/${blog_uuid}?frequency=last7days`, headerParams)
            .then(res => {
                setTrafficSource(res.data);
                console.log("TrafficSource ----- ", trafficSource);
            })
            .catch(error => {
                console.log("error: ", error);
            })
    }
    
    const fetchConversionMetrics = () => {
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        axios.get(`http://localhost:4001/api/v1/dashboard/conversion-metrics/${account}/${store}/${blog_uuid}`, headerParams)
            .then(res => {
                setConversionMetrics(res.data);
                console.log("ConversionMetrics ----- ", conversionMetrics);
            })
            .catch(error => {
                console.log("error: ", error);
            })
    }
    
    
    return (
        <div>
            
            <TopNav/>
            
            <SecondaryTopNav/>
            
            <div className="max-w-7xl px-6 mx-auto mb-28">
                
                <div className="ml-2 mt-6">
                    <h1>Dashboard</h1>
                </div>
                
                {/*<div className="mt-6">
                    <div className="rounded-lg bg-orange-100 border border-orange-300 p-4">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth="2" stroke="currentColor" className="text-orange-950 w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"/>
                                </svg>
                            </div>
                            <div className="ml-3">
                                <div className="font-bold text-orange-900 text-sm uppercase">Create your first article
                                </div>
                                <div className="mt-1 text-sm text-orange-900">
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                                </div>
                            </div>
                            <div className={"float-right my-auto ml-auto"}>
                                <button
                                    onClick={() => {
                                    }}
                                    className="flex bg-primary text-sm text-white font-bold py-2 px-8 rounded-lg uppercase">
                                    Create Article
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                         fill="currentColor" className="h-5 w-5 ml-2 my-auto">
                                        <path fillRule="evenodd"
                                              d="M16.72 7.72a.75.75 0 0 1 1.06 0l3.75 3.75a.75.75 0 0 1 0 1.06l-3.75 3.75a.75.75 0 1 1-1.06-1.06l2.47-2.47H3a.75.75 0 0 1 0-1.5h16.19l-2.47-2.47a.75.75 0 0 1 0-1.06Z"
                                              clipRule="evenodd"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>*/}
                
                <div className="mt-6">
                    <div className="rounded-lg bg-blue-100 border border-blue-300 p-4">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth="2" stroke="currentColor" className="text-blue-950 w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"/>
                                </svg>
                            </div>
                            <div className="ml-3">
                                <div className="font-bold text-blue-900 text-sm uppercase">Hey, it's been a long time
                                    since you've created an article
                                </div>
                                <div className="mt-1 text-sm text-blue-900">
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                                </div>
                            </div>
                            <div className={"float-right my-auto ml-auto"}>
                                <button
                                    onClick={() => {
                                    }}
                                    className="flex bg-blue-700 text-sm text-white font-bold py-2 px-8 rounded-lg uppercase">
                                    Create Article
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                         fill="currentColor" className="h-5 w-5 ml-2 my-auto">
                                        <path fillRule="evenodd"
                                              d="M16.72 7.72a.75.75 0 0 1 1.06 0l3.75 3.75a.75.75 0 0 1 0 1.06l-3.75 3.75a.75.75 0 1 1-1.06-1.06l2.47-2.47H3a.75.75 0 0 1 0-1.5h16.19l-2.47-2.47a.75.75 0 0 1 0-1.06Z"
                                              clipRule="evenodd"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                
                
                <div className="mt-6">
                    <div className="h-auto">
                        
                        <div className="grid grid-cols-12 gap-4">
                            <div
                                className="col-span-5 grid grid-cols-2 divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
                                {mergedTotalArticlesData.map((item) => (
                                    <div className="flex" key={item.name}>
                                        <div className={`rounded-md bg-${item.color}-500 p-2 my-auto ml-8`}>
                                            {item.icon}
                                        </div>
                                        
                                        <div className="my-auto ml-4">
                                            <div className="flex my-auto">
                                                <div
                                                    className={`flex my-auto items-baseline text-xl font-bold text-black`}>
                                                    {!!item.stat ? item.stat : "--"}
                                                </div>
                                            </div>
                                            <div className="text-sm font-semibold text-gray-500">{item.name}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            
                            <div className="col-span-7 grid grid-cols-2 gap-4">
                                {quickLinks.map(link => (
                                    <div
                                        className="bg-white shadow p-4 rounded-lg cursor-pointer">
                                        <div className="flex">
                                            <div className="">
                                                {link.icon}
                                            </div>
                                            <div>
                                                <div className="flex items-baseline justify-between md:block lg:flex">
                                                    <div className={`flex items-baseline font-semibold text-black`}>
                                                        {link.name}
                                                        {link.label === 'paid' && <span
                                                            className="ml-2 my-auto bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full">Premium</span>}
                                                        {link.label === 'free' &&
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                                                 fill="currentColor" className="h-5 w-5 ml-2 my-auto">
                                                                <path fillRule="evenodd"
                                                                      d="M16.72 7.72a.75.75 0 0 1 1.06 0l3.75 3.75a.75.75 0 0 1 0 1.06l-3.75 3.75a.75.75 0 1 1-1.06-1.06l2.47-2.47H3a.75.75 0 0 1 0-1.5h16.19l-2.47-2.47a.75.75 0 0 1 0-1.06Z"
                                                                      clipRule="evenodd"/>
                                                            </svg>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="text-sm mt-1 text-gray-500">
                                                    {link.description}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                
                
                <div className="mt-6">
                    <div className="grid grid-cols-1 gap-4">
                        <div className="">
                            <div className="bg-white shadow p-4 rounded-lg">
                                <div className={"text-lg font-bold text-black ml-2"}>Your Blog Progress</div>
                                <div className="mt-6 mx-2" aria-hidden="true">
                                    <div className="overflow-hidden rounded-full bg-gray-200">
                                        <div className="h-2 rounded-full bg-indigo-600" style={{width: '37.5%'}}/>
                                    </div>
                                    <div className="mt-3 hidden grid-cols-4 text-sm font-medium text-gray-600 sm:grid">
                                        <div className="text-indigo-600">Article Created</div>
                                        <div className="text-center text-indigo-600">Template Selected</div>
                                        <div className="text-center">Fix SEO</div>
                                        <div className="text-right">Custom Domain</div>
                                    </div>
                                </div>
                            </div>
                            
                            {/*<div className="bg-white shadow p-4 rounded-lg mt-4">
                                <div className={"text-lg font-bold text-black ml-2"}>Your Blog Progress</div>
                                <div className="mt-6 mx-2" aria-hidden="true">
                                    <div className="overflow-hidden rounded-full bg-gray-200">
                                        <div className="h-2 rounded-full bg-indigo-600" style={{ width: '37.5%' }} />
                                    </div>
                                    <div className="mt-3 hidden grid-cols-4 text-sm font-medium text-gray-600 sm:grid">
                                        <div className="text-indigo-600">Article Created</div>
                                        <div className="text-center text-indigo-600">Template Selected</div>
                                        <div className="text-center">Fix SEO</div>
                                        <div className="text-right">Custom Domain</div>
                                    </div>
                                </div>
                            </div>*/}
                        </div>
                    
                    
                    </div>
                </div>
                
                <div className="mt-6">
                    <div className="bg-white card-border-radius p-4 border border-gray-200 rounded-lg h-auto">
                        <div className="grid grid-cols-12">
                            <div className="col-span-5 mt-2 ml-1">
                                <div className={"text-lg font-bold text-black ml-2"}>Blog Post Performance</div>
                                <div className="flex text-sm text-gray-600 ml-2">
                                    <div className="mt-0.5">Loren ipsum dolor sit amet consectetur elit.</div>
                                </div>
                                
                                <div className="grid grid-cols-2 gap-y-8 gap-x-1 mt-8 ml-6">
                                    {blogPostPerformance.map(stat => (
                                        <div key={stat.id}>
                                            <dd className="flex items-baseline justify-between md:block lg:flex">
                                                <div className={`flex items-baseline text-lg font-bold text-black`}>
                                                    {stat.stat}
                                                </div>
                                            </dd>
                                            <dt className="text-sm font-semibold text-gray-500">
                                                {stat.name}
                                            </dt>
                                        </div>
                                    ))}
                                </div>
                            
                            </div>
                            <div className="col-span-7">
                                <AppChart chart={blogPostChart} series={blogPostChart?.series} type={'area'}
                                          height={250}/>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="mt-6">
                    <div className="bg-white card-border-radius p-4 border border-gray-200 rounded-lg">
                        <div className="grid grid-cols-12 pb-4">
                            <div className="col-span-4 mt-2 ml-1">
                                <div className={"text-lg font-bold text-black ml-2"}>SEO Metrics</div>
                                <div className="flex text-sm text-gray-600 ml-2">
                                    <div className="mt-0.5">Loren ipsum dolor sit amet consectetur elit.</div>
                                </div>
                                
                                <div className="grid grid-cols-1 gap-8 mt-8 ml-6">
                                    {seoMetricStats.map(stat => (
                                        <div key={stat.id}>
                                            <div className="flex items-baseline justify-between md:block lg:flex">
                                                <div className={`flex items-baseline text-lg font-bold text-black`}>
                                                    {stat.stat}
                                                </div>
                                            </div>
                                            <div className="text-sm font-bold text-gray-800 uppercase mt-1">
                                                {stat.name}
                                            </div>
                                            <div className="text-sm mt-1 text-gray-500">
                                                {stat.description}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            
                            <div className="col-span-8">
                                <div className="relative overflow-x-auto">
                                    <div className="grid grid-cols-2 gap-4">
                                        <table
                                            className="w-full text-sm text-left rtl:text-right text-gray-500 mt-3">
                                            <thead
                                                className="text-xs text-gray-700 uppercase bg-gray-50">
                                            <tr>
                                                <th scope="col" className="px-3 py-1.5 text-sm">
                                                    Keywords
                                                </th>
                                                <th scope="col" className="px-3 py-1.5 text-sm">
                                                    Score
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {seoKeywordScore.map(score => (
                                                <tr className="bg-white border-b">
                                                    <th scope="row"
                                                        className="px-3 py-2 text-sm font-normal text-gray-900 whitespace-nowrap">
                                                        {score.keyword}
                                                    </th>
                                                    <td className="px-3 py-2 text-sm font-normal text-gray-900">
                                                        {score.score}
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                        
                                        <table
                                            className="w-full text-sm text-left rtl:text-right text-gray-500 mt-3">
                                            <thead
                                                className="text-xs text-gray-700 uppercase bg-gray-50">
                                            <tr>
                                                <th scope="col" className="px-3 py-1.5 text-sm">
                                                    Keywords
                                                </th>
                                                <th scope="col" className="px-3 py-1.5 text-sm">
                                                    Score
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {seoKeywordScore.map(score => (
                                                <tr className="bg-white border-b">
                                                    <th scope="row"
                                                        className="px-3 py-2 text-sm font-normal text-gray-900 whitespace-nowrap">
                                                        {score.keyword}
                                                    </th>
                                                    <td className="px-3 py-2 text-sm font-normal text-gray-900">
                                                        {score.score}
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="grid grid-cols-12 gap-3 mt-6">
                    <div
                        className="col-span-5 bg-white card-border-radius p-4 border border-gray-200 rounded-lg h-auto">
                        <div className={"text-lg font-bold text-black ml-2"}>Traffic Source</div>
                        <div className="ml-2 text-sm text-gray-600 mt-0.5">Loren ipsum dolor sit amet consectetur
                            elit.
                        </div>
                        <div className="">
                            <AppChart chart={trafficSourceChart} series={trafficSourceChart?.series} type={'bar'}
                                      height={280}/>
                        </div>
                    </div>
                    
                    <div
                        className="col-span-7 bg-white card-border-radius p-4 border border-gray-200 rounded-lg h-auto">
                        <div className={"text-lg font-bold text-black ml-2"}>Conversion Metrics</div>
                        <div className="ml-2 text-sm text-gray-600 mt-0.5">Loren ipsum dolor sit amet consectetur
                            elit.
                        </div>
                        <div className="">
                            <AppChart chart={conversionMetricsChart} series={conversionMetricsChart?.series}
                                      type={'bar'} height={280}/>
                        </div>
                    </div>
                </div>
                
                
                <div className="grid grid-cols-2 gap-4">
                    <div className="mt-6">
                        <div className="bg-white shadow p-4 rounded-lg">
                            <div className={"text-lg font-bold text-black ml-2"}>Drafted Articles</div>
                            
                            <div className="flex text-sm text-gray-600 ml-2 mr-2 mt-3">
                                <div className="divide-y divide-gray-200 w-full">
                                    {draftArticles?.map((item) => (
                                        <div className="flex">
                                            <div key={item.id} className="py-4 font-semibold capitalize">
                                                {item.title}
                                                <div
                                                    className="text-xs mt-0.5 font-normal">
                                                    {/*<span className="">Scheduled: </span>
                                                        {item.scheduledTime}*/}
                                                </div>
                                            </div>
                                            <div className="ml-auto my-auto">
                                                <div className="flex">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                                         fill="currentColor"
                                                         className="w-5 h-5 mr-3 cursor-pointer">
                                                        <path
                                                            d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32l8.4-8.4Z"/>
                                                        <path
                                                            d="M5.25 5.25a3 3 0 0 0-3 3v10.5a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3V13.5a.75.75 0 0 0-1.5 0v5.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5V8.25a1.5 1.5 0 0 1 1.5-1.5h5.25a.75.75 0 0 0 0-1.5H5.25Z"/>
                                                    </svg>
                                                    
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                                         fill="currentColor" className="w-5 h-5 cursor-pointer">
                                                        <path fillRule="evenodd"
                                                              d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
                                                              clipRule="evenodd"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            
                            <div className="flex justify-center items-center pt-4 pb-2">
                                <button
                                    onClick={() => {
                                    }}
                                    className="flex bg-green-100 border border-green-400 tracking-wide text-green-800 font-bold py-2 px-8 rounded-lg items-center">
                                    Good time to publish these articles
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                         className="h-5 w-5 ml-2">
                                        <path fillRule="evenodd"
                                              d="M16.72 7.72a.75.75 0 0 1 1.06 0l3.75 3.75a.75.75 0 0 1 0 1.06l-3.75 3.75a.75.75 0 1 1-1.06-1.06l2.47-2.47H3a.75.75 0 0 1 0-1.5h16.19l-2.47-2.47a.75.75 0 0 1 0-1.06Z"
                                              clipRule="evenodd"/>
                                    </svg>
                                </button>
                            </div>
                        
                        </div>
                    </div>
                </div>
            
            
            </div>
        
        </div>
    )
}
