import React, {useEffect} from "react";
import {PRODUCT_CARD_WIDGET, SOCIAL_MEDIA_WIDGET} from "../../constants/app.constants";
import {redoChange, undoChange} from "../../modules/Editor/EditorToolbar";
import Quill from "quill";
import "./scss/custom-editor.scss";

const CustomToolbar = ({editor, selectedText, quillRefs, index}) => {
    
    const insertBlock = () => {
        if (selectedText.length > 0) {
            quillRefs.current[index].editor.format('youtubeEmbedBlot', "id");
            const cursorPosition = quillRefs.current[index].editor.getSelection().index;
            quillRefs.current[index].editor.deleteText(cursorPosition, selectedText.length);
            const content = `<strong>${selectedText}<strong>`;
            quillRefs.current[index].editor.insertEmbed(cursorPosition, 'my-blot', {
                text: selectedText,
                id: "id",
                content: `${content}`,
            });
        }
    }
    
    return (
        <div id={`toolbar-${editor.id}`} className={"w-[100%]"}>
            <span className="ql-formats">
              <button className="ql-bold"/>
              <button className="ql-italic"/>
              <button className="ql-underline"/>
              <button className="ql-strike"/>
            </span>
            
            <div className="ql-formats ql-formats-buttons">
                {/*<button className="ql-header" value="1">Heading</button>*/}
                {/*<button className="ql-header font-bold" value="2">H2</button>*/}
                <button className="ql-header font-bold -mt-[2px]" value="3">H3</button>
            </div>
            
            <span className="ql-formats">
              <button className="ql-list" value="ordered"/>
              <button className="ql-list" value="bullet"/>
              <button className="ql-indent" value="-1"/>
              <button className="ql-indent" value="+1"/>
            </span>
            
            <span className="ql-formats">
              <select className="ql-align"/>
              <select className="ql-color"/>
              <select className="ql-background"/>
            </span>
            
            <span className="ql-formats">
              <button className="ql-link"/>
              <button className="ql-image"/>
              <button className="ql-video"/>
            </span>
            
            <span className="ql-formats ml-auto">
              <button className="text-sm font-semibold">REWRITE</button>
            </span>
        </div>
    );
}

export default CustomToolbar;
