import React, {useContext, useEffect, useState} from 'react';
import "../../App.css";
import {useLocation, useNavigate} from "react-router";
import {LOGGED_OUT, ROUTE_CONSTANT} from "../../constants/app.constants";
import AppContext from "../../context/AppContext";
import {toastSuccess} from "../../context/ToasterContext";
import ConnectShopifyStore from "../shared/ConnectShopifyStore";


const menus = [
    {id: 0, name: 'Dashboard', subString: 'dashboard', route: 'dashboard'},
    {id: 1, name: 'Articles', subString: 'articles', route: 'articles'},
    {id: 2, name: 'Widgets', subString: 'widgets', route: 'widgets'},
    {id: 3, name: 'Analytics', subString: 'analytics', route: 'analytics'},
    {id: 4, name: 'Settings', subString: 'settings', route: 'settings'},
]

export default function Sidebar() {
    
    const navigate = useNavigate();
    const {pathname} = window.location;
    let {afterLogout} = useContext(AppContext);
    const [isStoreOptionOpen, setIsStoreOptionOpen] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState("");
    
    const location = useLocation();
    const {blog_id, blog_uuid, blog_title, blog_handle} = location.state;
    
    useEffect(() => {
        const parts = pathname.split('/');
        const lastPart = parts[parts.length - 1];
        setSelectedMenu(lastPart);
    }, [pathname]);
    
    
    function handleStoreChangeClick() {
        setIsStoreOptionOpen(!isStoreOptionOpen)
    }
    
    function openModalClick() {
        setIsModalVisible(true);
    }
    
    function closeModalClick() {
        setIsModalVisible(false);
    }
    
    return (
        <div
            className="sidebar fixed top-16 bottom-0 lg:left-0 w-[200px] overflow-y-auto bg-primary shadow"
        >
            {/*<div className="text-gray-100 text-xl bg-gray-900 cursor-pointer">
                <div className="px-4 py-2">
                    <div className="flex">
                        <div>
                            <div className="font-bold text-gray-100 text-[14px]">Store Name</div>
                            <div className="text-gray-100 text-[12px] -mt-2">store.shopify.com</div>
                        </div>
                        
                        <div className="m-auto -mr-0">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                 strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>*/}
            
            
            <div className="pl-4 pt-2 pb-2">
                {menus.map(menu => (
                    <>
                        {selectedMenu === menu.subString && <div
                            onClick={(e) => {
                                e.preventDefault();
                                navigate(`/blogs/${blog_handle}/${menu.route}`, {
                                    state: {blog_id, blog_uuid, blog_title, blog_handle}
                                });
                            }}
                            className="text-[14px] py-3 mt-3 flex font-semibold items-center rounded-l-lg pl-2 duration-300 cursor-pointer bg-white text-[#E35C39]">
                            <span className="ml-4">
                                {menu.name}
                            </span>
                        </div>}
                        
                        {selectedMenu !== menu.subString && <div
                            onClick={(e) => {
                                e.preventDefault();
                                navigate(`/blogs/${blog_handle}/${menu.route}`, {
                                    state: {blog_id, blog_uuid, blog_title, blog_handle}
                                });
                            }}
                            className="text-[14px] py-3 mt-3 flex font-semibold items-center rounded-l-lg pl-2 duration-300 cursor-pointer hover:bg-gray-100 hover:text-[#E35C39] text-white">
                            <span className="ml-4">
                                {menu.name}
                            </span>
                        </div>}
                    </>
                ))}
                
                {/*<div
                    onClick={(e) => {
                        e.preventDefault();
                        navigate(ROUTE_CONSTANT.ANALYTICS);
                    }}
                    className="text-[14px] py-2 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-white text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                         stroke="#000000" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z"/>
                    </svg>
                    <span className="ml-4 text-gray-900">Analytics</span>
                </div>
    
                <div
                    onClick={(e) => {
                        e.preventDefault();
                        navigate(ROUTE_CONSTANT.CONFIGURE);
                    }}
                    className="text-[14px] py-2 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-white text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                         stroke="#000000" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5"/>
                    </svg>
                    <span className="ml-4 text-gray-900">Configure</span>
                </div>
    
                <div
                    onClick={(e) => {
                        e.preventDefault();
                        navigate(ROUTE_CONSTANT.SETTINGS);
                    }}
                    className="text-[14px] py-2 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-white text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                         stroke="#000000" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"/>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                    </svg>
                    <span className="ml-4 text-gray-900">Settings</span>
                </div>
    
                <div
                    onClick={(e) => {
                        e.preventDefault();
                        navigate(ROUTE_CONSTANT.WIDGETS);
                    }}
                    className="text-[14px] py-2 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-white text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                         stroke="#000000" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M3 8.25V18a2.25 2.25 0 002.25 2.25h13.5A2.25 2.25 0 0021 18V8.25m-18 0V6a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 6v2.25m-18 0h18M5.25 6h.008v.008H5.25V6zM7.5 6h.008v.008H7.5V6zm2.25 0h.008v.008H9.75V6z"/>
                    </svg>
                    <span className="ml-4 text-gray-900">Widgets</span>
                </div>
    
                <div
                    onClick={(e) => {
                        e.preventDefault();
                        navigate(ROUTE_CONSTANT.NOTEPAD);
                    }}
                    className="text-[14px] py-2 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-white text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                         stroke="#000000" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M15.666 3.888A2.25 2.25 0 0013.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 01-.75.75H9a.75.75 0 01-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 01-2.25 2.25H6.75A2.25 2.25 0 014.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 011.927-.184"/>
                    </svg>
                    <span className="ml-4 text-gray-900">Notepad</span>
                </div>
                
                <div
                    onClick={() => {
                        toastSuccess(LOGGED_OUT);
                        afterLogout();
                        navigate(ROUTE_CONSTANT.LOGIN);
                    }}
                    className="text-[14px] py-2 mt-2 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-white text-white">
                    <span className="ml-4 text-gray-900">Logout</span>
                </div>*/}
            </div>
            
            {/*<Modal
                show={isModalVisible}
                onClose={closeModalClick}
            >
                <Modal.Header>
                    Connect Your Shopify Store
                </Modal.Header>
                <Modal.Body>
                    <div className="space-y-6">
                        <ConnectShopifyStore/>
                    </div>
                </Modal.Body>
            </Modal>*/}
        
        </div>
    )
}
