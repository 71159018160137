import React, {useContext, useEffect, useState} from 'react'
import {useNavigate} from "react-router";
import AppContext from "../context/AppContext";
import {ROUTE_CONSTANT, STORE_UUID, USER_ID} from "../constants/app.constants";
import OBTopNav from "../components/layouts/OBTopNav";
import {useLocation} from "react-router-dom";
import CreateArticlePager from "../components/pager/CreateArticlePager";

export default function CreateArticlePage() {
    
    const navigate = useNavigate();
    const {pathname} = window.location;
    const location = useLocation();
    let {initialDataFetch, token, user, account, store, afterLogout} = useContext(AppContext);
    const {blog_id, blog_uuid, blog_title, blog_handle} = location.state;
    
    useEffect(() => {
        if (initialDataFetch) {
            if (!!token && !!user) {
            } else {
                navigate(ROUTE_CONSTANT.LOGIN);
            }
        }
    }, [token, user]);
    
    return (
        <>
            <OBTopNav/>
            
            <div className="flex justify-center items-center mt-12">
                
                <div
                    className="max-w-5xl mx-auto my-auto">
                    
                    <div className={""}>
                        
                        <div className="flex cursor-pointer">
                            <div className={"mr-4 mt-1 -ml-8"}
                                 onClick={() => {
                                     navigate(`/blogs/${localStorage.getItem('blog_handle')}/articles`, {
                                         state: {
                                             blog_id,
                                             blog_uuid,
                                             blog_title,
                                             blog_handle
                                         }
                                     })
                                 }}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                     className="w-5 h-5">
                                    <path fillRule="evenodd"
                                          d="M11.03 3.97a.75.75 0 0 1 0 1.06l-6.22 6.22H21a.75.75 0 0 1 0 1.5H4.81l6.22 6.22a.75.75 0 1 1-1.06 1.06l-7.5-7.5a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 0 1 1.06 0Z"
                                          clipRule="evenodd"/>
                                </svg>
                            </div>
                            
                            <div>
                                <div className="text-xl font-bold">
                                    Create Article
                                </div>
                                
                                <div className="mt-3 text-sm text-gray-600 pr-8">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing edivt, sed do eiusmod
                                    tempor incididunt ut labore et dolore magna adivqua.
                                </div>
                            </div>
                        
                        </div>
                        
                        <hr className={"mt-6"}/>
                        
                        <div
                            className="max-w-8xl mx-auto my-auto">
                            <div className="grid grid-cols-12 gap-3">
                                
                                <div className="col-span-4">
                                    <div className="mt-10 font-bold text-gray-600">
                                        Loren Ipsum
                                    </div>
                                    <div className="mt-3 text-sm text-gray-600 pr-8">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing edivt, sed do eiusmod
                                        tempor
                                        incididunt ut labore et dolore magna adivqua.
                                    </div>
                                </div>
                                
                                
                                {/*<div className="col-span-7">
                                    {pageOne &&
                                        <div className="bg-white rounded-lg border border-gray-200 shadow-lg p-6 mt-8">
                                            
                                            <label
                                                className=" text-xs uppercase ml-2 font-bold text-gray-600 dark:text-white">
                                                Choose Article Type</label>
                                            
                                            <div
                                                className="mx-auto mt-4 text-center grid grid-cols-2 gap-y-4 gap-x-4 items-center">
                                                
                                                <div
                                                    className={`h-50 bg-white p-2 shadow-lg border ${selectedOption === 1 ? 'border-2 border-orange-500' : 'border-gray-200'} sm:rounded-lg cursor-pointer flex items-center justify-center`}>
                                                    <div className="text-center"
                                                         onClick={() => {
                                                             setSelectedOption(1);
                                                             setArticleType("own");
                                                         }}>
                                                        <div className="px-4 py-5 sm:px-6">
                                                            <div className="mt-3 font-bold text-gray-600">
                                                                Create My Own
                                                            </div>
                                                            <div className="mt-3 text-sm text-gray-600">
                                                                Lorem ipsum dolor sit amet, consectetur adipiscing
                                                                edivt, sed do eiusmod
                                                                tempor.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div
                                                    className={`h-50 bg-white p-2 shadow-lg border ${selectedOption === 2 ? 'border-2 border-orange-500' : 'border-gray-200'} sm:rounded-lg cursor-pointer flex items-center justify-center`}>
                                                    <div className="text-center"
                                                         onClick={() => {
                                                             setSelectedOption(2);
                                                             setArticleType("ai");
                                                         }}>
                                                        <div className="px-4 py-5 sm:px-6">
                                                            <div className="mt-3 font-bold text-gray-600">
                                                                Use Blogd AI
                                                            </div>
                                                            <div className="mt-3 text-sm text-gray-600">
                                                                Lorem ipsum dolor sit amet, consectetur adipiscing
                                                                edivt, sed do eiusmod
                                                                tempor.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                            </div>
                                        
                                        </div>
                                    }
                                    
                                    {articleType === 'own' && <>
                                        {pageTwo &&
                                            <div
                                                className="bg-white rounded-lg border border-gray-200 shadow-lg p-6 mt-8">
                                                
                                                <div className="mb-8">
                                                    <label htmlFor="articleName"
                                                           className=" text-xs uppercase font-bold text-gray-600 dark:text-white">
                                                        Enter your article name</label>
                                                    <input
                                                        className="bg-gray-50 mt-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                                                        id="articleName"
                                                        type="text"
                                                        placeholder="Healthy Nutrition"
                                                        value={articleName}
                                                        onChange={(e) => {
                                                            setIsArticleNameEmpty(false);
                                                            setArticleName(e.target.value);
                                                            const formattedText = _.kebabCase(e.target.value);
                                                            setArticleSlug(formattedText);
                                                        }}/>
                                                    {isArticleNameEmpty && <div
                                                        className="text-red-500 text-xs mt-1 ml-1">Article Name is
                                                        Required</div>}
                                                </div>
                                                
                                                <div className="mb-8">
                                                    <label htmlFor="articleSlug"
                                                           className=" text-xs uppercase font-bold text-gray-600 dark:text-white">
                                                        Enter your article slug</label>
                                                    <input
                                                        className="bg-gray-50 mt-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                                                        id="articleSlug"
                                                        type="text"
                                                        placeholder="healthy-nutrition"
                                                        value={articleSlug}
                                                        onChange={(e) => {
                                                            setIsArticleSlugEmpty(false);
                                                            setArticleSlug(e.target.value);
                                                        }}/>
                                                    {isArticleSlugEmpty && <div
                                                        className="text-red-500 text-xs mt-1 ml-1">Article Slug is
                                                        Required</div>}
                                                </div>
                                                
                                                
                                                <div className="mb-8">
                                                    <div className="flex">
                                                        <label htmlFor="articleSlug"
                                                               className="text-xs uppercase font-bold text-gray-600 dark:text-white">
                                                            Select Author</label>
                                                        <div
                                                            className="font-medium flex cursor-pointer text-blue-500 text-[14px] ml-auto"
                                                            onClick={() => {
                                                                setShowAddAuthorField(true);
                                                            }}>
                                                            <span className={""}>Add Author</span>
                                                        </div>
                                                    </div>
                                                    <select
                                                        id="author"
                                                        required={true}
                                                        onChange={handleAuthorChange}
                                                        value={articleAuthor}
                                                        className="bg-gray-50 mt-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    >
                                                        <option value="">Choose an author</option>
                                                        {authors.map((author, index) => (
                                                            <option key={index} value={author}>{author}</option>
                                                        ))}
                                                    </select>
                                                    
                                                    {showAddAuthorField &&
                                                        <div className="flex mt-2">
                                                            <input type="name" id="add-author"
                                                                   className="flex-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                                   placeholder="Enter Author"
                                                                   onChange={(e) => setAddNewAuthor(e.target.value)}
                                                                   required={true}/>
                                                            <button type="button"
                                                                    onClick={handleAddNewAuthor}
                                                                    className="text-white hover:bg-red-500 ml-2 bg-primary font-medium rounded-lg text-sm px-4 py-2.5 mb-2">
                                                                Add
                                                            </button>
                                                            <button type="button"
                                                                    onClick={() => setShowAddAuthorField(false)}
                                                                    className="text-gray-500 ml-2 bg-gray-100 border-gray-300 border font-medium rounded-lg text-sm px-3 mb-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                     viewBox="0 0 24 24"
                                                                     strokeWidth="1.5" stroke="currentColor"
                                                                     className="w-5 h-5">
                                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                                          d="M6 18L18 6M6 6l12 12"/>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    }
                                                </div>
                                                
                                                
                                                <div className="mb-8">
                                                    <div className="flex">
                                                        <label htmlFor="articleSlug"
                                                               className=" text-xs uppercase font-bold text-gray-600 dark:text-white">
                                                            Select Category</label>
                                                        <div
                                                            className="font-medium flex cursor-pointer text-blue-500 text-[14px] ml-auto"
                                                            onClick={() => {
                                                                setShowAddCategoryField(true);
                                                            }}>
                                                            <span className={"mb-2"}>Add Category</span>
                                                        </div>
                                                    </div>
                                                    <select
                                                        id="category"
                                                        required={true}
                                                        onChange={handleCategoryChange}
                                                        value={articleCategory}
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    >
                                                        <option value="">Choose a category</option>
                                                        {categories.map((category, index) => (
                                                            <option key={index} value={category}>{category}</option>
                                                        ))}
                                                    </select>
                                                    
                                                    {showAddCategoryField &&
                                                        <div className="flex mt-2">
                                                            <input type="name" id="add-category"
                                                                   className="flex-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                                   placeholder="Enter Category"
                                                                   onChange={(e) => setAddNewCategory(e.target.value)}
                                                                   required={true}/>
                                                            <button type="button"
                                                                    onClick={handleAddNewCategory}
                                                                    className="text-white hover:bg-red-500 ml-2 bg-primary font-medium rounded-lg text-sm px-4 py-2.5 mb-2">
                                                                Add
                                                            </button>
                                                            <button type="button"
                                                                    onClick={() => setShowAddCategoryField(false)}
                                                                    className="text-gray-500 ml-2 bg-gray-100 border-gray-300 border font-medium rounded-lg text-sm px-3 mb-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                     viewBox="0 0 24 24"
                                                                     strokeWidth="1.5" stroke="currentColor"
                                                                     className="w-5 h-5">
                                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                                          d="M6 18L18 6M6 6l12 12"/>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    }
                                                </div>
                                            
                                            
                                            </div>
                                        }
                                        
                                        {lastPage &&
                                            <div
                                                className="bg-white rounded-lg border border-gray-200 shadow-lg p-6 mt-8">
                                                
                                                <div className="mb-8">
                                                    <label htmlFor="blogName"
                                                           className="underline text-xs uppercase text-gray-600 dark:text-white">
                                                        Question 1</label>
                                                    <div
                                                        className="text-sm font-semibold mt-2 text-gray-700 dark:text-white">
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing edivt, sed do
                                                        eiusmod
                                                        tempor ?
                                                    </div>
                                                    <input
                                                        className="bg-gray-50 mt-3 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                                                        id="blogName"
                                                        type="text"
                                                        placeholder="Lorem ipsum dolor sit amet....."
                                                        value={""}
                                                        required={true}/>
                                                </div>
                                                
                                                <div className="mb-8">
                                                    <label htmlFor="blogSlug"
                                                           className="underline text-xs uppercase text-gray-600 dark:text-white">
                                                        Question 2</label>
                                                    <div
                                                        className="text-sm font-semibold mt-2 text-gray-700 dark:text-white">
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing edivt?
                                                    </div>
                                                    <input
                                                        className="bg-gray-50 mt-3 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                                                        id="blogSlug"
                                                        type="text"
                                                        placeholder="Lorem ipsum dolor sit amet...."
                                                        value={""}
                                                        required={true}/>
                                                </div>
                                                
                                                <div className="mb-8">
                                                    <label htmlFor="tone"
                                                           className=" text-xs uppercase font-bold text-gray-600 dark:text-white">
                                                        Enter Tone</label>
                                                    <input
                                                        className="bg-gray-50 mt-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                                                        id="tone"
                                                        type="text"
                                                        placeholder="Conversational"
                                                        value={""}
                                                        onChange={(e) => {
                                                        }}
                                                        required={true}/>
                                                </div>
                                                
                                                <div className="mb-8">
                                                    <label htmlFor="products"
                                                           className=" text-xs uppercase font-bold text-gray-600 dark:text-white">
                                                        Select Products</label>
                                                    <select
                                                        id="products"
                                                        required={true}
                                                        onChange={handleProductsChange}
                                                        // value={}
                                                        className="bg-gray-50 mt-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    >
                                                        <option value="">Choose products</option>
                                                        {products.map((product, index) => (
                                                            <option key={product.id}
                                                                    value={product.title}>{product.title}</option>
                                                        ))}
                                                    </select>
                                                    
                                                    <div className="flex mt-2">
                                                        {selectedProducts.map(product => (
                                                            <span
                                                                className="bg-blue-100 m-1 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-lg dark:bg-blue-900 dark:text-blue-300">{product}</span>
                                                        ))}
                                                    </div>
                                                
                                                </div>
                                            </div>
                                        }
                                    </>}
                                    
                                    <div className="mt-6 float-right">
                                        {pageTwo && <button
                                            onClick={() => {
                                                setPageOne(true);
                                                setPageTwo(false);
                                                setLastPage(false);
                                            }}
                                            className="border border-gray-400 hover:bg-gray-100 mr-3 text-gray-600 font-semibold text-sm py-2 px-8 w-36 rounded-lg">
                                            BACK
                                        </button>}
                                        
                                        {lastPage && <button
                                            onClick={() => {
                                                setPageOne(false);
                                                setPageTwo(true);
                                                setLastPage(false);
                                            }}
                                            className="border border-gray-400 hover:bg-gray-100 mr-3 text-gray-600 font-semibold text-sm py-2 px-8 w-36 rounded-lg">
                                            BACK
                                        </button>}
                                        
                                        {pageTwo && <button
                                            type="submit"
                                            onClick={() => {
                                                if (!articleName) {
                                                    setIsArticleNameEmpty(true);
                                                } else if (!articleSlug) {
                                                    setIsArticleSlugEmpty(true);
                                                } else {
                                                    setIsArticleNameEmpty(false);
                                                    setIsArticleSlugEmpty(false);
                                                    setPageOne(false);
                                                    setPageTwo(false);
                                                    setLastPage(true);
                                                }
                                            }}
                                            className="bg-primary hover:bg-green-600 text-white font-semibold text-sm py-2 px-8 w-36 rounded-lg">
                                            NEXT
                                        </button>}
                                        
                                        {lastPage && <button
                                            onClick={() => {
                                                createNewArticle();
                                            }}
                                            className="bg-primary hover:bg-orange-600 text-white font-semibold text-sm py-2 px-8 w-40 rounded-lg">
                                            {isCreateArticleClicked ? "Creating..." : "Create Article"}
                                        </button>}
                                        
                                        {pageOne && <button
                                            onClick={() => {
                                                setPageOne(false);
                                                setPageTwo(true);
                                                setLastPage(false);
                                            }}
                                            className="bg-primary hover:bg-green-600 text-white font-semibold text-sm py-2 px-8 w-36 rounded-lg">
                                            NEXT
                                        </button>}
                                    </div>
                                </div>*/}
                                
                                
                                <div className="col-span-8 mt-8 mb-8">
                                        <CreateArticlePager/>
                                </div>
                            
                            
                            </div>
                        </div>
                    </div>
                
                </div>
            
            </div>
        </>
    )
    
}
