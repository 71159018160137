import React, {useContext, useEffect, useState} from "react";
import LayoutImage from "../../assets/img/layout_image.png";
import TopNav1Image from "../../assets/img/top_nav_1.png";
import TopNav2Image from "../../assets/img/top_nav_2.png";
import Footer1Image from "../../assets/img/footer_1.png";
import Footer2Image from "../../assets/img/footer_2.png";
import Footer3Image from "../../assets/img/footer_3.png";
import API from "../../api/api";
import {toastError, toastSuccess, toastWarning} from "../../context/ToasterContext";
import {AUTH, USER} from "../../constants/app.constants";
import AppContext from "../../context/AppContext";
import ShowSearchTemplateImg from "../../assets/img/template/show_search_template_img.png";
import DontShowSearchTemplateImg from "../../assets/img/template/dont_show_search_template_img.png";
import PublishArticleListForm from "./PublishArticleListForm";
import PublishArticleDescForm from "./PublishArticleDescForm";
import PublishArticleSEOChecklist from "./PublishArticleSEOChecklist";

const Colors = [
    {id: 1, color: "bg-green-500", color_name: "green", selected: true},
    {id: 2, color: "bg-blue-500", color_name: "blue", selected: false},
    {id: 3, color: "bg-yellow-500", color_name: "yellow", selected: false},
    {id: 4, color: "bg-orange-500", color_name: "orange", selected: false},
    {id: 5, color: "bg-violet-500", color_name: "violet", selected: false},
    {id: 6, color: "bg-red-500", color_name: "red", selected: false},
    {id: 7, color: "bg-pink-500", color_name: "pink", selected: false},
    {id: 8, color: "bg-gray-1000", color_name: "teal", selected: false},
    {id: 9, color: "bg-indigo-500", color_name: "indigo", selected: false},
];


const MENU = [
    {id: 1, name: "Site Information"},
    {id: 2, name: "Blog Information"},
    {id: 3, name: "Layout"},
    {id: 4, name: "SEO Settings"},
]

const DeployForm = ({handleClosePublishModal, article_id, article_html = "", article = {}}) => {
    
    const [accentColor, setAccentColor] = useState("");
    const [showSearch, setShowSearch] = useState(false);
    const [topMenuPosition, setTopMenuPosition] = useState("");
    const [showImage, setShowImage] = useState(false);
    const [showDescription, setShowDescription] = useState(false);
    const [showAuthorAndDate, setShowAuthorAndDate] = useState(false);
    const [authorAndDatePosition, setAuthorAndDatePosition] = useState("");
    const [firstPage, setFirstPage] = useState(true);
    const [secondPage, setSecondPage] = useState(false);
    const [thirdPage, setThirdPage] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState(MENU[0]);
    let {token, account, store} = useContext(AppContext);
    const [stepOnePayloadData, setStepOnePayloadData] = useState({});
    const [stepTwoPayloadData, setStepTwoPayloadData] = useState({});
    const [stepThreePayloadData, setStepThreePayloadData] = useState({});
    
    useEffect(() => {
    
    }, []);
    
    
    const [step, setStep] = useState(1);
    const totalSteps = 3;
    
    const nextStep = () => {
        setStep(prevStep => prevStep + 1);
    };
    
    const prevStep = () => {
        setStep(prevStep => prevStep - 1);
    };
    
    const reset = () => {
        setStep(1);
    };
    
    const renderStep = () => {
        switch (step) {
            case 1:
                return <PublishArticleListFormOne nextStep={nextStep} step1Payload={step1Payload}/>;
            case 2:
                return <PublishArticleDescFormTwo nextStep={nextStep} prevStep={prevStep} step2Payload={step2Payload}/>;
            case 3:
                return <SEOChecklist prevStep={prevStep} reset={reset}/>;
            default:
                return null;
        }
    };
    
    const step1Payload = (payload) => setStepOnePayloadData(payload);
    const step2Payload = (payload) => setStepTwoPayloadData(payload);
    
    
    return (
        <div>
            
            <div>
                <div
                    className="p-4 -ml-1 mt-3 flex items-center w-full text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base">
                    
                    <div className={`flex md:w-full items-center ${
                        step >= 1 ? 'text-gray-900 dark:text-blue-500' : 'text-gray-500 dark:text-gray-400'
                    } sm:after:content-[''] after:w-full after:h-1 after:border-b ${step >= 2 ? 'after:border-orange-300' : 'after:border-gray-300' } after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10`}>
                          <span
                              className="flex font-bold text-xs items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500">
                              Article List Template
                          </span>
                    </div>
                    
                    <div className={`flex md:w-full items-center ${
                        step >= 2 ? 'text-gray-900 dark:text-blue-500' : 'text-gray-500 dark:text-gray-400'
                    } after:content-[''] after:w-full after:h-1 after:border-b ${step >= 3 ? 'after:border-orange-300' : 'after:border-gray-300' }  after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10`}>
                          <span
                              className="flex font-bold text-xs items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500">
                              Article Description Template
                          </span>
                    </div>
                    
                    <div className={`text-xs flex items-center ${
                        step >= 3 ? 'text-gray-900 dark:text-blue-500' : 'text-gray-500 dark:text-gray-400'
                    }`}>
                         <span
                             className="flex font-bold text-xs items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500">
                             SEO Checklist
                          </span>
                    </div>
                </div>
                <form className="flex flex-col gap-4 pt-5">
                    {renderStep()}
                </form>
            </div>
        
        </div>
    );
}


const PublishArticleListFormOne = ({nextStep, step1Payload}) => {
    return (
        <PublishArticleListForm nextStep={nextStep} step1Payload={step1Payload}/>
    );
};

const PublishArticleDescFormTwo = ({nextStep, prevStep, step2Payload}) => {
    return (
        <PublishArticleDescForm nextStep={nextStep} prevStep={prevStep} step2Payload={step2Payload}/>
    );
};

const SEOChecklist = ({prevStep, reset}) => {
    return (
        <PublishArticleSEOChecklist prevStep={prevStep} reset={reset}/>
    );
};


export default DeployForm;
