import {useContext, useEffect, useState} from "react";
import API from "../../../api/api";
import AppContext from "../../../context/AppContext";

const ContentSummary = ({content}) => {
    
    let {initialDataFetch, token, user, contentSummary, setContentSummary} = useContext(AppContext);
    const [wordCount, setWordCount] = useState(0);
    const [sentenceCount, setSentenceCount] = useState(0);
    const [characterCount, setCharacterCount] = useState(0);
    const [readability, setReadability] = useState("");
    
    
    // const handleContentChange = (content) => {
    //     setContent(content);
    // }
    
    useEffect(() => {
        if (initialDataFetch) {
            if (!!token && !!user) {
                // getReadability(account);
            } else {
                // navigate(ROUTE_CONSTANT.LOGIN);
            }
        }
    }, [token, user]);
    
    
    useEffect(() => {
    
    }, [content]);
    
    
    const getReadability = () => {
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        const params = {
            text: content.toString()
        }
        API.post(`helper_widgets/seo/statistics`, {params: params}, headerParams)
            .then(res => {
            })
            .catch(error => {
                console.log('Error: ', error.message);
            })
    }
    
    const getCountOfWords = (content) => {
        const cleanContent = !!content ? content.replace(/<[^>]+>/g, '') : "";
        const words = cleanContent.match(/\b\w+\b/g);
        const wordCount = {};
        let totalWordsCount = 0;
        if (words) {
            words.forEach((word) => {
                if (wordCount[word]) {
                    wordCount[word]++;
                } else {
                    wordCount[word] = 1;
                }
                totalWordsCount++;
            });
        }
        return {wordCount, totalWordsCount};
    };
    
    const getCountOfSentences = (content) => {
        const sentences = !!content ? content.split(/[.!?]+/) : "";
        if (sentences.length === 0) {
            return 0;
        } else {
            return sentences.length - 1;
        }
    };
    
    const getCountOfCharacters = (content) => {
        const cleanContent = !!content ? content.replace(/<[^>]+>/g, '') : "";
        return cleanContent.length;
    };
    
    
    useEffect(() => {
        const {wordCount, totalWordsCount} = getCountOfWords(content);
        const sentenceCount = getCountOfSentences(content);
        const characterCounts = getCountOfCharacters(content);
        setWordCount(totalWordsCount);
        setSentenceCount(sentenceCount);
        setCharacterCount(characterCounts);
        setContentSummary({
            wordCount: totalWordsCount,
            sentenceCount: sentenceCount,
            characterCount: characterCounts
        });
    }, [content]);
    
    return (
        <div className="bg-white border border-gray-300 rounded-lg">
            
            <div className="grid grid-cols-3 border_peach_b px-3 pt-[15px] pb-[15px]">
                <div className="">
                    <div className="px-2">
                        <h1 className="text-xs font-bold text_light_brown">Word Count </h1>
                        <p className="text-xs font-bold text-black mt-1">{wordCount}</p>
                    </div>
                </div>
                <div className="pl-2">
                    <div className="px-2">
                        <h1 className="text-xs font-bold text_light_brown">Sentence </h1>
                        <p className="text-xs font-bold text-black mt-1">{sentenceCount}</p>
                    </div>
                </div>
                <div className="">
                    <div className="px-2">
                        <h1 className="text-xs font-bold text_light_brown">Characters </h1>
                        <p className="text-xs font-bold text-black mt-1">{characterCount}</p>
                    </div>
                </div>
            </div>
            
            
            <div className="flex justify-between p-4">
                <h1 className="text-xs font-bold text_light_brown">Readability </h1>
                <div className="flex">
                    <div onClick={getReadability}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                             strokeWidth="1.5" stroke="currentColor"
                             className="w-4 h-4 text-green-600 mr-2">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75"/>
                        </svg>
                    </div>
                    <div className="text-xs font-bold text-black">2.5</div>
                    <div className="cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                             strokeWidth="1.5" stroke="currentColor"
                             className="w-4 h-4 text-gray-800 ml-2">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"/>
                        </svg>
                    </div>
                </div>
            </div>
        
        </div>
    )
}

export default ContentSummary
