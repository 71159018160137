import React, {useContext, useEffect, useState} from 'react';
import {ROUTE_CONSTANT, STORE_UUID} from "../../constants/app.constants";
import API from "../../api/api";
import {toastError} from "../../context/ToasterContext";
import {useNavigate} from "react-router";
import {useLocation} from "react-router-dom";
import AppContext from "../../context/AppContext";
import _ from "lodash";

const LANGUAGE = ['English', 'Spanish'];
const MODEL = ['GPT 3', 'GPT 4'];
const LOCATION = ['Location 1', 'Location 2'];

const StepOneAISettings = ({aiSettingsFormData, setAISettingsFormData, isTopicEmpty, setIsTopicEmpty}) => {
    const navigate = useNavigate();
    let {initialDataFetch, token, user, account, store, afterLogout} = useContext(AppContext);
    
    const [allLanguages, setAllLanguages] = useState([]);
    const [allModels, setAllModels] = useState([]);
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        if (initialDataFetch) {
            if (!!token && !!user) {
                getAIPresetOptions();
            } else {
                navigate(ROUTE_CONSTANT.LOGIN);
            }
        }
    }, [token, user]);
    
    const handleLanguageChange = (e) => {
        setAISettingsFormData(prevFormData => ({
            ...prevFormData,
            language: e.target.value
        }));
    };
    
    const handleModelChange = (e) => {
        setAISettingsFormData(prevFormData => ({
            ...prevFormData,
            model: e.target.value
        }));
    };
    
    const handleLocationChange = (e) => {
        setAISettingsFormData(prevFormData => ({
            ...prevFormData,
            location: e.target.value
        }));
    };
    
    const getAIPresetOptions = () => {
        setLoading(true);
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        API.get(`article-generator/get-ai-presets`, headerParams)
            .then(res => {
                const {languages, models} = res.data.data;
                
                const modifiedLanguages = _.map(languages, (value, key) => {
                    return {handle: key, name: value};
                });
                setAllLanguages(modifiedLanguages);
                
                const modifiedModels = _.map(models, (value, key) => {
                    return {handle: key, name: value};
                });
                setAllModels(modifiedModels);
                
                setLoading(false);
            })
            .catch(error => {
                console.log('Error: ', error.message);
                setLoading(false);
            });
    };
    
    return (
        <form>
            <div className="">
                <div className="mb-8 uppercase text-sm font-bold text-gray-900 dark:text-white">
                    Setup your <span className="text-orange-600">Blogd AI</span>
                </div>
                
                <label htmlFor="topic" className="text-xs uppercase font-bold text-gray-600 dark:text-white">
                    Enter your topic / keyword
                </label>
                <input
                    className="bg-gray-50 mt-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                    id="topic"
                    type="text"
                    placeholder="Benefits of print on demand"
                    value={aiSettingsFormData.topic}
                    required
                    onChange={(e) => {
                        setIsTopicEmpty(false);
                        const name = e.target.value;
                        setAISettingsFormData(prevFormData => ({
                            ...prevFormData,
                            topic: name,
                        }));
                    }}
                />
                {isTopicEmpty && <div className="text-red-500 text-xs mt-1.5 ml-1">Topic is Required</div>}
            </div>
            
            <div className="mb-8 flex justify-between mt-2 mx-2">
                <select
                    id="language"
                    required
                    onChange={handleLanguageChange}
                    value={aiSettingsFormData.language}
                    className="block py-2.5 px-0 text-sm bg-transparent border-0 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer">
                    <option value="">{loading ? "Fetching Languages.." : "Select Language"}</option>
                    {allLanguages.map((language, index) => (
                        <option key={index} value={language.handle} className={"underline font-bold"}>{language.name}</option>
                    ))}
                </select>
                
                <select
                    id="location"
                    required
                    onChange={handleLocationChange}
                    value={aiSettingsFormData.location}
                    className="block py-2.5 px-0 text-sm bg-transparent border-0 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer">
                    <option value="">{loading ? "Fetching Locations.." : "Select Location"}</option>
                    {LOCATION.map((location, index) => (
                        <option key={index} value={location} className={"underline font-bold"}>{location}</option>
                    ))}
                </select>
                
                <select
                    id="model"
                    required
                    onChange={handleModelChange}
                    value={aiSettingsFormData.model}
                    className="block py-2.5 px-0 text-sm bg-transparent border-0 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer">
                    <option value="">{loading ? "Fetching Models.." : "Select Model"}</option>
                    {allModels.map((model, index) => (
                        <option key={index} value={model.handle} className={"underline"}>{model.name}</option>
                    ))}
                </select>
            </div>
        </form>
    );
};


export default StepOneAISettings;
