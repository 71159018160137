import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {ROUTE_CONSTANT, MODE_STRING} from "../../constants/app.constants";
import AppContext from "../../context/AppContext";
import BlogdLogoDark from "../../assets/img/blogd_dark_logo.png";
import {useLocation} from "react-router-dom";


const TopNav = () => {
    
    const navigate = useNavigate();
    const {afterLogout, storeModeData, initialDataFetch, token, user, mode} = useContext(AppContext);
    const [isOpenStoreDropDown, setOpenStoreDropDown] = useState(false);
    const [isOpenAccountDropDown, setOpenAccountDropDown] = useState(false);
    const location = useLocation();
    const url = location.pathname;
    const {2: blog_name, 4: article_name} = url.split('/');
    const formattedBlogName = blog_name.replace(/-/g, ' ').replace(/(?:^|\s)\S/g, (char) => char.toUpperCase());
    const [modeData, setModeData] = useState(MODE_STRING[0]);
    const [showBeginnerProLabel, setBeginnerProLabel] = useState(false);
    const {blog_id, blog_uuid, blog_title, blog_handle} = location.state;
    
    useEffect(() => {
        if (initialDataFetch) {
            if (!!token && !!user) {
                setModeData(mode);
            } else {
                navigate(ROUTE_CONSTANT.LOGIN);
            }
        }
    }, [token, user]);
    
    useEffect(() => {
        if (!!article_name) {
            setBeginnerProLabel(true);
        } else {
            setBeginnerProLabel(false);
        }
    }, [url]);
    
    
    const handleStoreDropDown = () => {
        setOpenStoreDropDown(!isOpenStoreDropDown);
        setOpenAccountDropDown(false);
    };
    
    const handleAccountDropDown = () => {
        setOpenAccountDropDown(!isOpenAccountDropDown);
        setOpenStoreDropDown(false);
    };
    
    const handleLogout = async () => {
        try {
            afterLogout();
            navigate(ROUTE_CONSTANT.LOGIN);
        } catch (error) {
            console.log(error.message);
        }
    };
    
    const changeMode = (value) => {
        storeModeData(value);
        setModeData(value);
    }
    
    return (
        <div className="min-h-full sticky top-0 z-40 bg-gradient-to-r from-white to-[#FEEFE2]">
            <nav className="bg-white border-b">
                <div className="mx-auto w-full px-4 sm:px-6 lg:px-8">
                    <div className="h-16 items-center flex justify-around">
                        <div className="flex items-center text-left">
                            <div className="flex-shrink-0">
                                <div className="flex items-baseline space-x-4 m-auto text-center">
                                    <div className="mr-5 mt-2 cursor-pointer"
                                         onClick={() => {
                                             navigate(`/blogs`, {
                                                 state: {
                                                     blog_id,
                                                     blog_uuid,
                                                     blog_title,
                                                     blog_handle
                                                 }
                                             })
                                         }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth="2" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"/>
                                        </svg>
                                    </div>
                                </div>
                                {/*<img src={BlogdLogoDark} className="mx-auto" width="60" alt="Empty"/>*/}
                            </div>
                        
                        </div>
                        <div className="hidden md:block m-auto text-center">
                            {/*<div className="flex items-baseline space-x-4 m-auto text-center">
                                <div
                                    className="cursor-pointer inline-flex w-full gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900"
                                    id="menu-button" aria-expanded="true" aria-haspopup="true">
                                    <div className="mr-4">
                                        {formattedBlogName}
                                    </div>
                                    <svg className="-mr-1 mt-[1px] -ml-3 h-5 w-5 text-gray-400" viewBox="0 0 20 20"
                                         fill="#000000"
                                         aria-hidden="true">
                                        <path fillRule="evenodd"
                                              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                              clipRule="evenodd"/>
                                    </svg>
                                </div>
                            </div>*/}
                            <img src={BlogdLogoDark} className="mx-auto" width="60" alt="Empty"/>
                        </div>
                        <div className="hidden md:block text-right">
                            
                            <>
                                <div className="ml-4 flex md:ml-6">
                                    
                                    {showBeginnerProLabel &&
                                        <div className="border border-gray-300 rounded-full p-1 inline-block mr-4">
                                            <div className="flex">
                                                <button
                                                    className={`${modeData === MODE_STRING[0] ? 'bg-primary text-white' : ''} font-semibold py-1.5 px-3 text-xs rounded-full mr-1`}
                                                    onClick={() => {
                                                        changeMode(MODE_STRING[0]);
                                                    }}>
                                                    Beginner
                                                </button>
                                                <button
                                                    className={`${modeData === MODE_STRING[1] ? 'bg-primary text-white' : ''} font-semibold py-1.5 px-3 text-xs rounded-full ml-1`}
                                                    onClick={() => {
                                                        changeMode(MODE_STRING[1]);
                                                    }}>
                                                    Pro
                                                </button>
                                            </div>
                                        </div>
                                    }
                                    
                                    <button type="button"
                                            className="rounded-full ml-2 p-2 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                                            id="account-menu-button" aria-expanded="true" aria-haspopup="true"
                                            onClick={handleAccountDropDown}>
                                        <div className="flex">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                 strokeWidth="1.5"
                                                 stroke="currentColor" className="w-5 h-5">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                      d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"/>
                                            </svg>
                                        </div>
                                    </button>
                                </div>
                                
                                {isOpenStoreDropDown && <div
                                    className="absolute left-14 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                    role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
                                    <div className="py-1" role="none">
                                        <button type="submit"
                                                className="text-gray-700 block w-full px-4 py-2 text-left text-sm
                                                hover:bg-gray-100"
                                                role="menuitem" tabIndex="-1" id="menu-item-3"
                                                onClick={() => {
                                                }}>
                                            Daily Dairy Store
                                        </button>
                                        <button type="submit"
                                                className="text-gray-700 block w-full px-4 py-2 text-left text-sm
                                                hover:bg-green-200 hover:text-gray-900"
                                                role="menuitem" tabIndex="-1" id="menu-item-3">
                                            
                                            Add Store
                                        </button>
                                    </div>
                                </div>}
                                
                                {isOpenAccountDropDown && <div
                                    className="absolute right-3 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                    role="menu" aria-orientation="vertical" aria-labelledby="account-menu-button"
                                    tabIndex="-1">
                                    <div className="py-1" role="none">
                                        {/*<div className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 no-underline float-left"
                                           role="menuitem"
                                           tabIndex="-1" id="menu-item-0">Account Settings</div>*/}
                                        <button type="submit"
                                                className="text-gray-700 block w-full px-4 py-2 text-left text-sm
                                                hover:bg-gray-100"
                                                role="menuitem" tabIndex="-1" id="menu-item-3"
                                                onClick={() => {
                                                    const account_settings = true;
                                                    navigate(`/blogs/${blog_handle}/settings`, {
                                                        state: {blog_id, blog_uuid, blog_title, blog_handle, account_settings}
                                                    });
                                                }}>
                                            Account Settings
                                        </button>
                                        <button type="submit"
                                                className="text-gray-700 block w-full px-4 py-2 text-left text-sm
                                                hover:bg-red-200 hover:text-red-700"
                                                role="menuitem" tabIndex="-1" id="menu-item-3"
                                                onClick={handleLogout}>
                                            Log Out
                                        </button>
                                    </div>
                                </div>}
                            </>
                        </div>
                    </div>
                </div>
            </nav>
        
        </div>
    )
}

export default TopNav
