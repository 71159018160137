import React from "react";
import Chart from "react-apexcharts";

function AppChart({chart, series, type, height}) {
    return (
        <>
            {chart &&
                <Chart
                    options={chart.options}
                    series={!!series ? series : chart.series}
                    type={type}
                    height={height}
                />}
        </>
    )
}

export default AppChart;
