import React, { useState } from 'react';

// Create the new component
const ArticleTypeSelector = ({ handleOptionClick }) => (
    <div>
        <label
            className=" text-xs uppercase ml-2 font-bold text-gray-600 dark:text-white">
            Choose Article Type
        </label>
        <div
            className="mx-auto mt-4 text-center grid grid-cols-2 gap-y-4 gap-x-4 items-center">
            <div
                className={`h-50 bg-white p-2 shadow-lg border sm:rounded-lg cursor-pointer flex items-center justify-center`}
                onClick={() => handleOptionClick("own")}
            >
                <div className="text-center">
                    <div className="px-4 py-5 sm:px-6">
                        <div className="font-bold text-gray-600">Create My Own</div>
                        <div className="mt-3 text-sm text-gray-600">
                            Lorem ipsum dolor sit amet, consectetur adipiscing edivt, sed do
                            eiusmod tempor.
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`h-50 bg-white p-2 shadow-lg border sm:rounded-lg cursor-pointer flex items-center justify-center`}
                onClick={() => handleOptionClick("ai")}
            >
                <div className="text-center">
                    <div className="px-4 py-5 sm:px-6">
                        <div className="font-bold text-gray-600">Use <span className="text-orange-600">Blogd AI</span></div>
                        <div className="mt-3 text-sm text-gray-600">
                            Lorem ipsum dolor sit amet, consectetur adipiscing edivt, sed do
                            eiusmod tempor.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default ArticleTypeSelector;
