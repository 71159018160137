import EditorPage from "../modules/EditorPage";

export const TOASTER_STYLES = {
    style: {
        borderRadius: '4px',
        background: '#333',
        color: '#fff',
    }
}

export const TEST_STORE = [{
    "id": 2,
    "uuid": "8tnqz4bkp",
    "name": null,
    "shopify_domain": "thetimelystore.myshopify.com",
    "shopify_access_token": "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",
    "created_at": "2022-04-23T14:25:48.000Z"
}]

export const AUTH = "a_blogd_auth";
export const USER = "a_blogd_user";
export const STORE = "a_blogd_store";
export const STORE_UUID = "a_blogd_store_uuid";
export const MODE = "a_blogd_mode";
export const ACCOUNT_UUID = "a_blogd_account_uuid";
export const USER_ID = !!localStorage.getItem(USER) ? JSON.parse(localStorage.getItem(USER)).id : null;

export const LOGGED_IN = 'Successfully Logged In.';
export const LOGGED_OUT = 'Logged Out.';


export const TEMPLATE_STRING = ["left", "center"];
export const VISIBILITY_STRING = ["embed", "popup"];
export const MODE_STRING = ["noob", "pro"];
export const STYLE_STRING = ["fixed", "dynamic", "random"];


export const PRODUCT_CARD_WIDGET = "product_card_widget";
export const SOCIAL_MEDIA_WIDGET = "social_media_widget";
export const POPUP_WIDGET = "popup_widget";


export const DISPLAY_POSITION = ["top-center", "bottom-center"];
export const DISPLAY_SIZE = ["small", "medium", "large"];

export const POPUP_EMAIL_OPTION = ["email-only", "email-name"];
export const POPUP_TRIGGER = ["on-page-load", "after-10-seconds", "scroll-to-bottom"];
export const POPUP_AFTER_SUBMIT_OPTION = ["thank-you-message", "discount-code"];


/* Global Constant for Route */
export const ROUTE_CONSTANT = {
    DEFAULT: '/',
    LOGIN: '/login',
    // DASHBOARD: '/dashboard',
    BLOGS: '/blogs',
    CREATE_BLOG: '/create-blog',
    CREATE_ARTICLE: '/create-article',
    DASHBOARD: '/blogs/:id/dashboard',
    ARTICLES: '/blogs/:id/articles',
    ANALYTICS: '/blogs/:id/analytics',
    CONFIGURE: '/blogs/:id/configure',
    SETTINGS: '/blogs/:id/settings',
    WIDGETS: '/blogs/:id/widgets',
    NOTEPAD: '/blogs/:id/notepad',
    CREATE_ACCOUNT: '/create-account',
    ONBOARDING_ACCOUNT: '/onboarding-account',
    CHAT_GPT: '/chat-gpt',
    EDITOR_PAGE: '/blogs/:id/articles/:id/editor',
    PRICING_PLAN_PAGE: '/pricing',
    PRICING_FAILURE_PAGE: '/payment-failed',
    PRICING_VERIFY_PAGE: '/payment-verify',
    GENERATING_ARTICLE_PAGE: '/generating-article',
    NOT_FOUND: '/*',
}
