import React from "react";
import Chart from "react-apexcharts";
import Line_1 from "../../assets/img/Line_1.png";
import Line_2 from "../../assets/img/Line_2.png";

function CommonBg() {
    return (
        <>
            <div className="absolute top-0 left-0">
                {/*<img src="../../assets/img/Line_1.png" width={100} height={100} className={"w-100 h-100"}/>*/}
                <img src={Line_1} className="mx-auto mb-4" width="80" alt="Empty"/>
            </div>
    
            {/*<div className="relative h-screen">
                <img src={Line_2} className="mx-auto absolute inset-0" alt="Empty"/>
            </div>*/}
        </>
    )
}

export default CommonBg;
