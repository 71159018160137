import React, {useContext, useEffect, useState} from 'react';
import {ROUTE_CONSTANT, STORE_UUID} from "../../constants/app.constants";
import API from "../../api/api";
import {toastError} from "../../context/ToasterContext";
import {useNavigate} from "react-router";
import {useLocation} from "react-router-dom";
import AppContext from "../../context/AppContext";
import _ from "lodash";

const OUTLINE = [
    {id: 1, wordCount: "4-5 Headings (500 - 1000 words)", selected: true},
    {id: 2, wordCount: "5-6 Headings (1000 - 2000 words)", selected: false},
    {id: 3, wordCount: "6-7 Headings (2000 - 3000 words)", selected: false},
    {id: 4, wordCount: "7-8 Headings (3000 - 4000 words)", selected: false},
    {id: 5, wordCount: "9-10 Headings (4000 - 5000 words)", selected: false},
];

const StepThreeWordCount = ({aiSettingsFormData, setAISettingsFormData}) => {
    const navigate = useNavigate();
    let {
        initialDataFetch, token,
        user, account, store, afterLogout
    } = useContext(AppContext);
    
    const [allWordCounts, setAllWordCounts] = useState([]);
    const [loading, setLoading] = useState(false);
    
    const handleRadioChange = (handle) => {
        const updatedWordCounts = allWordCounts.map(wordCount =>
            wordCount.handle === handle ? {...wordCount, selected: true} : {...wordCount, selected: false}
        );
        
        const selectedWordCount = updatedWordCounts.find(wordCount => wordCount.selected);
        setAISettingsFormData(prevFormData => ({
            ...prevFormData,
            wordcount: selectedWordCount ? selectedWordCount.handle : ''
        }));
    };
    
    useEffect(() => {
        if (initialDataFetch) {
            if (!!token && !!user) {
                getAIPresetOptions();
            } else {
                navigate(ROUTE_CONSTANT.LOGIN);
            }
        }
    }, [token, user]);
    
    
    const getAIPresetOptions = () => {
        setLoading(true);
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        API.get(`article-generator/get-ai-presets`, headerParams)
            .then(res => {
                const {wordcount} = res.data.data;
                
                const modifiedWordCounts = _.map(wordcount, (value, key) => {
                    return {handle: key, name: value};
                });
                setAllWordCounts(modifiedWordCounts);
                
                setLoading(false);
            })
            .catch(error => {
                console.log('Error: ', error.message);
                setLoading(false);
            });
    };
    
    
    return (
        <form>
            <div className="">
                <div className="mb-8 uppercase text-sm font-bold text-gray-900">
                    Word Count Suggestions from <span className="text-orange-600">Blogd AI</span>
                </div>
                
                {!loading && <>
                    {allWordCounts.map(wordCount => (
                        <div key={wordCount.handle} className="flex items-center ps-4 border-b border-gray-200 mb-2">
                            <input
                                id={`radio-${wordCount.handle}`}
                                type="radio"
                                value={wordCount.name.display_text}
                                name="bordered-radio"
                                checked={wordCount.selected}
                                onChange={() => handleRadioChange(wordCount.handle)}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                            />
                            <label
                                htmlFor={`radio-${wordCount.handle}`}
                                className="w-full py-4 ms-4 text-sm font-medium text-gray-900 flex"
                            >{wordCount.name.display_text}
                            </label>
                        </div>
                    ))}
                </>}
                
                {loading && <div className="w-full h-full animate-pulse">
                    <div className="flex space-x-4">
                        <div className="flex-1 space-y-4 py-1">
                            <div className="h-4 bg-gray-300 rounded-lg w-3/4"></div>
                            <div className="h-4 bg-gray-300 rounded-lg"></div>
                            <div className="h-4 bg-gray-300 rounded-lg w-5/6"></div>
                            <div className="h-4 bg-gray-300 rounded-lg w-3/4"></div>
                            <div className="h-4 bg-gray-300 rounded-lg"></div>
                            <div className="h-4 bg-gray-300 rounded-lg w-5/6"></div>
                        </div>
                    </div>
                </div>}
            
            
            </div>
        
        </form>
    );
};

export default StepThreeWordCount;
