import React from "react";

const AppContext = React.createContext({
    initialDataFetch: false,
    isAuthenticated: false,
    token: undefined,
    user: undefined,
    store: undefined,
    account: undefined,
    mode: undefined,
    afterLogin: undefined,
    afterLogout: undefined,
    storeUserData: undefined,
    storeTokenData: undefined,
    storeAccountData: undefined,
    storeShopData: undefined,
    storeStoreData: undefined,
    storeModeData: undefined,
    contentSummary: undefined,
    setContentSummary: undefined,
    triggerConfetti: undefined,
});

export default AppContext;
