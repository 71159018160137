import React, {useContext, useEffect, useState} from 'react';
import {ROUTE_CONSTANT, STORE_UUID} from "../../constants/app.constants";
import API from "../../api/api";
import {toastError} from "../../context/ToasterContext";
import {useNavigate} from "react-router";
import {useLocation} from "react-router-dom";
import AppContext from "../../context/AppContext";
import _ from "lodash";

const TONE = ['Tone 1', 'Tone 2', 'Tone 3', 'Tone 4'];
const POV = ['POV 1', 'POV 2', 'POV 3', 'POV 4'];
const VOICE = ['Voice 1', 'Voice 2', 'Voice 3', 'Voice 4'];

const StepFiveForm = ({aiSettingsFormData, setAISettingsFormData}) => {
    const navigate = useNavigate();
    let {
        initialDataFetch, token,
        user, account, store, afterLogout
    } = useContext(AppContext);
    
    const [checkboxes, setCheckboxes] = useState({
        generate_image: false,
        generate_faq: false,
        generate_conclusion: false,
        meta_title: false,
        meta_description: false,
    });
    
    const [allTones, setAllTones] = useState([]);
    const [allPov, setAllPov] = useState([]);
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        if (initialDataFetch) {
            if (!!token && !!user) {
                getAIPresetOptions();
            } else {
                navigate(ROUTE_CONSTANT.LOGIN);
            }
        }
    }, [token, user]);
    
    const handleToneChange = (e) => {
        setAISettingsFormData(prevFormData => ({
            ...prevFormData,
            tone: e.target.value
        }));
    };
    
    const handlePOVChange = (e) => {
        setAISettingsFormData(prevFormData => ({
            ...prevFormData,
            pov: e.target.value
        }));
    };
    
    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setAISettingsFormData(prevState => ({
            ...prevState,
            [name]: checked
        }));
    };
    
    const getAIPresetOptions = () => {
        setLoading(true);
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        API.get(`article-generator/get-ai-presets`, headerParams)
            .then(res => {
                const {tones, pov} = res.data.data;
                
                const modifiedTones = _.map(tones, (value, key) => {
                    return {handle: key, name: value};
                });
                setAllTones(modifiedTones);
                
                const modifiedPov = _.map(pov, (value, key) => {
                    return {handle: key, name: value};
                });
                setAllPov(modifiedPov);
                
                setLoading(false);
            })
            .catch(error => {
                console.log('Error: ', error.message);
                setLoading(false);
            });
    };
    
    return (
        <form>
            <div className="">
                <div className="mb-8 uppercase text-sm font-bold text-gray-900">
                    Final Setup
                </div>
                
                <div className="mb-8">
                    <div className="flex">
                        <label htmlFor="tone" className="text-xs uppercase font-bold text-gray-600 dark:text-white">
                            Select Tone
                        </label>
                    </div>
                    <select
                        id="tone"
                        required={true}
                        onChange={handleToneChange}
                        value={aiSettingsFormData.tone}
                        className="bg-gray-50 mt-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                        <option value="">Select Tone</option>
                        {allTones.map((tone, index) => (
                            <option key={index} value={tone.handle}>{tone.name}</option>
                        ))}
                    </select>
                </div>
                
                <div className="mb-8">
                    <div className="flex">
                        <label htmlFor="pov" className="text-xs uppercase font-bold text-gray-600 dark:text-white">
                            Select POV
                        </label>
                    </div>
                    <select
                        id="pov"
                        required={true}
                        onChange={handlePOVChange}
                        value={aiSettingsFormData.pov}
                        className="bg-gray-50 mt-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                        <option value="">Select POV</option>
                        {allPov.map((pov, index) => (
                            <option key={index} value={pov.handle}>{pov.name}</option>
                        ))}
                    </select>
                </div>
                
                <div className="mb-8">
                    <label htmlFor="additional-prompt" className="text-xs uppercase font-bold text-gray-600 dark:text-white">
                        Additional Prompt
                    </label>
                    <textarea
                        className="bg-gray-50 mt-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                        id="additional-prompt"
                        placeholder=""
                        value={aiSettingsFormData.additional_prompt}
                        onChange={(e) => {
                            const name = e.target.value;
                            setAISettingsFormData(prevFormData => ({
                                ...prevFormData,
                                additional_prompt: name,
                            }));
                        }}
                    />
                </div>
                
                <div className="grid grid-cols-3 gap-2 mb-8">
                    <div className="flex items-center mb-4">
                        <input
                            id="generateImage"
                            type="checkbox"
                            name="generateImage"
                            checked={checkboxes.generateImage}
                            onChange={handleCheckboxChange}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500"
                        />
                        <label htmlFor="generateImage" className="ms-2 text-sm font-medium text-gray-900">Generate Image</label>
                    </div>
                    
                    <div className="flex items-center mb-4">
                        <input
                            id="generateFAQ"
                            type="checkbox"
                            name="generateFAQ"
                            checked={checkboxes.generateFAQ}
                            onChange={handleCheckboxChange}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500"
                        />
                        <label htmlFor="generateFAQ" className="ms-2 text-sm font-medium text-gray-900">Generate FAQ</label>
                    </div>
                    
                    <div className="flex items-center mb-4">
                        <input
                            id="generateConclusion"
                            type="checkbox"
                            name="generateConclusion"
                            checked={checkboxes.generateConclusion}
                            onChange={handleCheckboxChange}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500"
                        />
                        <label htmlFor="generateConclusion" className="ms-2 text-sm font-medium text-gray-900">Generate Conclusion</label>
                    </div>
                    
                    <div className="flex items-center mb-4">
                        <input
                            id="metaTitle"
                            type="checkbox"
                            name="metaTitle"
                            checked={checkboxes.metaTitle}
                            onChange={handleCheckboxChange}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500"
                        />
                        <label htmlFor="metaTitle" className="ms-2 text-sm font-medium text-gray-900">Meta Title</label>
                    </div>
                    
                    <div className="flex items-center mb-4">
                        <input
                            id="metaDescription"
                            type="checkbox"
                            name="metaDescription"
                            checked={checkboxes.metaDescription}
                            onChange={handleCheckboxChange}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500"
                        />
                        <label htmlFor="metaDescription" className="ms-2 text-sm font-medium text-gray-900">Meta Description</label>
                    </div>
                </div>
            
            </div>
        
        </form>
    );
};

export default StepFiveForm;
