import React, {useContext, useEffect, useRef, useState} from "react";
import _ from "lodash";
import API from "../../../api/api";
import AppContext from "../../../context/AppContext";

const ContentStructure = ({content, sectionRefs, setSectionRefs}) => {
    
    const [headingsArray, setHeadingsArray] = useState([]);
    
    useEffect(() => {
        const headingsArray = extractHeadings(!!content ? content : "");
        setHeadingsArray(headingsArray);
    }, [content]);
    
    
    const extractHeadings = (html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        
        const result = [];
        const elements = doc.body.querySelectorAll('h1, h2, h3, h4');
        
        elements.forEach((element) => {
            result.push({
                id: _.kebabCase(element.textContent),
                tag: element.tagName.toLowerCase(),
                content: element.textContent
            });
        });
        return result;
    };
    
    const getTagColor = (tag) => {
        switch (tag) {
            case 'h1':
                return 'bg-violet-600';
            case 'h2':
                return 'bg-pink-600 text-white';
            case 'h3':
                return 'text-cyan-700 font-bold';
            case 'h4':
                return 'text-violet-700 font-bold';
            default:
                return 'bg-gray-600';
        }
    };
    
    const getTagMargin = (tag) => {
        switch (tag) {
            case 'h1':
                return 'mt-2';
            case 'h2':
                return 'mt-2 ml-4';
            case 'h3':
                return 'mt-2 ml-8';
            case 'h4':
                return 'mt-2 ml-8';
            default:
                return 'mt-2';
        }
    };
    
    const getCursorPointer = (tag) => {
        switch (tag) {
            case 'h2':
                return 'bg-gray-100 border rounded-lg cursor-pointer';
            case 'h4':
                return 'bg-gray-100 border rounded-lg cursor-pointer';
            default:
                return '';
        }
    };
    
    useEffect(() => {
        setSectionRefs(
            headingsArray.reduce((acc, value) => {
                acc[value.id] = React.createRef();
                return acc;
            }, {})
        );
    }, [headingsArray]);
    
    const scrollToSection = (heading) => {
        if (heading.tag === 'h2') {
            if (sectionRefs[heading.id]) {
                sectionRefs[heading.id].current.scrollIntoView({behavior: 'smooth'});
            } else {
                console.error(`No ref found for id: ${heading.id}`);
            }
        }
    };
    
    
    return (
        <div className="relative overflow-x-auto">
            
            <div className="flex justify-between py-3 px-1 text-sm text-bold">
                <div className="text-left">
                    <p className="font-bold text-[16px]">
                        Content Structure
                    </p>
                </div>
            </div>
            
            {headingsArray.map((heading, index) => (
                <div key={index}
                     onClick={() => scrollToSection(heading)}
                     className={`${getTagMargin(heading.tag)} ${getCursorPointer(heading.tag)}`}>
                    <div className="flex px-1.5 py-1.5 text-sm text-bold ml-1">
                        <div
                            className={`py-1 px-1.5 ${getTagColor(heading.tag)} rounded-lg mr-2 text-xs font-semibold`}>
                            {heading.tag === 'h4' ? 'Widget' : heading.tag.toUpperCase()}
                        </div>
                        <div className={"font-semibold my-auto"}>
                            {heading.tag === 'h4' ? 'Product Card' : heading.content}
                        </div>
                    </div>
                </div>
            ))}
        
        </div>
    );
}

export default ContentStructure;
