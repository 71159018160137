import React, {useContext, useEffect, useState} from "react";

const gridConstant = [
    {id: 1, title: "Title", md: "Meta Description", author: "Author"},
    {id: 2, title: "Title", md: "Meta Description", author: "Author"},
    {id: 3, title: "Title", md: "Meta Description", author: "Author"},
    {id: 4, title: "Title", md: "Meta Description", author: "Author"},
]

const articleRowConstant = [
    {id: 2, row: 2},
    {id: 3, row: 3},
]

const PublishArticleListForm = ({nextStep, step1Payload}) => {
    
    const [selectedAccentColor, setSelectedAccentColor] = useState('#D03801');
    const [selectedSearchBar, setSelectedSearchBar] = useState('show-search-bar');
    const [selectedImageInArticle, setSelectedImageInArticle] = useState('show-image-in-article');
    const [selectedDescInArticle, setSelectedDescInArticle] = useState('show-desc-in-article');
    const [articleRowCount, setArticleRowCount] = useState(2);
    
    const handleAccentColorChange = (event) => {
        setSelectedAccentColor(event.target.value);
    };
    
    const handleSearchBarRadioChange = (event) => {
        setSelectedSearchBar(event.target.id);
    };
    
    const handleImageInArticleRadioChange = (event) => {
        setSelectedImageInArticle(event.target.id);
    };
    
    const handleDescInArticleRadioChange = (event) => {
        setSelectedDescInArticle(event.target.id);
    };
    
    const handleArticleRowCount = (id) => {
        setArticleRowCount(id);
    }
    
    useEffect(() => {
    }, [selectedAccentColor, articleRowCount]);
    
    return (
        <div className="">
            
            <div>
                <div className="w-full bg-white">
                    
                    <div className="grid grid-cols-2 gap-3 mb-3">
                        
                        <div>
                            <div className={"bg-white rounded-lg p-4"}>
                                <div className="text-sm font-semibold text-orange-600 mb-4 px-1">Set Accent Color</div>
                                <div className="flex py-2">
                                    <div
                                        className="w-full text-sm px-1 pt-0.5 text-gray-900 dark:text-gray-300 uppercase font-bold">
                                        {selectedAccentColor}
                                    </div>
                                    
                                    <input
                                        id="accent-color"
                                        type="color"
                                        value={selectedAccentColor}
                                        className="w-16 h-6 mr-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                                        onChange={handleAccentColorChange}
                                    />
                                </div>
                            </div>
                            
                            {/*<div className={"bg-white rounded-lg p-4"}>
                                <div className="text-sm font-semibold text-orange-600 mb-4 px-1">Show Search Bar</div>
                                <div className="flex">
                                    <div
                                        className="flex items-center w-full ps-4 border border-gray-200 rounded-lg px-4">
                                        <input
                                            id="show-search-bar"
                                            type="radio"
                                            value=""
                                            name="search-bar"
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                                            checked={selectedSearchBar === 'show-search-bar'}
                                            onChange={handleSearchBarRadioChange}
                                        />
                                        <label htmlFor="show-search-bar"
                                               className="w-full py-4 ms-2 text-sm font-medium text-gray-900 px-2">
                                            Show Search Bar
                                        </label>
                                    </div>
                                    <div
                                        className="flex items-center w-full ps-4 border border-gray-200 rounded-lg px-4 ml-3">
                                        <input
                                            id="dont-show-search-bar"
                                            type="radio"
                                            value=""
                                            name="search-bar"
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                                            checked={selectedSearchBar === 'dont-show-search-bar'}
                                            onChange={handleSearchBarRadioChange}
                                        />
                                        <label htmlFor="dont-show-search-bar"
                                               className="w-full py-4 ms-2 text-sm font-medium text-gray-900 px-2">
                                            Don't Show Search Bar
                                        </label>
                                    </div>
                                </div>
                            </div>*/}
                            
                            <div className={"bg-white rounded-lg p-4"}>
                                <div className="text-sm font-semibold text-orange-600 mb-4 px-1">Show Image In Article
                                </div>
                                <div className="flex">
                                    <div
                                        className="flex items-center w-full ps-4 border border-gray-200 rounded-lg px-4">
                                        <input
                                            id="show-image-in-article"
                                            type="radio"
                                            value=""
                                            name="show-image-in-article"
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                                            checked={selectedImageInArticle === 'show-image-in-article'}
                                            onChange={handleImageInArticleRadioChange}
                                        />
                                        <label htmlFor="show-image-in-article"
                                               className="w-full py-4 ms-2 text-sm font-medium text-gray-900 px-2">
                                            Show Image
                                        </label>
                                    </div>
                                    <div
                                        className="flex items-center w-full ps-4 border border-gray-200 rounded-lg px-4 ml-3">
                                        <input
                                            id="dont-show-image-in-article"
                                            type="radio"
                                            value=""
                                            name="dont-show-image-in-article"
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                                            checked={selectedImageInArticle === 'dont-show-image-in-article'}
                                            onChange={handleImageInArticleRadioChange}
                                        />
                                        <label htmlFor="dont-show-image-in-article"
                                               className="w-full py-4 ms-2 text-sm font-medium text-gray-900 px-2">
                                            Don't Show Image
                                        </label>
                                    </div>
                                </div>
                            </div>
                            
                            <div className={"bg-white rounded-lg p-4"}>
                                <div className="text-sm font-semibold text-orange-600 mb-4 px-1">
                                    Show Meta Description In Article
                                </div>
                                <div className="flex">
                                    <div
                                        className="flex items-center w-full ps-4 border border-gray-200 rounded-lg px-4">
                                        <input
                                            id="show-desc-in-article"
                                            type="radio"
                                            value=""
                                            name="show-desc-in-article"
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                                            checked={selectedDescInArticle === 'show-desc-in-article'}
                                            onChange={handleDescInArticleRadioChange}
                                        />
                                        <label htmlFor="show-desc-in-article"
                                               className="w-full py-4 ms-2 text-sm font-medium text-gray-900 px-2">
                                            Show Meta Description
                                        </label>
                                    </div>
                                    <div
                                        className="flex items-center w-full ps-4 border border-gray-200 rounded-lg px-4 ml-3">
                                        <input
                                            id="dont-show-desc-in-article"
                                            type="radio"
                                            value=""
                                            name="dont-show-desc-in-article"
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                                            checked={selectedDescInArticle === 'dont-show-desc-in-article'}
                                            onChange={handleDescInArticleRadioChange}
                                        />
                                        <label htmlFor="dont-show-desc-in-article"
                                               className="w-full py-4 ms-2 text-sm font-medium text-gray-900 px-2">
                                            Don't Show Meta Description
                                        </label>
                                    </div>
                                </div>
                            </div>
                            
                            <div className={"bg-white rounded-lg p-4"}>
                                <div className="text-sm font-semibold text-orange-600 mb-4 px-1">Article Row Count
                                </div>
                                <div className="mt-2 grid grid-cols-2 gap-3">
                                    {articleRowConstant.map((row) => (
                                        <div
                                            key={row.id}
                                            className={`text-gray-700 border border-gray-400 cursor-pointer w-full text-center font-bold rounded-lg text-sm px-6 py-2.5 ${
                                                articleRowCount === row.id ? 'bg-gray-500 text-white' : ''
                                            }`}
                                            onClick={() => handleArticleRowCount(row.id)}
                                        >
                                            {row.row} Rows
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        
                        <div>
                            <div
                                className="bg-gray-100 rounded-lg font-medium p-4 m-2">
                                <div className="flex flex-row items-center justify-between mt-3">
                                    <span className="text-left text-sm ml-4 font-bold">Otaku Nerd Store</span>
                                    {selectedSearchBar === 'show-search-bar' &&
                                    <button disabled
                                            style={{"background": `${selectedAccentColor}`}}
                                            className={`flex text-right text-white px-6 py-2 rounded-lg mr-4`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth="2"
                                             stroke="currentColor" className="w-4 h-4 mt-0.5">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"/>
                                        </svg>
                                        <span className="ml-3 text-sm">Search</span>
                                    </button>
                                    }
                                </div>
                                
                                <div className={"mb-4 mt-6 bg-gray-100 rounded-lg ml-4 mr-4"}>
                                    <div className={`grid grid-cols-${articleRowCount} gap-2`}>
                                        {gridConstant.map((key, value) => (
                                            <div className="bg-white rounded-xl mt-1">
                                                <div className="cursor-pointer">
                                                    {selectedImageInArticle === 'show-image-in-article' &&
                                                        <div className="flex-1">
                                                            <div className="w-full rounded-t-lg bg-gray-200 h-28"/>
                                                        </div>
                                                    }
                                                    <div
                                                        className="flex-1 p-4 my-auto ml-1 flex flex-col justify-between h-full">
                                                        <div className="font-bold mt-1 font-sm">{key.title}</div>
                                                        
                                                        <div className="flex flex-col justify-between h-full">
                                                            {selectedDescInArticle === 'show-desc-in-article' &&
                                                                <div className="text-xs text-gray-500 mt-1">
                                                                    {key.md}
                                                                </div>}
                                                            <div className="text-xs text-gray-500 mt-2 mb-1">
                                                                {key.author}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        
                        </div>
                    
                    </div>
                
                </div>
                
                <div className={"absolute bottom-10 left-[50%] -translate-x-1/2 pt-4"}>
                    <button type="button"
                            onClick={() => {
                                const payload = {
                                    selectedSearchBar,
                                    selectedAccentColor,
                                    selectedImageInArticle,
                                    selectedDescInArticle
                                };
                                nextStep();
                                step1Payload(payload);
                            }}
                            className="text-white w-0.5/4 font-bold mt-2 hover:bg-gray-800 bg-gray-700 rounded-lg text-sm px-6 py-2.5 mr-2">
                        NEXT
                    </button>
                </div>
            </div>
        
        </div>
    )
}

export default PublishArticleListForm;
