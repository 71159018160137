const AppModalLong = ({title, classes, children}) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center" style={{"zIndex": "1000"}}>
            <div className="bg-black opacity-50 fixed inset-0"></div>
            <div className="bg-white w-3/4 h-3/4 rounded-lg p-6 shadow-lg relative overflow-y-auto">
                {children}
            </div>
        </div>
    );
};

export default AppModalLong;
