import React, {useContext, useEffect, useState} from "react";
import API from "../../../api/api";
import AppContext from "../../../context/AppContext";

const SEOBestPractice = ({content}) => {
    
    const [articleContent, setArticleContent] = useState("");
    let {initialDataFetch, token, user, account} = useContext(AppContext);
    const [allIssues, setAllIssues] = useState("");
    
    const [issues, setIssues] = useState([]);
    
    useEffect(() => {
        // getHtmlContentFromArticleUrl();
    }, []);
    
    const getHtmlContentFromArticleUrl = () => {
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        API.get(`shopify/blogs/articles`, headerParams)
            .then(res => {
                setArticleContent(res.data.data.htmlContent);
            })
            .catch(e => {
                console.log("Error: ", e);
            })
    }
    
    const checkMetaTitleStatus = () => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(articleContent, 'text/html');
        let result = {};
        const metaTitleElement = doc.querySelector('title');
        const metaTitle = metaTitleElement ? metaTitleElement.textContent : '';
        const titleCount = metaTitle.length;
        const expectedCount = 70;
        let status = '';
        if (titleCount === 0 || titleCount > expectedCount) {
            status = 'error';
        } else if (titleCount < 10) {
            status = 'error';
        } else if (titleCount < 50) {
            status = 'warning';
        } else if (titleCount >= 65 && titleCount <= 70) {
            status = 'success';
        }
        result = {rule: 'meta-title', expected: expectedCount, used: titleCount, status: status};
        setIssues([...issues, result]);
    };
    
    const checkMetaDescriptionStatus = () => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(articleContent, 'text/html');
        const metaDescriptionElement = doc.querySelector('meta[name="description"]');
        const metaDescription = metaDescriptionElement ? metaDescriptionElement.getAttribute('content') : '';
        const descriptionCount = metaDescription.length;
        const expectedCount = 320;
        let status = '';
        let result = {};
        if (descriptionCount === 0 || descriptionCount > expectedCount) {
            status = 'error';
        } else if (descriptionCount < 100) {
            status = 'error';
        } else if (descriptionCount < 200) {
            status = 'warning';
        } else if (descriptionCount >= 300 && descriptionCount <= 320) {
            status = 'success';
        }
        result = {rule: 'meta-description', expected: expectedCount, used: descriptionCount, status: status};
        setIssues([...issues, result]);
    };
    
    const getHeadingTagStatus = () => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(articleContent, 'text/html');
        const h1Count = doc.getElementsByTagName('h1').length;
        const h2Count = doc.getElementsByTagName('h2').length;
        const h3Count = doc.getElementsByTagName('h3').length;
        const expectedCount = 1;
        const totalCount = h1Count + h2Count + h3Count;
        const status = totalCount >= expectedCount ? 'success' : 'failure';
        const result = {
            rule: 'heading-tags',
            expected: expectedCount,
            used: totalCount,
            status: status,
        }
        setIssues([...issues, result]);
    }
    
    const checkImageAlt = () => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(articleContent, 'text/html');
        const imgElements = doc.getElementsByTagName('img');
        const imgCount = imgElements.length;
        let emptyAltCount = 0;
        
        for (let i = 0; i < imgCount; i++) {
            const alt = imgElements[i].getAttribute('alt');
            if (!alt || alt.trim() === '') {
                emptyAltCount++;
            }
        }
        
        const expectedCount = imgCount;
        const nonEmptyAltCount = imgCount - emptyAltCount;
        
        let status = '';
        if (emptyAltCount === 0) {
            status = 'success';
        } else if (emptyAltCount < imgCount) {
            status = 'warning';
        } else {
            status = 'error';
        }
        const result = {
            rule: 'image-alt',
            expected: expectedCount,
            used: nonEmptyAltCount,
            status: status,
        };
        setIssues([...issues, result]);
    }
    
    const checkMetaViewportStatus = () => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(articleContent, 'text/html');
        const metaViewport = doc.querySelector('meta[name="viewport"]');
        const expectedCount = 1;
        const usedCount = metaViewport ? 1 : 0;
        const status = usedCount === expectedCount ? 'success' : 'error';
        const result = {
            rule: 'meta-viewport',
            expected: expectedCount,
            used: usedCount,
            status: status,
        };
        setIssues([...issues, result]);
    }
    
    const checkFramesetTagStatus = () => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(articleContent, 'text/html');
        const framesetTags = doc.getElementsByTagName('frameset');
        const framesetCount = framesetTags.length;
        const expectedCount = 0;
        const usedCount = framesetCount;
        const status = usedCount === expectedCount ? 'success' : 'error';
        const result = {
            rule: 'frameset-tag',
            expected: expectedCount,
            used: usedCount,
            status: status,
        };
        setIssues([...issues, result]);
    }
    
    const checkNoIndexTagStatus = () => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(articleContent, 'text/html');
        const metaTags = doc.getElementsByTagName('meta');
        let usedCount = 0;
        for (let i = 0; i < metaTags.length; i++) {
            const metaTag = metaTags[i];
            const name = metaTag.getAttribute('name');
            const content = metaTag.getAttribute('content');
            if (name === 'robots' && content && content.toLowerCase().includes('noindex')) {
                usedCount = 1;
                break;
            }
        }
        const expectedCount = 0;
        const status = usedCount === expectedCount ? 'success' : 'error';
        const result = {
            rule: 'noindex',
            expected: expectedCount,
            used: usedCount,
            status: status,
        };
        setIssues([...issues, result]);
    }
    
    const checkNoFollowTagStatus = () => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(articleContent, 'text/html');
        const anchorTags = doc.getElementsByTagName('a');
        let usedCount = 0;
        for (let i = 0; i < anchorTags.length; i++) {
            const anchorTag = anchorTags[i];
            const relAttribute = anchorTag.getAttribute('rel');
            if (relAttribute && relAttribute.toLowerCase().includes('nofollow')) {
                usedCount++;
            }
        }
        const expectedCount = 0;
        const status = usedCount === expectedCount ? 'success' : 'error';
        const result = {
            rule: 'nofollow',
            expected: expectedCount,
            used: usedCount,
            status: status,
        };
        setIssues([...issues, result]);
    }
    
    const checkMetaRefreshTagStatus = () => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(articleContent, 'text/html');
        const metaTags = doc.getElementsByTagName('meta');
        let usedCount = 0;
        for (let i = 0; i < metaTags.length; i++) {
            const metaTag = metaTags[i];
            const httpEquivAttribute = metaTag.getAttribute('http-equiv');
            if (httpEquivAttribute && httpEquivAttribute.toLowerCase() === 'refresh') {
                usedCount++;
            }
        }
        const expectedCount = 0;
        const status = usedCount === expectedCount ? 'success' : 'error';
        const result = {
            rule: 'meta-refresh',
            expected: expectedCount,
            used: usedCount,
            status: status,
        };
        setIssues([...issues, result]);
    }
    
    const checkHtmlPageSize = () => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(articleContent, 'text/html');
        const encoder = new TextEncoder();
        const bytes = encoder.encode(content);
        const kbSize = bytes.length / 1024;
        const mbSize = kbSize / 1024;
        const result = {
            rule: 'html-page-size-kb',
            expected: 33,
            used: parseFloat(kbSize.toFixed(2)),
            status: parseFloat(kbSize.toFixed(2)) < 33 ? 'success' : 'error',
        };
        setIssues([...issues, result]);
    }
    
    const getIssues = async () => {
        await Promise.all([
            checkMetaTitleStatus(),
            getHeadingTagStatus(),
            checkMetaDescriptionStatus(),
            checkImageAlt(),
            checkHtmlPageSize(),
            checkFramesetTagStatus(),
            checkMetaViewportStatus(),
            checkNoIndexTagStatus(),
            checkNoFollowTagStatus(),
            checkMetaRefreshTagStatus()
        ]);
    };
    
    return (
        <div className="relative overflow-x-auto">
            {/*<div className="flex justify-between py-3 px-1 text-sm text-bold">
                <div className="text-left">
                    <p className="font-regular w-[200px]">
                        Make your blog super interactive by finishing the issues below!
                    </p>
                </div>
                <div>
                    <img
                        src={ArticlePlaceHolder}
                        alt="random"
                    />
                </div>
            </div>
            
            <div className="my-4">
                <hr/>
            </div>*/}
            
            <div className="flex justify-between py-3 px-1 text-sm text-bold">
                <div className="text-left">
                    <p className="font-bold text-[16px]">
                        Issues
                    </p>
                </div>
                <div>
                    <p className="font-regular text-primary underline font-semibold cursor-pointer">
                        Update all
                    </p>
                </div>
            </div>
            
            
            <div className="bg-gray-100 border rounded-lg mt-2">
                <div className="flex px-2 py-3 text-sm text-bold">
                    <div className={"mr-2"}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                             stroke="currentColor" className="w-6 h-6 text-red-600">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"/>
                        </svg>
                    </div>
                    <div className={""}>
                        <p className="font-semibold">There should be at least one h1 tags in a blog</p>
                        {/*<div className="flex mt-2">
                            <div className="text-sm">Current: 0</div>
                            <div className="ml-4 text-sm underline">Update</div>
                        </div>*/}
                    </div>
                </div>
            </div>
            
            <div className="bg-gray-100 border rounded-lg mt-2">
                <div className="flex px-2 py-3 text-sm text-bold">
                    <div className={"mr-2"}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                             stroke="currentColor" className="w-6 h-6 text-violet-600">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"/>
                        </svg>
                    </div>
                    <div className={""}>
                        <p className="font-semibold">There should be at least one h1 tags in a blog</p>
                        {/*<div className="flex mt-2">
                            <div className="text-sm">Current: 0</div>
                            <div className="ml-4 text-sm underline">Update</div>
                        </div>*/}
                    </div>
                </div>
            </div>
            
            <button
                onClick={() => {
                    getIssues().then(() => {
                        console.log("issues", issues);
                    });
                }}
                className={`text-white text-center bg-primary w-full font-bold rounded-lg text-sm px-5 py-2 mt-5`}>
                View All Issues
            </button>
        
        </div>
    );
}

export default SEOBestPractice;
