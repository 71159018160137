import {useContext, useEffect, useState} from "react";
import {AUTH, ROUTE_CONSTANT, STORE_UUID} from "../../../constants/app.constants";
import AppContext from "../../../context/AppContext";
import {useNavigate} from "react-router";
import AppModal from "../../shared/AppModal";
import AppModalShort from "../../shared/AppModalShort";

const WordFrequency = ({content}) => {
    
    const navigate = useNavigate();
    const [wordFrequency, setWordFrequency] = useState([]);
    const [wordFrequencyModal, setWordFrequencyModal] = useState([]);
    let {initialDataFetch, token, user, account, store, afterLogout} = useContext(AppContext);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchQueryModal, setSearchQueryModal] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };
    
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    
    const getFrequencyOfWords = (content) => {
        const cleanContent = content?.replace(/<[^>]+>/g, ''); // Remove HTML tags
        const words = cleanContent.match(/\b\w+\b/g); // Extract words
        const wordFrequency = {};
        let totalWordsCount = 0;
        if (words) {
            words.forEach((word) => {
                if (wordFrequency[word]) {
                    wordFrequency[word]++;
                } else {
                    wordFrequency[word] = 1;
                }
                totalWordsCount++;
            });
        }
        return {wordFrequency, totalWordsCount};
    };
    
    const handleSearchWordFrequency = (event) => {
        setSearchQuery(event.target.value);
    };
    
    const handleSearchWordFrequencyModal = (event) => {
        setSearchQueryModal(event.target.value);
    };
    
    const filteredWordFrequencyArray = wordFrequency.filter(
        (item) =>
            item.word.toLowerCase().includes(searchQuery.toLowerCase())
    );
    
    const filteredWordFrequencyArrayModal = wordFrequencyModal.filter(
        (item) =>
            item.word.toLowerCase().includes(searchQueryModal.toLowerCase())
    );
    
    useEffect(() => {
        if (initialDataFetch) {
            if (!!token && !!user) {
                const {wordFrequency} = getFrequencyOfWords(content);
                const wordFrequencyArray = Object.entries(wordFrequency).map(([word, count]) => ({word, count}));
                setWordFrequency(wordFrequencyArray);
                setWordFrequencyModal(wordFrequencyArray);
            } else {
                navigate(ROUTE_CONSTANT.LOGIN);
            }
        }
    }, [token, user]);
    
    
    return (
        <div>
            <div className={"bg-red-50 border border-gray-300 p-2 rounded-lg"}>
                
                <div className="flex justify-between py-3 px-2 text-sm text-bold">
                    <div className={`text-left -mt-1`}>
                        Know your word’s usage count
                    </div>
                    <div
                        onClick={handleOpenModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                             stroke="currentColor" className="w-4 h-4 cursor-pointer">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"/>
                        </svg>
                    </div>
                </div>
                
                <form className={"px-1"}>
                    <div className="relative">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none"
                                 stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
                            </svg>
                        </div>
                        <input type="search" id="m-search"
                               className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                               value={searchQuery}
                               onChange={handleSearchWordFrequency}
                               placeholder="Search Words..." required/>
                    </div>
                </form>
                
                <div className="flex flex-wrap pb-3 pt-2 px-2 mt-2 max-h-80 overflow-y-auto">
                    
                    {filteredWordFrequencyArray.map(frequency => (
                        <div
                            id={frequency.word}
                            className="flex justify-between bg-[#F8E4DD] border border-[#F3C0AE] text-xs font-medium mr-2 px-2.5 py-1 my-1 rounded-full">
                            <div
                                className="text-gray-700 text-left mr-2">
                                {frequency.word}
                            </div>
                            <div
                                className="font-bold text-[#E35C39]">
                                {frequency.count}
                            </div>
                        </div>
                    ))}
                    
                    {filteredWordFrequencyArray.length === 0 && (
                        <div className="text-center text-gray-500 mt-2 mx-auto flex">
                            No results found
                        </div>
                    )}
                
                </div>
            </div>
            
            {/* Modal */}
            {isModalOpen && (
                <AppModalShort title={""}>
                    <div className="">
                        <div className="flex justify-between py-3 px-1 text-sm text-bold">
                            <div className="text-left">
                                <h2 className="text-xl font-bold">Word Frequency</h2>
                                <p className="font-regular mb-4">
                                    Know your word’s usage count and optimize your content
                                </p>
                            </div>
                            <div>
                                <form>
                                    <div className="relative mt-1">
                                        <div
                                            className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                            <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400"
                                                 fill="none"
                                                 stroke="currentColor" viewBox="0 0 24 24"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
                                            </svg>
                                        </div>
                                        <input type="search" id="modal-search"
                                               className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                               value={searchQueryModal}
                                               onChange={handleSearchWordFrequencyModal}
                                               placeholder="Search Words..." required/>
                                    </div>
                                </form>
                            </div>
                        </div>
                        
                        
                        <div className="h-[300px]">
                            <div className="flex flex-wrap pb-3 pt-2 px-1 max-h-80 overflow-y-auto">
                                {filteredWordFrequencyArrayModal.map(frequency => (
                                    <div
                                        id={frequency.word}
                                        className="flex justify-between bg-[#F8E4DD] border border-[#F3C0AE] text-xs font-medium mr-2 px-2.5 py-1 my-1 rounded-full">
                                        <div
                                            className="text-gray-700 text-left mr-2">
                                            {frequency.word}
                                        </div>
                                        <div
                                            className="font-bold text-[#E35C39]">
                                            {frequency.count}
                                        </div>
                                    </div>
                                ))}
                                
                                {filteredWordFrequencyArrayModal.length === 0 && (
                                    <div className="text-center text-gray-500">
                                        No results found
                                    </div>
                                )}
                            </div>
                        </div>
                        
                        <button
                            className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 mt-8 float-right rounded-lg"
                            onClick={handleCloseModal}
                        >
                            Close
                        </button>
                    </div>
                </AppModalShort>
            )}
        
        </div>
    )
}

export default WordFrequency;
