import React, {useContext, useEffect, useState} from 'react'
import {useNavigate} from "react-router";
import Chart from "react-apexcharts";
import Sidebar from "../components/layouts/Sidebar";
import TopNav from "../components/layouts/TopNav";
import PageTitle from "../components/typography/PageTitle";
import AppChart from "../components/layouts/AppChart";
import API from "../api/api";
import AppContext from "../context/AppContext";
import axios from "axios";
import {ROUTE_CONSTANT} from "../constants/app.constants";
import SecondaryTopNav from "../components/layouts/SecondaryTopNav";
import classNames from "classnames";
import {useLocation} from "react-router-dom";
import _ from "lodash";


const browsers = [
    {
        id: 1,
        name: 'Chrome',
        stat: '71,897',
        change: '122',
        changeType: 'increase',
        icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                   stroke="currentColor" className="w-6 h-6 text-white">
            <path strokeLinecap="round" strokeLinejoin="round"
                  d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418"/>
        </svg>
    },
    {
        id: 2,
        name: 'Safari',
        stat: '583',
        change: '5.4%',
        changeType: 'increase',
        icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                   stroke="currentColor" className="w-6 h-6 text-white">
            <path strokeLinecap="round" strokeLinejoin="round"
                  d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418"/>
        </svg>
    },
    {
        id: 3,
        name: 'Firefox',
        stat: '581',
        change: '5.4%',
        changeType: 'increase',
        icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                   stroke="currentColor" className="w-6 h-6 text-white">
            <path strokeLinecap="round" strokeLinejoin="round"
                  d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418"/>
        </svg>
    },
    {
        id: 4,
        name: 'Brave',
        stat: '581',
        change: '5.4%',
        changeType: 'increase',
        icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                   stroke="currentColor" className="w-6 h-6 text-white">
            <path strokeLinecap="round" strokeLinejoin="round"
                  d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418"/>
        </svg>
    }
]

const stats = [
    {
        id: 1,
        name: 'Mobile',
        stat: '71,897',
        change: '122',
        changeType: 'increase',
        color: 'indigo',
        icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                   strokeWidth="1.5" stroke="currentColor"
                   className="w-6 h-6 text-white">
            <path strokeLinecap="round" strokeLinejoin="round"
                  d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"/>
        </svg>
    },
    {
        id: 2,
        name: 'Web',
        stat: '58.16%',
        change: '232',
        changeType: 'increase',
        color: 'indigo',
        icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                   stroke="currentColor" className="w-6 h-6 text-white">
            <path strokeLinecap="round" strokeLinejoin="round"
                  d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25"/>
        </svg>
    },
    {
        id: 3,
        name: 'Total Views',
        stat: '71,897',
        change: '122',
        changeType: 'increase',
        color: 'green',
        icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                   strokeWidth="1.5" stroke="currentColor"
                   className="w-6 h-6 text-white">
            <path strokeLinecap="round" strokeLinejoin="round"
                  d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z"/>
        </svg>
    },
    {
        id: 4,
        name: 'Distinct Views',
        stat: '232',
        change: '5.4%',
        changeType: 'increase',
        color: 'green',
        icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                   strokeWidth="1.5" stroke="currentColor"
                   className="w-6 h-6 text-white">
            <path strokeLinecap="round" strokeLinejoin="round"
                  d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z"/>
        </svg>
    },
]


export default function Analytics() {
    
    const navigate = useNavigate();
    let {initialDataFetch, token, user, account, store, afterLogout} = useContext(AppContext);
    const [allBlogs, setAllBlogs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    
    const topArticles = {
        "series": [
            {
                "data": [
                    3
                ]
            }
        ],
        "options": {
            "chart": {
                "type": "bar",
                "height": 200,
                "fontFamily": "inherit",
                "toolbar": {
                    "show": false
                }
            },
            "plotOptions": {
                "bar": {
                    "borderRadius": 10,
                    "borderRadiusApplication": "end",
                    "horizontal": true,
                    "barHeight": "45%"
                }
            },
            "colors": [
                "#7c3aed"
            ],
            "dataLabels": {
                "enabled": false
            },
            "xaxis": {
                "categories": [
                    "Unlocking the Power of K1 and K2 for Enhanced SEO Strategies"
                ],
                "labels": {
                    "style": {
                        "fontSize": "13px"
                    }
                }
            },
            "yaxis": {
                "labels": {
                    "style": {
                        "fontSize": "13px"
                    }
                }
            },
            "grid": {
                "show": false
            }
        }
    };
    
    const location = useLocation();
    const [analytics, setAnalytics] = useState({});
    const [statsData, setStatsData] = useState([]);
    const [browserStatsData, setBrowserStatsData] = useState([]);
    const [topArticlesChart, setTopArticlesChart] = useState([]);
    const [clickState, setClickState] = useState({});
    const [topBlogArticles, setTopBlogArticles] = useState(null);
    const [topArticleVisits, setTopArticleVisits] = useState(null);
    const [blogVisitsPerDate, setBlogVisitsPerDate] = useState(null);
    const [articleVisitsPerDate, setArticleVisitsPerDate] = useState(null);
    const {blog_id, blog_uuid, blog_title, blog_handle} = location.state;
    
    useEffect(() => {
        if (initialDataFetch) {
            if (!!token && !!user && !!store) {
                if (store === undefined || store === null || store === "") {
                    // setAllBlogs([]);
                } else {
                    fetchAnalytics();
                }
            } else {
                navigate(ROUTE_CONSTANT.LOGIN);
            }
        }
    }, [token, user, store]);
    
    const mergeStats = (statsData, stats) => {
        return stats.map(statItem => {
            const matchingStatData = statsData.find(data => data.name === statItem.name);
            return matchingStatData ? {...statItem, stat: matchingStatData.stat} : statItem;
        });
    };
    
    const mergeBrowserStats = (browserStatsData, browserStats) => {
        return browserStats.map(statItem => {
            const matchingStatData = browserStatsData.find(data => _.capitalize(data.name) === _.capitalize(statItem.name));
            return matchingStatData ? {...statItem, stat: matchingStatData.stat} : statItem;
        });
    };
    
    const updateTopArticles = (topArticlesData, topArticles) => {
        console.log("topArticlesData: ", topArticlesData);
        console.log("topArticles: ", topArticles);
        return {
            ...topArticles,
            series: [{data: topArticlesData.data}],
            options: {
                ...topArticles.options,
                xaxis: {
                    ...topArticles.options.xaxis,
                    categories: topArticlesData.categories
                }
            }
        };
    };
    
    const fetchAnalytics = () => {
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        axios.get(`http://localhost:4001/api/v1/analytics/get-blog-analytics/${account}/${store}/${blog_uuid}?frequency=last30days`, headerParams)
            .then(res => {
                setAnalytics(res.data.data);
                const mergedStats = mergeStats(res.data.data.statsData, stats);
                setStatsData(mergedStats);
                const mergedBrowserStats = mergeBrowserStats(res.data.data.browsersData, browsers);
                setBrowserStatsData(mergedBrowserStats);
                const updatedTopArticles = updateTopArticles(res.data.data.topArticlesData, topArticles);
                console.log("updatedTopArticles: ", updatedTopArticles);
                setTopArticlesChart(updatedTopArticles);
            })
            .catch(error => {
                console.log("error: ", error);
            })
    }
    
    
    return (
        
        <div>
            
            <TopNav/>
            
            <SecondaryTopNav/>
            
            <div className="max-w-5xl px-6 mx-auto mb-8">
                
                <div className="ml-2 mt-6">
                    <h1>Stats & Analytics</h1>
                </div>
                
                <div className="mt-6">
                    <dl className="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-4 md:divide-x md:divide-y-0">
                        {statsData?.map((item) => (
                            <div className="flex">
                                <div className={`rounded-md bg-${item.color}-500 p-2 my-auto ml-6`}>
                                    {item.icon}
                                </div>
                                
                                <div key={item.name} className="py-5 pl-4">
                                    <dt className="text-sm font-normal text-gray-900">{item.name}</dt>
                                    <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                                        <div className={`flex items - baseline text-xl font-bold text-black`}>
                                            {item.stat}
                                        </div>
                                    </dd>
                                </div>
                            </div>
                        ))}
                    </dl>
                </div>
                
                
                <div className="mt-8">
                    <h6 className={"mb-4 text-normal font-semibold ml-2"}>Article Stats</h6>
                    <div className="bg-white card-border-radius p-4 border border-gray-200 rounded-lg h-auto">
                        <AppChart
                            chart={topArticles} series={topArticles?.series} type={'bar'}
                            height={250}/>
                    </div>
                </div>
                
                <div className="h-auto mt-8">
                    <h6 className={"mb-4 text-normal font-semibold ml-2"}>Browser Stats</h6>
                    <dl className="grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-4 md:divide-x md:divide-y-0">
                        {browserStatsData?.map((item) => (
                            <div className="flex" key={item.name}>
                                <div className={`rounded-md bg-yellow-500 p-2 my-auto ml-6`}>
                                    {item.icon}
                                </div>
                                
                                <div className="py-5 pl-4">
                                    <dt className="text-sm font-normal text-gray-900 capitalize">{item.name}</dt>
                                    <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                                        <div className={`flex items - baseline text-xl font-bold text-black`}>
                                            {item.stat}
                                        </div>
                                    </dd>
                                </div>
                            </div>
                        ))}
                    </dl>
                </div>
            
            </div>
        
        </div>
    )
}
