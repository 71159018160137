import toast from "react-hot-toast";
import {TOASTER_STYLES} from "../constants/app.constants";

export function toastSuccess(message) {
  toast.success(message, TOASTER_STYLES);
}

export function toastError(message) {
  toast.error(message, TOASTER_STYLES);
}

export function toastWarning(message) {
  const styles = TOASTER_STYLES;
  styles.icon = '⚠️';
  toast(message, styles);
}
