import React, {useContext, useEffect, useState} from "react";
import {
    DISPLAY_POSITION,
    DISPLAY_SIZE, POPUP_TRIGGER,
    ROUTE_CONSTANT,
    SOCIAL_MEDIA_WIDGET,
    STORE_UUID
} from "../../../constants/app.constants";
import TwitterIcon from "../../../assets/img/twitter.png";
import FacebookIcon from "../../../assets/img/facebook.png";
import InstagramIcon from "../../../assets/img/instagram.png";
import PinterestIcon from "../../../assets/img/pinterest.png";
import LinkIcon from "../../../assets/img/link.png";
import AppModalShort from "../AppModalShort";
import Shimmer from "../../layouts/Shimmer";
import API from "../../../api/api";
import {useLocation} from "react-router";
import AppContext from "../../../context/AppContext";
import {toastError, toastSuccess} from "../../../context/ToasterContext";
import {Toaster} from "react-hot-toast";


const POSITIONS = ['top', 'bottom'];

const SocialMediaWidgetForm = ({closeWidget, widgetData, getSocialMediaWidgets}) => {
    
    const location = useLocation();
    const {blog_id, blog_uuid} = location.state;
    let {initialDataFetch, token, user, account, store} = useContext(AppContext);
    
    const [facebookUrl, setFacebookUrl] = useState('');
    const [pinInterestUrl, setPinInterestUrl] = useState('');
    const [twitterUrl, setTwitterUrl] = useState('');
    const [instagramUrl, setInstagramUrl] = useState('');
    const [personalWebsiteUrl, setPersonalWebsiteUrl] = useState('');
    const [position, setPosition] = useState('top');
    const [activateLoading, setActivateLoading] = useState(false);
    const [deactivateLoading, setDeactivateLoading] = useState(false);
    
    useEffect(() => {
        if (widgetData) {
            setFacebookUrl(widgetData.facebook || '');
            setPinInterestUrl(widgetData.pinterest || '');
            setTwitterUrl(widgetData.x || '');
            setInstagramUrl(widgetData.instagram || '');
            setPersonalWebsiteUrl(widgetData.personal_website || '');
        }
    }, [widgetData]);
    
    const handleFacebookUrlChange = (e) => setFacebookUrl(e.target.value);
    const handlePinInterestUrlChange = (e) => setPinInterestUrl(e.target.value);
    const handleTwitterUrlChange = (e) => setTwitterUrl(e.target.value);
    const handleInstagramUrlChange = (e) => setInstagramUrl(e.target.value);
    const handlePersonalWebsiteUrlChange = (e) => setPersonalWebsiteUrl(e.target.value);
    const handlePositionChange = (e) => setPosition(e.target.value);
    
    const socialMediaInputFields = [{
        icon: FacebookIcon,
        value: facebookUrl,
        onChange: handleFacebookUrlChange,
        placeholder: "Facebook URL",
        apiAttributes: 'facebook'
    }, {
        icon: PinterestIcon,
        value: pinInterestUrl,
        onChange: handlePinInterestUrlChange,
        placeholder: "Pinterest URL",
        apiAttributes: 'pinterest'
    }, {
        icon: TwitterIcon,
        value: twitterUrl,
        onChange: handleTwitterUrlChange,
        placeholder: "Twitter URL",
        apiAttributes: 'x'
    }, {
        icon: InstagramIcon,
        value: instagramUrl,
        onChange: handleInstagramUrlChange,
        placeholder: "Instagram URL",
        apiAttributes: 'instagram'
    }, {
        icon: LinkIcon,
        value: personalWebsiteUrl,
        onChange: handlePersonalWebsiteUrlChange,
        placeholder: "Personal URL",
        apiAttributes: 'personal_website'
    }];
    
    
    useEffect(() => {
    }, []);
    
    
    const handleSocialMediaWidgetCreation = (payload, status) => {
        if (status === 'active') setActivateLoading(true)
        if (status === 'deactive') setDeactivateLoading(true)
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        const store_uuid = localStorage.getItem(STORE_UUID);
        API.post(`social_widgets/add-social-widget/${account}/${store_uuid}/${blog_uuid}`, payload, headerParams)
            .then(res => {
                toastSuccess("Widget Saved Successfully!");
                getSocialMediaWidgets();
                if (status === 'active') setActivateLoading(false)
                if (status === 'deactive') setDeactivateLoading(false)
            })
            .catch(error => {
                if (status === 'active') setActivateLoading(false)
                if (status === 'deactive') setDeactivateLoading(false)
                toastError(error.message);
                console.log('Error: ', error.message);
            })
    }
    
    
    return (
        <>
            <div className="bg-gray-100 border border-gray-200 rounded-lg p-4 mt-6">
                <div className="grid grid-cols-1 gap-6 my-2">
                    
                    <div className="relative mb-3">
                        <label
                            htmlFor="position"
                            className="block mb-2 text-sm font-semibold text-gray-700 dark:text-white ml-2">
                            Select Position
                        </label>
                        <div className="flex mt-5">
                            {POSITIONS.map((pos, index) => (
                                <div key={index} className="flex items-center ml-2 mr-2">
                                    <input
                                        id={`pos-${index}`}
                                        type="radio"
                                        value={pos}
                                        name="position"
                                        className="w-4 h-4 text-blue-600 bg-white border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        onChange={handlePositionChange}
                                        checked={position === pos}
                                    />
                                    <label
                                        htmlFor={`pos-${index}`}
                                        className="ml-2 text-sm text-gray-900 dark:text-gray-500 capitalize">
                                        {pos}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                    
                    {socialMediaInputFields.map((input, index) => (
                        <div key={index} className="relative">
                            <label htmlFor={`input-group-${index + 1}`}
                                   className="block mb-3 text-sm font-semibold text-gray-700 dark:text-white ml-2">
                                Enter {input.placeholder}
                            </label>
                            <input
                                type="text"
                                id={`input-group-${index + 1}`}
                                onChange={input.onChange}
                                value={input.value}
                                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder={input.placeholder}
                            />
                        </div>
                    ))}
                </div>
            </div>
            
            <div className="flex">
                <div className="ml-auto mt-4">
                    <button
                        onClick={() => {
                            const payload = {
                                "status": true,
                                "has_data": true,
                                "data": {
                                    facebook: facebookUrl,
                                    pinterest: pinInterestUrl,
                                    x: twitterUrl,
                                    instagram: instagramUrl,
                                    personal_website: personalWebsiteUrl,
                                },
                                "settings": null,
                                "position": position
                            }
                            handleSocialMediaWidgetCreation(payload, 'active');
                        }}
                        className={`bg-primary text-white text-sm font-semibold px-4 py-2 mb-2 rounded-lg ml-2`}>
                        {activateLoading ? 'Activate...' : 'Activate Widget'}
                    </button>
                    
                    <button
                        onClick={() => {
                            const payload = {
                                "status": false,
                                "has_data": false,
                                "data": {
                                    facebook: "",
                                    pinterest: "",
                                    x: "",
                                    instagram: "",
                                    personal_website: "",
                                },
                                "settings": null,
                                "position": ""
                            }
                            setFacebookUrl("");
                            setInstagramUrl("");
                            setPinInterestUrl("");
                            setTwitterUrl("");
                            setPersonalWebsiteUrl("");
                            handleSocialMediaWidgetCreation(payload, 'deactive');
                        }}
                        className={`bg-gray-700 text-white text-sm font-semibold px-4 py-2 mb-2 rounded-lg ml-2`}>
                        {deactivateLoading ? 'Deactivating...' : 'Deactivate Widget'}
                    </button>
                </div>
            </div>
            
            {/*{isModalOpen && (
                <AppModalShort title={""}>
                    <div className="">
                        <div className="flex justify-between py-3 px-1 text-sm text-bold">
                            <div className="text-left">
                                <h2 className="text-xl font-bold">Social Media Widget</h2>
                                <p className="font-regular mb-4">
                                    Loren ipsum dolor sit amet, consectetur adipiscing elit.
                                </p>
                            </div>
                        </div>
                        
                        
                        <div className="">
                            <div className="pb-3 pt-2 px-1">
                                <Shimmer/>
                                
                                <div className="mt-12 font-semibold text-lg text-center">
                                    Follow us on
                                </div>
                                <div className="flex mt-6">
                                    <img src={FacebookIcon} className="" width="50" height="50" alt="Icon"/>
                                    <img src={PinterestIcon} className="" width="50" height="50" alt="Icon"/>
                                    <img src={TwitterIcon} className="" width="50" height="50" alt="Icon"/>
                                    <img src={InstagramIcon} className="" width="50" height="50" alt="Icon"/>
                                    <img src={LinkIcon} className="" width="50" height="50" alt="Icon"/>
                                </div>
                            </div>
                        </div>
                        
                        <button
                            className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 mt-8 float-right rounded-lg"
                            onClick={handleCloseModal}>
                            Close
                        </button>
                    </div>
                </AppModalShort>
            )}*/}
            
            <Toaster/>
        
        </>
    
    )
}

export default SocialMediaWidgetForm;
