import {useContext, useEffect, useMemo, useRef, useState} from "react";
import "react-quill/dist/quill.snow.css";
import AppContext from "../context/AppContext";
import SEOBestPractice from "../components/quill/widgets/SEOBestPractice";
import WordFrequency from "../components/quill/widgets/WordFrequency";
import ContentSummary from "../components/quill/widgets/ContentSummary";
import DynamicEditorSection from "../components/quill/DynamicEditorSection";
import {Layout} from "antd";
import {Content} from "antd/lib/layout/layout";
import "react-quill/dist/quill.snow.css";
import "prismjs/themes/prism.css";
import "katex/dist/katex.min.css";
import "./Editor/Editor.css";


const Editor = ({html, onEditorChangeValue}) => {
    const [value, setValue] = useState("");
    const [activeTab, setActiveTab] = useState(1);
    const tabContainerRef = useRef(null);
    const {mode} = useContext(AppContext);
    
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };
    
    useEffect(() => {
        if (tabContainerRef.current) {
            const selectedTab = tabContainerRef.current.querySelector('.selected');
            if (selectedTab) {
                selectedTab.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'start',
                });
            }
        }
    }, [activeTab]);
    
    const renderTabContent = () => {
        switch (activeTab) {
            case 1:
                return <SEOBestPractice content={!!value ? value : "Hello"}/>;
            case 2:
                return <WordFrequency content={!!value ? value : "Hello"}/>;
            case 3:
                return <div>Tab 3 Content</div>;
            case 4:
                return <div>Tab 4 Content</div>;
            case 5:
                return <div>Tab 5 Content</div>;
            case 6:
                return <div>Tab 6 Content</div>;
            case 7:
                return <div>Tab 7 Content</div>;
            case 8:
                return <div>Tab 8 Content</div>;
            case 9:
                return <div>Tab 9 Content</div>;
            case 10:
                return <div>Tab 10 Content</div>;
            default:
                return null;
        }
    };
    
    useEffect(() => {
        setValue(html);
    }, [html]);
    
    const [secondPageOp, setSecondPageOp] = useState(null);
    const [articleBody, setArticleBody] = useState(null);
    
    const [allHelperWidgets, setAllHelperWidgets] = useState([
        {id: 1, helperWidgetName: "SEO Best Practice", selected: false},
        {id: 2, helperWidgetName: "Word Frequency", selected: false},
        {id: 3, helperWidgetName: "Content Structure", selected: false},
        {id: 4, helperWidgetName: "Dictionary", selected: false},
    ]);
    
    useEffect(() => {
        console.log("secondPageOp", secondPageOp);
    }, [secondPageOp]);
    
    
    useEffect(() => {
        setArticleBody(value);
    }, [value])
    
    return (
        <div className="w-full">
            <Layout>
                
                <div className="w-[18%]">
                    <div className="rounded-lg">{/**/}
                        
                        
                        {mode === 'pro' && <ContentSummary content={!!value ? value : "Hello"}/>}
                        
                        <div className={"text-gray-700 p-4 bg-white border-b border-gray-300"}>
                            
                            <div className="text-left pb-6 pl-1">
                                <div className="text-[16px] font-semibold text-gray-700">
                                    {mode === 'pro' ? 'Helper Widgets' : 'Best Practices'}
                                </div>
                            </div>
                            
                            <div className="mb-4">
                                {mode === 'pro' &&
                                    <div className="overflow-x-auto mb-4">
                                        <style>
                                            {`
                                            .tab-container {
                                              display: flex;
                                              flex-wrap: nowrap;
                                              overflow-x: auto;
                                              scrollbar-width: none;
                                              -ms-overflow-style: none;
                                            }
                                            
                                            .tab-container::-webkit-scrollbar {
                                              display: none;
                                            }
                                        `}
                                        </style>
                                        <div className="flex items-center">
                                            <div className="tab-container" ref={tabContainerRef}>
                                                {allHelperWidgets.map((widget) => (
                                                    <div
                                                        key={widget.id}
                                                        className={`tab-item ${
                                                            activeTab === widget.id ? 'selected' : ''
                                                        }`}>
                                                        <button
                                                            className={`py-2 text-gray-600 w-[150px] ${
                                                                activeTab === widget.id
                                                                    ? 'font-bold border-b-amber-600 border-b-2'
                                                                    : 'border-b border-b-gray-300 font-medium'
                                                            }`}
                                                            onClick={() => handleTabClick(widget.id)}
                                                        >
                                                            {widget.helperWidgetName}
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>}
                                
                                <div className="">{renderTabContent()}</div>
                            </div>
                        
                        </div>
                    
                    </div>
                </div>
                
                <Layout className="site-layout">
                    <Content style={{margin: '0 0', display: 'flex', flexDirection: 'row'}}>
                        <div className="child-row text-editor">
                            <DynamicEditorSection/>
                        </div>
                    </Content>
                </Layout>
            
            </Layout>
        
        </div>
    );
};

export default Editor;
