import React, {useContext, useEffect, useState} from 'react';
import {ROUTE_CONSTANT} from "../../constants/app.constants";
import API from "../../api/api";
import {useNavigate} from "react-router";
import {useLocation} from "react-router-dom";
import AppContext from "../../context/AppContext";

const StepFourTitleSuggestions = ({ aiSettingsFormData, setAISettingsFormData }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { initialDataFetch, token, user, account, store } = useContext(AppContext);
    const { blog_uuid } = location.state;
    
    const [selectedHeading, setSelectedHeading] = useState("");
    const [allHeadings, setAllHeadings] = useState([]);
    const [articleID, setArticleID] = useState("");
    const [isRegenerated, setIsRegenerated] = useState(false);
    
    const [loading, setLoading] = useState(false);
    const [editingId, setEditingId] = useState(null);
    
    useEffect(() => {
        if (initialDataFetch && token && user) {
            const payload = {
                maxRetries: 2,
                userApiKey: "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",
                nested: true,
                wordCountType: aiSettingsFormData?.wordcount,
                data: {
                    article_type: "general",
                    model: aiSettingsFormData?.model,
                    target_keyword: aiSettingsFormData?.topic,
                    language: aiSettingsFormData?.language,
                    include_conclusion: aiSettingsFormData?.include_conclusion,
                    wordCountType: aiSettingsFormData?.wordcount,
                }
            };
            getKeywordSuggestions(payload);
        } else {
            navigate(ROUTE_CONSTANT.LOGIN);
        }
    }, [initialDataFetch, token, user]);
    
    const getKeywordSuggestions = (payload) => {
        setLoading(true);
        const headerParams = {
            headers: {
                "Authorization": token
            }
        };
        API.post(`article-generator/get-article-heading-and-outline/${account}/${store}/${blog_uuid}`, payload, headerParams)
            .then(res => {
                const { main, outline, articleId, isRegenerated } = res.data.data;
                
                const allTitles = [main, ...outline];
                setAllHeadings(allTitles);
                setAISettingsFormData(prevFormData => ({
                    ...prevFormData,
                    article_title: main.title,
                    article_id: articleId
                }));
                
                setArticleID(articleId);
                setIsRegenerated(isRegenerated);
                setLoading(false);
            })
            .catch(error => {
                console.log('Error: ', error.message);
                setLoading(false);
            });
    }
    
    const handleEditClick = (id, currentTitle) => {
        setEditingId(id);
        setSelectedHeading(currentTitle);
    };
    
    const handleInputChange = (event) => {
        setSelectedHeading(event.target.value);
    };
    
    const handleSaveClick = (titleObj) => {
        setAllHeadings(allHeadings.map(title =>
            title.id === titleObj.id ? { ...title, title: selectedHeading } : title
        ));
        setEditingId(null);
    };
    
    const handleCancelClick = () => {
        setEditingId(null);
    };
    
    return (
        <form>
            <div className="">
                <div className="mb-8 uppercase text-sm font-bold text-gray-900">
                    Title Suggestions from <span className="text-orange-600">Blogd AI</span>
                </div>
                
                {!loading && <>
                    {allHeadings.map(title => (
                        <div key={title.id} className="flex items-center ps-4 border-b border-gray-200 mb-2">
                            <label
                                htmlFor={`radio-${title.id}`}
                                className={`w-full py-4 text-sm font-medium text-gray-900 flex`}>
                                {editingId === title.id ? (
                                    <>
                                        <input
                                            type="text"
                                            value={selectedHeading}
                                            onChange={handleInputChange}
                                            className="border border-gray-300 rounded-lg w-3/4 text-sm"
                                        />
                                        <button
                                            onClick={() => handleSaveClick(title)}
                                            className="ml-2 text-blue-600 hover:text-blue-800 ml-8"
                                        >
                                            Save
                                        </button>
                                        <button
                                            onClick={handleCancelClick}
                                            className="ml-2 text-red-600 hover:text-red-800 ml-4"
                                        >
                                            Cancel
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <span
                                            className={`mr-2 font-bold
                                                ${title.level === 1 && 'bg-violet-600 text-white'}
                                                ${title.level === 2 && 'bg-pink-600 text-white ml-4'}
                                                ${title.level === 3 && 'bg-blue-600 text-white ml-8'}
                                                text-xs font-medium me-2 px-2.5 py-0.5 rounded`}>
                                            H{title.level}
                                        </span>
                                        {title.title}
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                            className="size-6 w-5 h-5 text-gray-500 ml-2 cursor-pointer ml-auto"
                                            onClick={() => handleEditClick(title.id, title.title)}
                                        >
                                            <path
                                                d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32l8.4-8.4Z"/>
                                            <path
                                                d="M5.25 5.25a3 3 0 0 0-3 3v10.5a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3V13.5a.75.75 0 0 0-1.5 0v5.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5V8.25a1.5 1.5 0 0 1 1.5-1.5h5.25a.75.75 0 0 0 0-1.5H5.25Z"/>
                                        </svg>
                                    </>
                                )}
                            </label>
                        </div>
                    ))}
                </>}
                
                {loading && <div className="w-full h-full animate-pulse">
                    <div className="flex space-x-4">
                        <div className="flex-1 space-y-4 py-1">
                            <div className="h-4 bg-gray-300 rounded-lg w-3/4"></div>
                            <div className="h-4 bg-gray-300 rounded-lg"></div>
                            <div className="h-4 bg-gray-300 rounded-lg w-5/6"></div>
                            <div className="h-4 bg-gray-300 rounded-lg w-3/4"></div>
                            <div className="h-4 bg-gray-300 rounded-lg"></div>
                            <div className="h-4 bg-gray-300 rounded-lg w-5/6"></div>
                        </div>
                    </div>
                </div>}
            
            </div>
        </form>
    );
};

export default StepFourTitleSuggestions;

