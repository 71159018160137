import React, {useContext, useEffect, useState} from "react";
import moment from "moment";

const PublishArticleSEOChecklist = ({reset, prevStep}) => {
    
    useEffect(() => {
    }, []);
    
    return (
        <div>
            
            <div className="w-full bg-white">
                
                <div className="font-semibold text-orange-600 mb-4 px-1 text-center">SEO Checklist</div>
                
                <div className="grid grid-cols-1 gap-4 mb-3 mx-24 pt-2">
                    <div className="m-auto w-[500px]">
                        <div className="bg-gray-50 border border-gray-300 rounded-lg flex flex-col p-6">
                            
                            <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                    <div>
                                        <input type="checkbox" className="w-5 h-5 text-red-600 rounded-full ring-0"/>
                                    </div>
                                    <div
                                        className="text-sm font-medium text-gray-800 mb-4 px-1 pt-4 mt-0.5 pl-2 text-center">
                                        There should be at least one div tag in a blog
                                    </div>
                                </div>
                                <div
                                    className="text-white bg-orange-600 px-3 py-1 text-xs rounded-lg cursor-pointer font-bold leading-5 mt-0.5">
                                    FIX
                                </div>
                            </div>
                        
                        </div>
                    </div>
                </div>
                
                <div className="flex justify-center text-gray-600 font-semibold py-6 mt-4">
                    You already chose the theme, &nbsp;
                    <span className="text-blue-600 underline cursor-pointer"
                          onClick={reset}>
                        do you want to change theme?
                    </span>
                </div>
            
            </div>
            
            
            <div className={"absolute bottom-10 left-[50%] -translate-x-1/2 pt-4"}>
                <button type="button"
                        onClick={prevStep}
                        className="text-gray-800 w-0.5/4 font-bold mt-2 hover:bg-gray-100 bg-white border border-gray-300 rounded-lg text-sm px-6 py-2.5 mr-2">
                    PREVIOUS
                </button>
                <button type="button"
                        onClick={reset}
                        className="text-white w-0.5/4 font-bold mt-2 hover:bg-red-500 bg-primary rounded-lg text-sm px-6 py-2.5 mr-2">
                    DEPLOY
                </button>
                <button type="button"
                        onClick={reset}
                        className="text-white w-0.5/4 font-bold mt-2 hover:bg-orange-900 bg-primary rounded-lg text-sm px-6 py-2.5 mr-2">
                    FIX ALL & DEPLOY
                </button>
            </div>
        </div>
    )
}

export default PublishArticleSEOChecklist;
