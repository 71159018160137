import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import AppContext from "../../context/AppContext";
import API from "../../api/api";
import {toastError} from "../../context/ToasterContext";
import {useLocation} from "react-router-dom";
import {ROUTE_CONSTANT} from "../../constants/app.constants";
import mixpanel from "mixpanel-browser";

const SEOReportView = ({showSEOReportStatus, isSingleArticlePublish, blog = null, isMigrate}) => {
    
    const navigate = useNavigate();
    const location = useLocation();
    const {blog_id, blog_uuid, blog_title, blog_handle} = location.state;
    let {initialDataFetch, token, user, account, store} = useContext(AppContext);
    const [fetchCalled, setFetchCalled] = useState(false);
    const [isDeploying, setIsDeploying] = useState(false);
    const [seoReport, setSeoReport] = useState({});
    
    useEffect(() => {
        if (initialDataFetch) {
            if (!!token && !!user && !!store) {
                if (!store) {
                    // setAllArticles([]);
                } else if (!fetchCalled) {
                    setFetchCalled(true);
                    seoAnalysis();
                }
            } else {
                navigate(ROUTE_CONSTANT.LOGIN);
            }
        }
    }, [initialDataFetch, token, user, store, fetchCalled]);
    
    const seoAnalysis = () => {
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        API.get(`blog/seo-check/${account}/${store}/${blog_uuid}`, headerParams)
            .then(res => {
                mixpanel.track('SEO Check', {
                    "account": account,
                    "blog_uuid": blog_uuid,
                    "store": store,
                });
                console.log("res: -----", res.data.data.overall_report);
                setSeoReport(res.data.data);
            })
            .catch(error => {
                toastError(error.message);
            })
    }
    
    const publishBlog = () => {
        setIsDeploying(true);
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        const params = {
            "domain_name": blog_handle,
        }
        API.post(`blog/publish/${account}/${store}/${blog_uuid}`, params, headerParams)
            .then(res => {
                if (isSingleArticlePublish) {
                    navigate(`/blogs/${blog.handle}/articles?migrate=${isMigrate}`, {
                        state: {
                            blog_id: blog.id,
                            blog_uuid: blog.uuid,
                            blog_title: blog.title,
                            blog_handle: blog.handle
                        }
                    });
                    mixpanel.track('Article Published', {
                        "account": account,
                        "blog_uuid": blog_uuid,
                        "store": store,
                        "domain_name": blog_handle
                    });
                    setIsDeploying(false);
                }
                setIsDeploying(false);
            })
            .catch(error => {
                console.log('Error: ', error.message);
                toastError(error.message);
                setIsDeploying(false);
            })
    }
    
    
    return (
        <div className="mt-12">
            <div
                className="bg-white max-w-6xl border border-gray-200 shadow-lg p-4 rounded-xl">
                
                <div className="flex flex-row items-center justify-between my-3 pb-3">
                    <span className="text-left text-lg ml-4 font-bold">SEO Report</span>
                    
                    <div onClick={() => showSEOReportStatus(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                             stroke="currentColor" className="w-8 h-8 mr-2 cursor-pointer">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12"/>
                        </svg>
                    </div>
                </div>
                
                <div className="pb-6">
                    <hr className="border-t border-gray-200"/>
                </div>
                
                <div>
                    <div className="w-full bg-white">
                        
                        <div className="mx-6 pt-4">
                            <h4 className="font-bold">Overall Blog Score (3 Articles)</h4>
                            {/*<div className="bg-white border border-gray-200 py-6 px-8 rounded-xl">*/}
                            <div className="flex justify-between mb-2">
                        <span
                            className="font-medium text-sm text-blue-700 dark:text-white">
                            {/*Overall Blog Score*/}
                        </span>
                                <span className="text-sm font-medium text-blue-700 dark:text-white">
                                    {seoReport?.overall_report?.overall_score}%</span>
                            </div>
                            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                                <div
                                    className="bg-blue-600 h-2.5 rounded-full"
                                    style={{width: `${seoReport?.overall_report?.overall_score || 0}%`}}
                                ></div>
                            </div>
                            {/*</div>*/}
                        </div>
                        
                        <div className="grid grid-cols-4 gap-4 mx-6 pt-8">
                            <div className="bg-purple-50 border border-purple-300 py-6 px-8 rounded-xl text-center">
                                <h2 className="font-bold">{seoReport?.overall_report?.total_articles}</h2>
                                <span className="font-bold text-sm text-purple-600">Articles Tested</span>
                            </div>
                            
                            <div className="bg-green-50 border border-green-200 py-6 px-8 rounded-xl text-center">
                                <h2 className="font-bold">{seoReport?.overall_report?.total_test_passed}</h2>
                                <span className="font-bold text-sm text-green-600">Test Passed</span>
                            </div>
                            
                            <div className="bg-red-50 border border-red-200 py-6 px-8 rounded-xl text-center">
                                <h2 className="font-bold">{seoReport?.overall_report?.total_test_failed}</h2>
                                <span className="font-bold text-sm text-red-600">Test Failed</span>
                            </div>
                            
                            <div className="bg-orange-50 border border-orange-200 py-6 px-8 rounded-xl text-center">
                                <h2 className="font-bold">{seoReport?.overall_report?.total_errors}</h2>
                                <span className="font-bold text-sm text-orange-600">Errors</span>
                            </div>
                        </div>
                        
                        
                        <div className="mx-6 pt-8">
                            <div className="relative overflow-x-auto border border-gray-200 sm:rounded-lg">
                                <table
                                    className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                    <thead
                                        className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">
                                            Article Name
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Test Passed
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Test Failed
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Errors
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Overall Status
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {seoReport?.detailed_report?.map((article, index) => (
                                        <tr
                                            key={index}
                                            className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                            <th
                                                scope="row"
                                                className="px-6 py-4 font-normal text-gray-900 dark:text-white break-words whitespace-normal">
                                                {article?.article_title}
                                            </th>
                                            <td className="px-6 py-4">
                                                {article?.test_passed}
                                            </td>
                                            <td className="px-6 py-4">
                                                {article?.test_failed}
                                            </td>
                                            <td className="px-6 py-4">
                                                {article?.errors.length}
                                            </td>
                                            <td className="px-6 py-4">
                                              <span
                                                  className="bg-red-100 text-red-800 text-xs font-normal px-2.5 py-0.5 rounded-lg">
                                                Failed
                                              </span>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    
                    
                    <div className={"mx-auto text-center pt-8 pb-4"}>
                        {!isDeploying ? <>
                            <button type="button"
                                    onClick={() => {
                                        publishBlog();
                                    }}
                                    className="text-orange-600 border border-orange-600 w-1/4 font-bold mt-2 hover:bg-orange-50 rounded-lg text-sm px-6 py-2.5 mr-2">
                                DEPLOY
                            </button>
                            
                            <button type="button"
                                    onClick={() => {
                                        const user = JSON.parse(localStorage.getItem('a_blogd_user'));
                                        if (!user.is_subscription_active) navigate("/pricing");
                                        else publishBlog();
                                    }}
                                    className="text-white w-1/4 font-bold mt-2 hover:bg-orange-700 bg-orange-600 rounded-lg text-sm px-6 py-2.5 mr-2">
                                FIX & DEPLOY
                            </button>
                        </> : <div className="font-semibold mt-1">Deploying....</div>}
                    </div>
                </div>
            
            </div>
        </div>
    )
}

export default SEOReportView;
