import React, {useContext, useEffect, useState} from 'react'
import {useLocation, useNavigate, useParams} from "react-router";
import AppContext from "../../context/AppContext";
import {ROUTE_CONSTANT} from "../../constants/app.constants";
import API from "../../api/api";
import {toastError, toastSuccess} from "../../context/ToasterContext";
import {useFormik} from "formik";
import * as Yup from "yup";


const MENU = [
    {id: 1, name: "Domain"},
    {id: 2, name: "Themes"},
    {id: 3, name: "Account DomainSetting"},
]

/*
* Custom Domain Added - t/f
* SSL Generated - t/f
* Custom Domain Added Timestamp
* */


export default function DomainSetting() {
    
    const navigate = useNavigate();
    const location = useLocation();
    let {initialDataFetch, token, user, store, account} = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const {id} = useParams();
    const {blog_id, blog_uuid, blog_handle, blog_title, account_settings} = location.state;
    const [showCopied, setShowCopied] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [tempArticleSelected, setTempArticleSelected] = useState(null);
    const [pageOne, setPageOne] = useState(true);
    const [pageTwo, setPageTwo] = useState(false);
    const [customDomainName, setCustomDomainName] = useState("");
    const [certificateArn, setCertificateArn] = useState("");
    const [platformDomain, setPlatformDomain] = useState("");
    const [showCopiedDomain, setShowCopiedDomain] = useState(false);
    const [showCopiedArn, setShowCopiedArn] = useState(false);
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    
    useEffect(() => {
        if (initialDataFetch) {
            if (!!token && !!user) {
                if (!!localStorage.getItem("custom_domain")) {
                    createCustomDomain();
                }
            } else {
                navigate(ROUTE_CONSTANT.LOGIN);
            }
        }
    }, [token, user]);
    
    
    const handleCopyDomain = async (textToCopy) => {
        try {
            await navigator.clipboard.writeText(textToCopy);
            setShowCopiedDomain(true);
            setTimeout(() => {
                setShowCopiedDomain(false);
            }, 2000);
        } catch (error) {
            console.error('Failed to copy text:', error);
        }
    };
    
    const handleCopyArn = async (textToCopy) => {
        try {
            await navigator.clipboard.writeText(textToCopy);
            setShowCopiedArn(true);
            setTimeout(() => {
                setShowCopiedArn(false);
            }, 2000);
        } catch (error) {
            console.error('Failed to copy text:', error);
        }
    };
    
    
    const formik = useFormik({
        initialValues: {
            customDomainName: '',
        },
        validationSchema: Yup.object({
            customDomainName: Yup.string().required('Custom Domain is required'),
        }),
        onSubmit: values => {
            createCustomDomain();
        },
    });
    
    
    const createCustomDomain = () => {
        setLoading(true);
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        let bodyParams = {
            custom_domain: customDomainName
        }
        localStorage.setItem("custom_domain", customDomainName);
        API.post(`settings/add-custom-domain/${account}/${store}/${blog_uuid}`, bodyParams, headerParams)
            .then(res => {
                const {certificateArn, platformDomain} = res.data.data;
                setCertificateArn(certificateArn);
                setPlatformDomain(platformDomain);
                getCNAMERecords(certificateArn);
                // toastSuccess("Template and Article Saved");
            })
            .catch(error => {
                setLoading(false);
                toastError(error.message);
            })
    }
    
    
    const getCNAMERecords = (certificateArn) => {
        setLoading(true);
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        let bodyParams = {
            certificateArn: certificateArn,
            custom_domain: customDomainName
        }
        API.post(`settings/get-cname-record-set/${account}/${store}/${blog_uuid}`, bodyParams, headerParams)
            .then(res => {
                setLoading(false);
                const response = res.data;
                if (response.status === 'success') {
                    setLoading(false);
                    setPageOne(false);
                    setPageTwo(true);
                } else if (response.status === 'failed') {
                
                }
                toastSuccess(response.message);
                // toastSuccess("Template and Article Saved");
            })
            .catch(error => {
                setLoading(false);
                toastError(error.message);
            })
    }
    
    
    const checkSSLCertificateRecords = () => {
        setLoading(true);
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        let bodyParams = {
            certificateArn: certificateArn,
            custom_domain: customDomainName
        }
        API.post(`settings/check-ssl-certificate-status/${account}/${store}/${blog_uuid}`, bodyParams, headerParams)
            .then(res => {
                setLoading(false);
                setPageOne(false);
                setPageTwo(true);
                toastSuccess(res.data.message);
            })
            .catch(error => {
                setLoading(false);
                toastError(error.message);
            })
    }
    
    
    return (
        <div>
            
            <div className="max-w-7xl mx-auto mb-8">
                <>
                    {pageOne && <div className="bg-white justify-center items-center border border-gray-200 shadow-lg p-4 rounded-xl mt-8">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="flex flex-col ml-4">
                                <h2 className="text-left text-lg mt-2 mb-6 font-bold">
                                    Add Custom Domain
                                </h2>
                                <p className="m-1 pt-1 pb-4">
                                    Enter the exact domain name you want people to see when they visit your site. It can
                                    be
                                    a domain (yourdomain.com) or a subdomain (app.yourdomain.com).
                                </p>
                                
                                <div className="flex flex-row">
                                    <div className="flex flex-col w-1/2 mr-3">
                                        <div className="mt-2">
                                            <input type="text"
                                                   className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                   placeholder="Enter your custom domain name"
                                                   value={customDomainName}
                                                   {...formik.getFieldProps('customDomainName')}
                                                   onChange={(event) => {
                                                       const text = event.target.value;
                                                       formik.setFieldValue('customDomainName', text);
                                                       setCustomDomainName(text);
                                                   }}/>
                                            {formik.touched.customDomainName && formik.errors.customDomainName && <div
                                                className="text-red-500 text-xs mt-1 ml-1">{formik.errors.customDomainName}</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-6 ml-4 mb-4">
                                <button
                                    type={"submit"}
                                    className="inline-block bg-primary text-white text-sm font-bold py-2 px-8 rounded-lg">
                                    {loading ? "Adding Domain..." : "Add Domain"}
                                </button>
                            </div>
                        </form>
                    </div>}
                    
                    
                    {pageTwo && <div className="relative overflow-x-auto sm:rounded-lg mt-6">
                        <div className="bg-white shadow rounded-md p-6 mt-6">
                            <div className="flex flex-col">
                                <h2 className="m-1">
                                    CNAME Record Set
                                </h2>
                                <p className="m-1 pt-1 pb-4">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing edivt, sed do eiusmod
                                    tempor incididunt ut labore et dolore magna adivqua.
                                </p>
                                
                                <div className="flex flex-row mb-4">
                                    <div className="flex flex-col mr-3">
                                        <div className="mt-2">
                                            <div className="grid grid-cols-12 gap-4">
                                                <div className="col-span-4">
                                                    <div className="mt-3 text-sm text-gray-600 pr-8">
                                                        Platform Domain
                                                    </div>
                                                    <div className="flex mt-2">
                                                        <div className="flex">
                                                            <div
                                                                className="font-semibold cursor-pointer">{platformDomain}</div>
                                                            <div className="ml-1.5 mt-0.5 cursor-pointer"
                                                                 onClick={() => {
                                                                     handleCopyDomain(platformDomain);
                                                                 }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                     viewBox="0 0 24 24" strokeWidth="1.5"
                                                                     stroke="currentColor" className="w-5 h-5">
                                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                                          d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"/>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        {showCopiedDomain &&
                                                            <p className="text-xs font-semibold cursor-pointer text-blue-500 ml-3">
                                                                Copied!
                                                            </p>
                                                        }
                                                    </div>
                                                </div>
                                                
                                                <div className="col-span-8">
                                                    <div className="mt-3 text-sm text-gray-600 pr-8">
                                                        Certificate ARN
                                                    </div>
                                                    <div className="flex mt-2">
                                                        <div className="flex">
                                                            <div
                                                                className="font-semibold cursor-pointer">{certificateArn}</div>
                                                            <div className="ml-1.5 mt-0.5 cursor-pointer"
                                                                 onClick={() => {
                                                                     handleCopyArn(certificateArn);
                                                                 }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                     viewBox="0 0 24 24" strokeWidth="1.5"
                                                                     stroke="currentColor" className="w-5 h-5">
                                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                                          d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"/>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        {showCopiedArn &&
                                                            <p className="text-xs font-semibold cursor-pointer text-blue-500 ml-3">
                                                                Copied!
                                                            </p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex mt-6">
                                <button type="button"
                                        onClick={() => {
                                            setPageOne(true);
                                            setPageTwo(false);
                                        }}
                                        className="inline-block bg-white border border-orange-500 mr-2 text-orange-500 text-sm font-bold py-2 px-8 rounded-lg">
                                    Back
                                </button>
                                
                                <button
                                    onClick={checkSSLCertificateRecords}
                                    className="inline-block bg-primary text-white text-sm font-bold py-2 px-8 rounded-lg">
                                    {loading ? "Verifying...." : "Verify SSL"}
                                </button>
                            </div>
                        </div>
                    </div>}
                </>
            </div>
        
        </div>
    )
}
