import React, {useContext, useEffect, useState} from 'react';
import EmptyImage from "../assets/img/empty-image.png";
import {ROUTE_CONSTANT} from "../constants/app.constants";
import {useLocation, useNavigate} from "react-router";
import API from "../api/api";
import AppContext from "../context/AppContext";
import {toastError} from "../context/ToasterContext";
import {Toaster} from "react-hot-toast";

const GeneratingArticlePage = () => {
    
    const [progress, setProgress] = useState(0);
    const navigate = useNavigate();
    
    let {initialDataFetch, token, user, account, store, afterLogout} = useContext(AppContext);
    
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const articleId = params.get('article_id');
    const {blog_id, blog_uuid, blog_handle, blog_title} = location.state;
    
    useEffect(() => {
        const timer = setTimeout(() => {
            setProgress(100);
        }, 100);
        
        return () => clearTimeout(timer);
    }, []);
    
    
    useEffect(() => {
        if (initialDataFetch) {
            if (!!token && !!user) {
                getArticleStatus();
            } else {
                navigate(ROUTE_CONSTANT.LOGIN);
            }
        }
    }, [token, user]);
    
    
    const getArticleStatus = () => {
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        API.get(`article-generator/get-article-status?article_id=${articleId}`, headerParams)
            .then(res => {
                navigate(`/blogs/${blog_handle}/articles`, {
                    state: {
                        blog_id: blog_id,
                        blog_uuid: blog_uuid,
                        blog_title: blog_title,
                        blog_handle: blog_handle
                    }
                })
            })
            .catch(error => {
                toastError(error.message)
            })
    }
    
    
    return (
        <div className="flex text-center items-center justify-center -mt-16 min-h-screen">
            <div className="bg-white rounded-xl p-12 shadow w-2/6">
                <p className={"font-bold text-gray-800 mt-2"}>Generating Article...</p>
                
                <p className={"text-gray-600 mt-4"}>Loren ipsum dolor sit amet consectetur adipiscing elit, dolor sit amet, consectetur adipiscing.</p>
                
                <div className="w-full h-4 bg-gray-200 rounded-full overflow-hidden mt-8">
                    <div
                        className="h-full bg-orange-500 rounded-full"
                        style={{
                            width: `${progress}%`,
                            transition: 'width 2s linear',
                        }}
                    ></div>
                </div>
            </div>
            <Toaster />
        </div>
    );
};

export default GeneratingArticlePage;
