import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import AppContext from "../context/AppContext";
import API from "../api/api";
import {Toaster} from "react-hot-toast";
import {toastError, toastSuccess} from "../context/ToasterContext";

const PricingPlanPage = () => {
    
    const navigate = useNavigate();
    let {initialDataFetch, token, user, account, store} = useContext(AppContext);
    const [checkoutResponse, setCheckoutResponse] = useState({});
    
    const createCheckoutSession = (lookupKey) => {
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        const params = {
            "lookup_key": lookupKey,
            "customer_email": user.email
        }
        API.post(`stripe/create_checkout_session`, params, headerParams)
            .then(res => {
                window.open(res.data.data.url, '_blank', 'rel=noopener noreferrer')
                setCheckoutResponse(res.data.data.url);
            })
            .catch(error => {
                console.log('Error: ', error.message);
                toastError(error.message);
            })
    }
    
    
    useEffect(() => {
    }, []);
    
    return (
        <div className="h-screen flex justify-center items-center">
            <div
                className="max-w-6xl bg-white rounded-xl border border-gray-300 shadow-lg mx-auto my-auto py-8">
                
                <div
                    className="max-w-7xl justify-center items-center p-4">
                    
                    {/*<div className="flex flex-row items-center justify-between my-3 pb-3">
                    <span className="text-left text-lg ml-4 font-bold">SEO Report</span>
                    
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                             stroke="currentColor" className="w-8 h-8 mr-2 cursor-pointer">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12"/>
                        </svg>
                    </div>
                </div>
                
                <div className="pb-6">
                    <hr className="border-t border-gray-200"/>
                </div>*/}
                    
                    
                    <div className="relative"
                         onClick={() => {
                             navigate(-1);
                         }}>
                        <div className="absolute -top-5 right-0">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                 stroke="currentColor" className="w-8 h-8 mr-2 cursor-pointer">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12"/>
                            </svg>
                        </div>
                    </div>
                    
                    <div className="text-center text-sm text-orange-600 font-semibold">Pricing</div>
                    
                    <div className="text-center text-4xl font-bold mt-2">Lorem ipsum dolor sit amet</div>
                    
                    <div className="text-center text-lg text-gray-600 mt-6">
                        Lorem ipsum dolor sit amet,
                        consectetur adipiscing edivt, sed do eiusmod <br/> tempor incididunt ut labore et dolore magna
                        adivqua.
                    </div>
                    
                    
                    <div className="grid grid-cols-2 gap-4 mt-10 mx-12">
                        
                        <div className="bg-white border border-gray-300 rounded-lg p-8">
                            <h4 className="font-bold mb-3">Basic Plan</h4>
                            <p className="text-gray-600 mb-6">Lorem ipsum dolor sit adipiscing amet, consectetur
                                adipiscing edivt.</p>
                            
                            <div className=""><span className="text-3xl font-bold">$39</span> <span
                                className="text-gray-500 font-semibold">/&nbsp;month</span></div>
                            <button type="button"
                                    onClick={() => {
                                        createCheckoutSession("basic-blogd");
                                    }}
                                    className="border border-orange-600 w-full font-bold hover:bg-orange-100 text-orange-600 rounded-lg text-sm px-6 py-2.5 mr-2 mt-6 mb-8">
                                Buy Plan
                            </button>
                            
                            <div className="text-gray-600 mb-4">
                                <div className={"flex mb-2"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="2" stroke="currentColor" className="w-5 h-5 pt-1 text-orange-600">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5"/>
                                    </svg>
                                    
                                    <span className="ml-3">Feature 1</span>
                                </div>
                                
                                <div className={"flex mb-2"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="2" stroke="currentColor" className="w-5 h-5 pt-1 text-orange-600">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5"/>
                                    </svg>
                                    
                                    <span className="ml-3">Feature 1</span>
                                </div>
                                
                                <div className={"flex mb-2"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="2" stroke="currentColor" className="w-5 h-5 pt-1 text-orange-600">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5"/>
                                    </svg>
                                    
                                    <span className="ml-3">Feature 1</span>
                                </div>
                                
                                <div className={"flex mb-2"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="2" stroke="currentColor" className="w-5 h-5 pt-1 text-orange-600">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5"/>
                                    </svg>
                                    
                                    <span className="ml-3">Feature 1</span>
                                </div>
                            </div>
                        </div>
                        
                        
                        <div className="bg-white border-2 border-orange-600 rounded-lg p-8">
                            <h4 className="font-bold mb-3">Premium Plan</h4>
                            <p className="text-gray-600 mb-6">Lorem ipsum dolor sit adipiscing amet, consectetur
                                adipiscing edivt.</p>
                            
                            <div className=""><span className="text-3xl font-bold">$49</span> <span
                                className="text-gray-500 font-semibold">/&nbsp;month</span></div>
                            <button type="button"
                                    onClick={() => {
                                        createCheckoutSession("enterprise-blogd");
                                    }}
                                    className="text-white w-full font-bold hover:bg-orange-700 bg-orange-600 rounded-lg text-sm px-6 py-2.5 mr-2 mt-6 mb-8">
                                Buy Plan
                            </button>
                            
                            <div className="text-gray-600 mb-4">
                                <div className={"flex mb-2"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="2" stroke="currentColor" className="w-5 h-5 pt-1 text-orange-600">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5"/>
                                    </svg>
                                    
                                    <span className="ml-3">Feature 1</span>
                                </div>
                                
                                <div className={"flex mb-2"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="2" stroke="currentColor" className="w-5 h-5 pt-1 text-orange-600">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5"/>
                                    </svg>
                                    
                                    <span className="ml-3">Feature 1</span>
                                </div>
                                
                                <div className={"flex mb-2"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="2" stroke="currentColor" className="w-5 h-5 pt-1 text-orange-600">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5"/>
                                    </svg>
                                    
                                    <span className="ml-3">Feature 1</span>
                                </div>
                                
                                <div className={"flex mb-2"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="2" stroke="currentColor" className="w-5 h-5 pt-1 text-orange-600">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5"/>
                                    </svg>
                                    
                                    <span className="ml-3">Feature 1</span>
                                </div>
                                
                                <div className={"flex mb-2"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="2" stroke="currentColor" className="w-5 h-5 pt-1 text-orange-600">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5"/>
                                    </svg>
                                    
                                    <span className="ml-3">Feature 1</span>
                                </div>
                                
                                <div className={"flex mb-2"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="2" stroke="currentColor" className="w-5 h-5 pt-1 text-orange-600">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5"/>
                                    </svg>
                                    
                                    <span className="ml-3">Feature 1</span>
                                </div>
                                
                                <div className={"flex mb-2"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="2" stroke="currentColor" className="w-5 h-5 pt-1 text-orange-600">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5"/>
                                    </svg>
                                    
                                    <span className="ml-3">Feature 1</span>
                                </div>
                                
                                <div className={"flex mb-2"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="2" stroke="currentColor" className="w-5 h-5 pt-1 text-orange-600">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5"/>
                                    </svg>
                                    
                                    <span className="ml-3">Feature 1</span>
                                </div>
                            </div>
                        </div>
                    
                    
                    </div>
                
                </div>
                
                <Toaster/>
            
            </div>
        </div>
    )
}

export default PricingPlanPage;
