import React, {useContext, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router";
import AppContext from "../context/AppContext";
import API from "../api/api";
import {Toaster} from "react-hot-toast";
import Lottie from 'react-lottie';
import VerifyLottie from "../assets/lotties/payment-verify.json";
import {toastError, toastSuccess} from "../context/ToasterContext";
import {ROUTE_CONSTANT, USER} from "../constants/app.constants";

const PricingVerifyPage = () => {
    
    const navigate = useNavigate();
    let {initialDataFetch, token, user, account, store, storeUserData} = useContext(AppContext);
    const [sessionId, setSessionId] = useState("");
    const location = useLocation();
    
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: VerifyLottie,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    
    
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const sessionIdParam = searchParams.get('session_id');
        if (initialDataFetch) {
            if (!!token && !!user) {
                paymentVerify(sessionIdParam);
            } else {
                navigate(ROUTE_CONSTANT.LOGIN);
            }
        }
    }, [location, token, user]);
    
    
    const publishBlogWithSEOFix = () => {
        const blog_uuid = localStorage.getItem("blog_uuid");
        const blog_handle = localStorage.getItem("blog_handle");
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        const params = {
            "domain_name": blog_handle,
        }
        API.post(`blog/publish-with-seo-fix/${account}/${store}/${blog_uuid}`, params, headerParams)
            .then(res => {
                toastSuccess("Published with SEO Fix");
            })
            .catch(error => {
                console.log('Error: ', error.message);
                toastError(error.message);
            })
    }
    
    
    const paymentVerify = (sessionIdParam) => {
        const blog_uuid = localStorage.getItem("blog_uuid");
        const blog_handle = localStorage.getItem("blog_handle");
        let headerParams = {
            headers: {
                "Authorization": token
            }
        };
        API.get(`stripe/payment_success?session_id=${sessionIdParam}`, headerParams)
            .then(res => {
                toastSuccess("Payment Success");
                const {user} = res.data.data;
                localStorage.removeItem(USER);
                localStorage.setItem(USER, JSON.stringify(user));
                publishBlogWithSEOFix();
                navigate(`/blogs/${blog_handle}/articles?migrate=false`, {
                    state: {
                        blog_uuid: blog_uuid,
                        blog_handle: blog_handle
                    }
                });
            })
            .catch(error => {
                console.log('Error: ', error.message);
                toastError(error.message);
            })
    }
    
    
    useEffect(() => {
    }, []);
    
    
    return (
        <div className="h-screen flex justify-center items-center">
            <div
                className="max-w-6xl mx-auto my-auto p-16">
                
                <Lottie
                    options={defaultOptions}
                    height={200}
                    width={200}
                />
                
                <Toaster/>
            
            </div>
        </div>
    )
}

export default PricingVerifyPage;
